const MainContainer = () => import('~/components/layouts/MainContainer').then(m => m.default || m)

const moduleRoutes = {
	path: '/flows',
	name: 'FlowsIndex',
	redirect: '/flows',
	component: MainContainer,
	props: true,
	children: [
		{
			path: '',
			name: 'flows',
			component: () => import('./Flows').then(m => m.default || m),
			meta: {
				breadcrumbs: [{ title: 'My Email Flows', active: true }],
			},
		},
		{
			path: 'new',
			name: 'add-flow',
			component: () => import('./AddFlow').then(m => m.default || m),
			meta: {
				pageTitle: 'Add Flow',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows' },
					{ title: 'Add Flow', active: true },
				],
			},
		},
		{
			path: 'new-v2',
			name: 'add-flow-v2',
			component: () => import('./AddFlowV2').then(m => m.default || m),
			meta: {
				pageTitle: 'Add Flow',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows' },
					{ title: 'Add Flow', active: true },
				],
			},
		},
		{
			path: ':flowId/edit',
			name: 'update-flow',
			component: () => import('./AddFlow').then(m => m.default || m),
			meta: {
				pageTitle: 'Update Flow',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows' },
					{ title: 'Update Flow', active: true },
				],
			},
		},
		{
			path: ':flowId/new',
			name: 'add-flow-email',
			component: () => import('./AddFlowEmail').then(m => m.default || m),
			meta: {
				pageTitle: 'Add Flow Email',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows' },
					{ title: 'Flows', active: true },
				],
			},
		},
		{
			path: ':flowId/emails/:flowEmailId',
			name: 'update-flow-email',
			component: () => import('./AddFlowEmail').then(m => m.default || m),
			meta: {
				pageTitle: 'Update Flow Email',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows' },
					{ title: 'Flows', active: true },
				],
			},
		},
		{
			path: '-1/broadcast/new',
			name: 'add-broadcast-email',
			component: () => import('./AddBroadcastEmail').then(m => m.default || m),
			meta: {
				pageTitle: 'Add Flow Email',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows', query: { tab: 'broadcasts' } },
					{ title: 'Add Broadcast', active: true },
				],
			},
		},
		{
			path: ':flowId/broadcast/emails/:flowEmailId',
			name: 'update-broadcast-email',
			component: () => import('./AddBroadcastEmail').then(m => m.default || m),
			meta: {
				pageTitle: 'Update Flow Email',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows', query: { tab: 'broadcasts' } },
					{ title: 'Broadcasts', active: true },
				],
			},
		},
		{
			path: 'recipients/:flowEmailId',
			name: 'flow-email-recipients',
			component: () => import('./FlowRecipients').then(m => m.default || m),
			meta: {
				pageTitle: 'Recipients',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows' },
					{ title: 'Recipients', active: true },
				],
			},
		},
		{
			path: 'recipients/:flowEmailId/:page',
			name: 'paginate-flow-email-recipients',
			component: () => import('./FlowRecipients').then(m => m.default || m),
			meta: {
				pageTitle: 'Recipients',
				breadcrumbs: [
					{ title: 'My Email Flows', to: 'flows' },
					{ title: 'Recipients', active: true },
				],
			},
		},
	],
}

export default moduleRoutes
