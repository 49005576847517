<template>
	<div>
		<AppDropdown
			ref="timezone-dropdown"
			:class="dropdownClass"
			:options="timeZonesList"
			:placeholder="$t('Time Zone')"
			reduce="name"
			label="label"
			:dropdownRowComponent="timezoneRowComponent"
			:dropdownAutoClose="false"
			:manualControl="manualControl"
			:icon="icon"
			:disabled="disabled"
			:searchable="searchable"
			v-model="internalValue"
		/>
	</div>
</template>
<script>
	import { timeZonesRawList } from '~/pages/stores/components/BaseDefaults.js'
	import AppTimeZoneRow from '~/components/shared/AppTimeZoneRow'

	export default {
		name: 'AppTimeZoneDropdown',
		props: {
			value: {
				type: [Object, String, Number],
			},
			manualControl: {
				type: Boolean,
				default: false,
			},
			icon: {
				type: String,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			searchable: {
				type: Boolean,
				default: true,
			},
			dropdownClass: {
				type: String,
				default: 'form-control',
			},
		},
		data() {
			return {
				timezoneRowComponent: AppTimeZoneRow,
			}
		},
		computed: {
			internalValue: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				},
			},

			timeZonesList() {
				return timeZonesRawList
			},
		},
		methods: {
			hide(e) {
				this.$refs['timezone-dropdown'].hide(e)
			},
		},
	}
</script>
