var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "paypal-manage-modal",
        title: "Manage Your PayPal",
        action: "Go to PayPal"
      },
      on: { cta: _vm.goToPaypal }
    },
    [
      _c("div", { staticClass: "paypal-subheading para-2" }, [
        _vm._v("\n\t\tYour connected PayPal account email is: "),
        _c("strong", [_vm._v(_vm._s(_vm.email))])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }