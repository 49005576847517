/* eslint-disable import/extensions */
import axios from 'axios'
import * as Sentry from '@sentry/vue'
import store from '~/store'
import router from '~/router'
import { $gtag } from '~/app'
import { getSessionUUID, UUID, getDeviceToken } from '../store/modules/Global.js'

// Request interceptor
axios.interceptors.request.use(request => {
	const token = store.getters['Auth/token']
	if (token) {
		request.headers.common.Authorization = `Bearer ${token}`
	}
	request.headers.common['x-request-id'] = UUID()
	request.headers.common['x-session-uuid'] = getSessionUUID()
	request.headers.common['x-session-id'] = getDeviceToken()
	Sentry.setContext('Logging Ids', { 'Session Id': request.headers.common['x-session-uuid'] })
	return request
})

// Response interceptor
axios.interceptors.response.use(
	response => {
		const isLoginRoute = response.config?.url?.endsWith('/login')
		const isMFAVerifyRoute = response.config?.url?.endsWith('/re-verify')
		const isRecoveryRoute = response.config?.url?.endsWith('/recovery')
		if (typeof response.data === 'object' && response?.data?.ip) {
			store.commit('Global/setIPAddress', response.data.ip)
		}

		// tag devices that hit our auth endpoints
		if ((isLoginRoute || isMFAVerifyRoute || isRecoveryRoute) && response.data?.deviceToken) {
			store.commit('Global/setDeviceToken', response.data.deviceToken)
		}
		return response
	},
	error => {
		const status = error?.response?.status // Possibility of response being undefined.
		const isAnyLoginRoute = error.response?.config?.url?.endsWith('/login')
		const isMFAVerifyRoute = error.response?.config?.url?.endsWith('/re-verify')
		const isRecoveryRoute = error.response?.config?.url?.endsWith('/recovery')
		$gtag.event('exception', { description: error, fatal: false })

		// We do not force logout users due to a failed login attempt
		const isLoginRoute = error.config?.url?.endsWith('v1/login')

		// Even on error, we want to tag the device
		if ((isAnyLoginRoute || isMFAVerifyRoute || isRecoveryRoute) && error.response?.data?.deviceToken) {
			store.commit('Global/setDeviceToken', error.response?.data.deviceToken)
		}
		if (!isLoginRoute && axios.defaults.baseURL === error.response?.config?.baseURL && status === 401 && store.getters['Auth/check']) {
			store.commit('Auth/LOGOUT')
			router.push({ name: 'login' })
		}

		return Promise.reject(error)
	}
)
