var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cancellation-modal-overlay" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-content-center h-100" },
      [
        _c(
          "div",
          { staticClass: "cancellation-modal" },
          [
            _c(
              "div",
              {
                staticClass: "close close-modal",
                attrs: { "data-dismiss": "modal", "aria-label": "Close" },
                on: {
                  click: function($event) {
                    return _vm.flowAbort()
                  }
                }
              },
              [
                _c("InlineSvg", {
                  staticClass: "close-icon",
                  attrs: { src: "/images/icons/ic-close.svg" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Transition",
              { attrs: { name: "slide", mode: "out-in" } },
              [
                _vm.activeDiscountOffer == _vm.twoMonthDiscountOffer &&
                _vm.step == _vm.discountOfferStep &&
                !_vm.discountOfferAccepted
                  ? _c("OfferTwoMonthDiscount", {
                      attrs: {
                        cancelPredefinedReason: _vm.cancelPredefinedReason,
                        cancelReasonExtraDetail: _vm.cancelReasonExtraDetail,
                        cancelFeedback: _vm.cancelFeedback
                      },
                      on: {
                        start: payload => this.stepStart(payload),
                        "next-step": _vm.nextStep,
                        "two-month-discount-accepted": payload =>
                          this.twoMonthDiscountAccepted(payload)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeDiscountOffer == _vm.twoMonthDiscountOffer &&
                _vm.step == _vm.discountOfferStep &&
                _vm.discountOfferAccepted
                  ? _c("TwoMonthDiscountOfferAccepted", {
                      on: {
                        start: payload => this.stepStart(payload),
                        "next-step": _vm.flowEnd
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeDiscountOffer == _vm.oneMonthFreeOffer &&
                _vm.step == _vm.discountOfferStep &&
                !_vm.discountOfferAccepted
                  ? _c("OfferOneFreeMonth", {
                      attrs: {
                        cancelPredefinedReason: _vm.cancelPredefinedReason,
                        cancelReasonExtraDetail: _vm.cancelReasonExtraDetail,
                        cancelFeedback: _vm.cancelFeedback
                      },
                      on: {
                        start: payload => this.stepStart(payload),
                        "next-step": _vm.nextStep,
                        "free-one-month-offer-accepted": payload =>
                          this.freeOneMonthOfferAccepted(payload)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeDiscountOffer == _vm.oneMonthFreeOffer &&
                _vm.step == _vm.discountOfferStep &&
                _vm.discountOfferAccepted
                  ? _c("OneFreeMonthOfferAccepted", {
                      on: {
                        start: payload => this.stepStart(payload),
                        "next-step": _vm.flowEnd
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 2
                  ? _c("CancellationSurvey", {
                      on: {
                        start: payload => this.stepStart(payload),
                        "next-step": payload => this.cancelConfirmation(payload)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 3
                  ? _c("CancelSubscriptionConfirmation", {
                      on: {
                        start: payload => this.stepStart(payload),
                        "next-step": _vm.nextStep
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 4
                  ? _c("CancellationFlowSurvey", {
                      on: {
                        start: payload => this.stepStart(payload),
                        "log-analytics": payload =>
                          _vm.log(_vm.build_step_props(payload)),
                        "next-step": _vm.flowEnd
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }