import { mapActions } from 'vuex'

export default {
	methods: {
		...mapActions('Flows', ['fetchFlows']),
		duplicate(flow) {
			const duplicateFlowBody = {
				flow_id: flow.flow_id,
			}

			this.$axios
				.post('v1/flows/duplicate', duplicateFlowBody)
				.then(async res => {
					this.loading = false
					this.$stanAnalytics('flow-duplicated', {
						meta: { user_id: this.user.user_id, username: this.user.username },
						props: {
							flow_id: res.data.flow_id,
						},
					})
					await this.fetchFlows()
					if (res.data.type === this.$constants.FLOW_TYPE.EVENT_TRIGGERED) {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Done!'),
							text: this.$t('Your flow has been duplicated.'),
						})
						this.focusFlow(res.data.flow_id)
					} else if (res.data.type === this.$constants.FLOW_TYPE.USER_TRIGGERED) {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Done!'),
							text: this.$t('Your broadcast has been duplicated.'),
						})
						this.scrollToBroadcast(res.data.flow_id)
					}
				})
				.catch(() => {
					this.$stanNotify({
						type: 'error',
						title: this.$t('Something went wrong'),
						text: this.$t('Please contact us at friends@stanwith.me'),
					})
				})
		},
		// AI-generated helper method.
		checkSchedule(schedule) {
			// Get the current date and time
			const currentDate = new Date()
			const currentTimestamp = currentDate.getTime() // Get the current timestamp in milliseconds

			// Extract the values from the provided data
			const { selectedDate, selectedTime, selectedTimezone } = schedule

			// Convert selectedDate and selectedTime into a JavaScript Date object
			const [year, month, day] = selectedDate.split('-').map(Number)
			const hour = Math.floor(selectedTime)
			const minute = Math.round((selectedTime - hour) * 60)
			const selectedDateTime = new Date(year, month - 1, day, hour, minute, 0, 0)

			// Convert selectedDateTime to UTC based on the selectedTimezone
			const selectedDateTimeUTC = new Date(selectedDateTime.toLocaleString('en-US', { timeZone: selectedTimezone }))

			// Calculate the difference in milliseconds between the selected time (in UTC) and the current time (in UTC)
			const timeDifference = selectedDateTimeUTC.getTime() - currentTimestamp

			// Check if the time difference is at least 0
			const isAtLeastNow = timeDifference >= 0

			// Check if the time difference is at least 10 minutes
			const isAtLeast10MinutesFromNow = timeDifference >= 10 * 60 * 1000

			return { isAtLeastNow, isAtLeast10MinutesFromNow }
		},
	},
}
