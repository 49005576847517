var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.internalValue
    ? _c("div", { staticClass: "d-flex flex-column gap-12px" }, [
        _c(
          "div",
          [
            _c("AppButtonGroup", {
              staticClass: "mb-3",
              attrs: {
                options: _vm.intervalOptions,
                initialValue: _vm.recurringIntervalType
              },
              on: { selected: _vm.toggleRecurringPrice }
            }),
            _vm._v(" "),
            _c("PriceForm", {
              attrs: {
                validation: _vm.priceValidation,
                currency: _vm.user.data.currency,
                recurring:
                  _vm.recurringIntervalType === "recurring" &&
                  !_vm.isAnnualSubscriptionDiscountEnabled,
                hideRecurringSubscription:
                  _vm.isAnnualSubscriptionDiscountEnabled
              },
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "position-relative" },
          [
            _c(
              "div",
              {
                class: {
                  "creator-pro-feature-not-allowed": !_vm.isAdvancedPricingAllowed
                }
              },
              [
                _vm.annualSubscriptionExperiment &&
                _vm.internalValue.price_options &&
                _vm.internalValue.price_options[_vm.annualSubscriptionKey] &&
                !_vm.isStoreFunnel &&
                _vm.recurringIntervalType == "recurring"
                  ? _c("AnnualSubscriptionDiscountOption", {
                      attrs: {
                        validation:
                          _vm.priceValidation.price_options.$each[
                            _vm.annualSubscriptionKey
                          ],
                        currency: _vm.user.data.currency,
                        disabled:
                          _vm.internalValue.interval != "month" ||
                          (!_vm.isStripeConnected &&
                            !_vm.isStripeMarketplaceConnected)
                      },
                      model: {
                        value:
                          _vm.internalValue.price_options[
                            _vm.annualSubscriptionKey
                          ],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.internalValue.price_options,
                            _vm.annualSubscriptionKey,
                            $$v
                          )
                        },
                        expression:
                          "internalValue.price_options[annualSubscriptionKey]"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isStoreFunnel &&
                _vm.recurringIntervalType !== "recurring" &&
                _vm.internalValue.amount
                  ? _c("StanFlexiblePaymentOption", {
                      attrs: {
                        validation: _vm.priceValidation.stan_flexible_payment,
                        currency: _vm.user.data.currency,
                        oneTimePrice: _vm.internalValue.amount,
                        disabled:
                          !_vm.isPaid ||
                          (!_vm.isStripeConnected &&
                            !_vm.isStripeMarketplaceConnected)
                      },
                      model: {
                        value: _vm.internalValue.stan_flexible_payment,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.internalValue,
                            "stan_flexible_payment",
                            $$v
                          )
                        },
                        expression: "internalValue.stan_flexible_payment"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isAnnualSubscriptionDiscountEnabled
                  ? _c("DiscountCodes", {
                      staticClass: "mb-3",
                      attrs: {
                        enableDuration:
                          _vm.recurringIntervalType === "recurring",
                        validation: _vm.priceValidation
                      },
                      on: {
                        addDiscountCode: () => _vm.$emit("addDiscountCode"),
                        removeDiscountCode: i =>
                          _vm.$emit("removeDiscountCode", i)
                      },
                      model: {
                        value: _vm.internalValue,
                        callback: function($$v) {
                          _vm.internalValue = $$v
                        },
                        expression: "internalValue"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isAdvancedPricingAllowed
              ? _c("AppUpgrade", {
                  attrs: { buttonTitle: "Upgrade to Unlock" }
                })
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }