<template>
	<div class="back-button">
		<InlineSvg :src="src" class="back-button-icon" :width="widthString" :height="heightString" @click="$emit('click', $event)" />
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		props: {
			src: { type: String, required: true },
			height: { type: Number },
			width: { type: Number },
		},
		computed: {
			widthString() {
				return this.width ? `${this.width}px` : undefined
			},
			heightString() {
				return this.height ? `${this.height}px` : undefined
			},
		},
	}
</script>

<style lang="scss" scoped>
	.back-button {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: var(--stan-gray-primary-color);
		width: 24px;
		height: 24px;
		text-align: center;
		cursor: pointer;
		.back-button-icon {
			opacity: 0.9;
		}
	}
</style>
