<template>
	<nav aria-label="breadcrumb transparent" class="d-flex align-items-center">
		<InlineSvg v-if="showBackButton" src="/images/ic-left-arrow.svg" class="mr-2 cursor-pointer back-button" @click="goBack" />
		<ol class="breadcrumb transparent mb-0 px-0 d-lg-inline-flex d-none">
			<li
				v-for="(item, index) in filteredBreadcrumbs"
				:key="index"
				class="breadcrumb-item h2 text-bold"
				:class="{ active: item.active }"
				aria-current="page"
			>
				<a class="p-0 m-0" v-if="item.to" @click="$emit('activeTab', item.to, item.query, item.params)">{{ item.title }}</a>
				<span v-else>{{ item.title }}</span>
			</li>
		</ol>
		<div class="breadcrumb transparent mb-0 px-0 d-lg-none d-inline-flex">
			<div class="h3 text-bold " :class="{ active: currentPage.active }" aria-current="page">
				<a class="p-0 m-0" v-if="currentPage.to" @click="$emit('activeTab', currentPage.to, currentPage.query, currentPage.params)">{{
					currentPage.title
				}}</a>
				<span v-else>{{ currentPage.title }}</span>
			</div>
		</div>
	</nav>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		props: ['route'],
		computed: {
			...mapGetters('Global', ['getBreadcrumbs']),
			breadcrumbs() {
				if (this.getBreadcrumbs && this.getBreadcrumbs.length > 0) {
					return this.getBreadcrumbs
				}
				return this.route.meta.breadcrumbs
			},
			filteredBreadcrumbs() {
				return this.breadcrumbs.filter(b => b.title)
			},
			showBackButton() {
				return this.breadcrumbs?.length > 1
			},
			breadCrumbSize() {
				return this.breadcrumbs?.length ?? 0
			},
			currentPage() {
				return this.breadcrumbs?.[this.breadCrumbSize - 1]
			},
			previousPage() {
				return this.breadcrumbs?.[this.breadCrumbSize - 2]
			},
		},
		methods: {
			goBack() {
				this.previousPage && this.$emit('activeTab', this.previousPage.to, this.previousPage.query, this.previousPage.params)
			},
		},
	}
</script>
<style lang="scss" scoped>
	.breadcrumb {
		li {
			&.active {
				color: var(--stan-primary-dark-color) !important;
				opacity: 1;
			}
			&::after {
				color: var(--stan-gray-dark-color) !important;
			}
			a {
				color: var(--stan-gray-dark-color);
			}
		}
	}

	.role-selector {
		@media (max-width: 767px) {
			margin-right: 15px !important;
		}
	}
	.breadcrumb-item + .breadcrumb-item::before {
		content: '';
		display: none !important;
		padding: 0;
	}
	.breadcrumb-item::after {
		content: '/';
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.breadcrumb-item + .breadcrumb-item {
		padding-left: 0px;
	}
	nav {
		li:last-child {
			&.breadcrumb-item::after {
				display: none;
			}
		}
	}
	.back-button::v-deep {
		padding-top: 1px;
		path {
			fill: var(--stan-text-light-color) !important;
		}
	}
</style>
