var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c(
        "div",
        {
          staticClass: "order-bump-section",
          class: { "creator-pro-feature-not-allowed": !_vm.isOrderBumpAllowed }
        },
        [
          _c("div", { staticClass: "mb-3 text-sm" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.$t(
                    "Incentivize your customers to purchase more with a one-time offer in your checkout flow and watch your sales increase by ~20%."
                  )
                ) +
                "\n\t\t\t"
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://help.stan.store/article/37-order-bumps",
                  target: "_blank",
                  tabindex: _vm.isOrderBumpAllowed || !_vm.isPaid ? 1 : -1
                }
              },
              [_vm._v(_vm._s(_vm.$t("Learn more")))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-start align-items-center"
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "text-bold mr-2 mb-0",
                    class: {
                      "text-light":
                        !_vm.isOrderBumpAllowed ||
                        !_vm.isPaid ||
                        !_vm.dropdownEnabled
                    }
                  },
                  [_vm._v("Enable Order Bump")]
                ),
                _vm._v(" "),
                _c("AppToggle", {
                  attrs: {
                    disabled:
                      !_vm.isOrderBumpAllowed ||
                      !_vm.isPaid ||
                      !_vm.dropdownEnabled
                  },
                  model: {
                    value: _vm.value.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "enabled", $$v)
                    },
                    expression: "value.enabled"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isOrderBumpAllowed || !_vm.isPaid || !_vm.dropdownEnabled
              ? _c(
                  "div",
                  { staticClass: "form-group mt-3 mb-0" },
                  [_c("Tip", { attrs: { tipSource: _vm.orderBumpIssue } })],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "AppAccordion",
            [
              _c(
                "AppAccordionItem",
                {
                  staticClass: "mb-0 overflow-visible shadow-none mt-3",
                  attrs: {
                    showHeader: false,
                    isWrapper: true,
                    name: _vm.accordionId
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "px-3" },
                    [
                      _c("AppDropdown", {
                        key: _vm.dropdownComponentKey,
                        staticClass: "form-control",
                        attrs: {
                          options: _vm.pageOptions,
                          label: "title",
                          reduce: "id",
                          searchable: false,
                          placeholder: _vm.$t("Select Option"),
                          disabled: !_vm.dropdownEnabled || !_vm.isPaid
                        },
                        model: {
                          value: _vm.value.page_id,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "page_id", $$v)
                          },
                          expression: "value.page_id"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group mt-3 mb-0" },
                        [
                          _c("Tip", { attrs: { tipSource: _vm.orderBumpInfo } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isOrderBumpAllowed
        ? _c("AppUpgrade", {
            attrs: {
              title: "Want to include a one-time offer to your checkout flow?"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }