var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AppDropdown", {
        ref: "timezone-dropdown",
        class: _vm.dropdownClass,
        attrs: {
          options: _vm.timeZonesList,
          placeholder: _vm.$t("Time Zone"),
          reduce: "name",
          label: "label",
          dropdownRowComponent: _vm.timezoneRowComponent,
          dropdownAutoClose: false,
          manualControl: _vm.manualControl,
          icon: _vm.icon,
          disabled: _vm.disabled,
          searchable: _vm.searchable
        },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }