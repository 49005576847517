<template>
	<div tabindex="0">
		<div class="d-flex align-items-center dropdown-item time-zone-wrapper" @click.stop="selectTimeZone">
			<div class="time-zone-iso">{{ option.iso }}&nbsp;-</div>
			<div class="time-zone-name">{{ option.showName }}</div>
			<div class="time-zone-offset">|&nbsp;UTC&nbsp;{{ offset }}</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppTimeZoneRow',
		props: {
			option: {
				type: Object,
				default: () => {},
			},
		},
		methods: {
			selectTimeZone() {
				if (this.option.zones && this.option.zones.length > 0) {
					const zone = this.option.zones[0]
					this.$emit('click', { id: this.option.id, label: this.option.name, name: zone })
				}
			},
		},
		computed: {
			offset() {
				if (this.option.offset > 0) {
					return `+${this.option.offset}`
				}
				return `${this.option.offset}`
			},
		},
	}
</script>

<style lang="scss" scoped>
	.time-zone {
		&-wrapper {
			cursor: pointer;
			min-height: 38px;
			padding: 10px 12px;
			gap: 5px;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.02em;
		}
		&-iso {
			color: var(--stan-text-dark-color);
			font-weight: 600;
		}
		&-name {
			max-width: 261px;
			text-overflow: ellipsis;
			overflow: hidden;
			color: var(--stan-text-dark-color);
			&.selector {
				padding: 4px 12px 4px 36px;
			}
		}
		&-offset {
			color: var(--stan-text-light-color);
		}
	}
</style>
