<template>
	<div
		class="position-relative cursor-pointer d-flex flex-row justify-content-between align-self-center plan-options-container"
		:class="{ 'hide-discount-experiment': hidePlatformDiscountPercentExperiment }"
	>
		<div
			class="plan-option-selected bg-white position-absolute d-flex justify-content-center align-items-center h-100"
			:class="{
				'yearly-plan': isYearlyPlan,
				'monthly-plan': !isYearlyPlan,
				'hide-discount-experiment': hidePlatformDiscountPercentExperiment,
			}"
		>
			<span v-if="isYearlyPlan" class="para-2 text-bold text-light">
				{{ annualSliderText }}
			</span>
			<span v-else class="para-2 text-bold text-light">
				Monthly
			</span>
		</div>
		<span class="para-2 text-bold text-light monthly-plan-unselected" @click="handleToggleInterval($constants.TIER_INTERVAL.MONTH)">
			Monthly
		</span>
		<span class="para-2 text-bold text-light yearly-plan-unselected" @click="handleToggleInterval($constants.TIER_INTERVAL.YEAR)">
			{{ annualSliderText }}
		</span>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'PlanIntervalSelector',
		props: {
			interval: {
				default: '',
				type: String,
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('PlatformSubscriptions', ['isLoading']),
			annualSliderText() {
				if (!this.isLoading && !this.hidePlatformDiscountPercentExperiment) {
					return `Annual (Save 20%)`
				}

				return 'Annual'
			},
			currentInterval() {
				return this.interval
			},
			isYearlyPlan() {
				return this.interval === this.$constants.TIER_INTERVAL.YEAR
			},
			hidePlatformDiscountPercentExperiment() {
				return this.user?.experiments?.hide_platform_discount_percent
			},
		},
		methods: {
			handleToggleInterval(newInterval) {
				if (newInterval !== this.interval) {
					this.$emit('toggleInterval', newInterval)
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.plan-options-container {
		background-color: var(--stan-gray-soft-color);
		border-radius: 50px;
		width: 270px;
	}

	.plan-options-container.hide-discount-experiment {
		width: 200px;
	}

	.monthly-plan-unselected {
		padding: 12px 0px 12px 23px;
	}

	.yearly-plan-unselected {
		padding: 12px 23px 12px 0px;
	}

	.plan-option-selected {
		top: 0;
		left: 0;
		transition: transform 0.3s ease;
		border-radius: 25px;
		box-shadow: 0px 5px 20px 0px #00106d1a, 0px -5px 10px 0px #00000005 inset; // Not --stan-box-shadow

		&.monthly-plan {
			width: 100px;
		}

		&.yearly-plan {
			width: 180px;
			transform: translateX(90px);

			&.hide-discount-experiment {
				width: 110px;
			}
		}
	}
</style>
