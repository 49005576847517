var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block block--callout block--preview" }, [
    _vm.image
      ? _c(
          "div",
          { staticClass: "d-none block__image" },
          [
            _c("image-preview", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading"
                }
              ],
              attrs: {
                source: _vm.image,
                width: 433,
                height: 433,
                alt: "block image"
              }
            }),
            _vm._v(" "),
            _vm.isLoading ? _c("LoadingSpinner") : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "block__content" }, [
      _vm.image
        ? _c(
            "div",
            { staticClass: "block__image" },
            [
              _c("image-preview", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoading,
                    expression: "!isLoading"
                  }
                ],
                attrs: {
                  source: _vm.image,
                  width: 433,
                  height: 433,
                  alt: "block image"
                },
                on: { isLoading: _vm.handleLoading }
              }),
              _vm._v(" "),
              _vm.isLoading
                ? _c("LoadingSpinner", {
                    attrs: { wrapperStyles: { "aspect-ratio": "1 / 1" } }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "block__text" }, [
        _c("h4", { staticClass: "block__heading" }, [
          _vm._v(_vm._s(_vm.heading))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "block__subheading" }, [
          _vm._v(_vm._s(_vm.tagline))
        ]),
        _vm._v(" "),
        _vm.isPrice
          ? _c(
              "div",
              { staticClass: "price-review" },
              [
                _c("Price", {
                  attrs: {
                    currency: _vm.user.data.currency,
                    amount: _vm.price.amount,
                    "sale-amount": _vm.price.sale_amount_available
                      ? _vm.price.sale_amount
                      : 0
                  }
                }),
                _vm._v(" "),
                _vm.showDiscount
                  ? _c("PriceOff", {
                      attrs: {
                        amount: _vm.price.amount,
                        "sale-amount": _vm.price.sale_amount_available
                          ? _vm.price.sale_amount
                          : 0
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isReviews
                  ? _c(
                      "div",
                      {
                        staticClass: "rating",
                        style: { "--avg-rating": _vm.avgReviews }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "13",
                              height: "13",
                              viewBox: "0 0 13 13",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z",
                                fill: "#FEC337"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.avgReviews))])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "flex-linebreak" }),
                _vm._v(" "),
                _vm.quantity != undefined
                  ? _c("div", { staticClass: "d-none inventory" }, [
                      _c(
                        "span",
                        {
                          class: {
                            "in-stock": _vm.isInStock,
                            "out-of-stock": !_vm.isInStock,
                            "low-stock": _vm.isLowStock
                          }
                        },
                        [_vm._v(_vm._s(_vm.quantity_text))]
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.quantity != undefined
          ? _c("div", { staticClass: "inventory" }, [
              _c(
                "span",
                {
                  class: {
                    "in-stock": _vm.isInStock,
                    "out-of-stock": !_vm.isInStock,
                    "low-stock": _vm.isLowStock
                  }
                },
                [_vm._v(_vm._s(_vm.quantity_text))]
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "block__content--preview" },
      [
        _vm.productType === "meeting"
          ? _c("Calendar", {
              attrs: {
                preview: true,
                "primary-color": _vm.userPrimaryColor,
                slug: _vm.product.meeting.slug,
                username: _vm.user.username,
                isDemo: _vm.isDemo
              },
              on: { "date-selected": _vm.preSelectCalendarDate }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.productType === "webinar"
          ? _c("WebinarCalendar", {
              attrs: {
                preview: true,
                "primary-color": _vm.userPrimaryColor,
                slug: _vm.product.webinar.slug,
                username: _vm.user.username,
                isDemo: _vm.isDemo
              },
              on: { "time-selected": _vm.preSelectWebinarSession }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.productType === "course"
          ? _c("CoursePreview", {
              attrs: {
                preview: true,
                "primary-color": _vm.userPrimaryColor,
                product: _vm.product,
                username: _vm.user.username
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.productType === "digital-download"
          ? _c("DigitalDownloadPreview", {
              attrs: {
                preview: true,
                "primary-color": _vm.userPrimaryColor,
                product: _vm.product,
                username: _vm.user.username
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "cta-button" }, [
      _c(
        "button",
        {
          staticClass: "cta-button__button",
          on: {
            click: function($event) {
              return _vm.$emit("buttonClick", { preSelectValue: true })
            }
          }
        },
        [
          _c("div", { staticClass: "cta-button__label" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-none cta-button__icon--right" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "20",
                  height: "17",
                  viewBox: "0 0 20 17",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M19.4524 8.11813L10.6704 0.695421C10.3454 0.420714 9.84766 0.651735 9.84766 1.07729V15.9227C9.84766 16.3483 10.3454 16.5793 10.6704 16.3046L19.4524 8.88187C19.6887 8.68213 19.6887 8.31787 19.4524 8.11813Z",
                    fill: "white"
                  }
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "0.796875",
                    y: "6.1842",
                    width: "10.321",
                    height: "4.73679",
                    rx: "0.5",
                    fill: "white"
                  }
                })
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cta-button-secondary" }, [
      _c(
        "button",
        {
          staticClass: "cta-button__button",
          on: {
            click: function($event) {
              return _vm.$emit("buttonClick", {})
            }
          }
        },
        [
          _c("div", { staticClass: "cta-button__label" }, [
            _vm._v(_vm._s(_vm.$t("shared.site_button.learn_more")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-none cta-button__icon--right" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "20",
                  height: "17",
                  viewBox: "0 0 20 17",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M19.4524 8.11813L10.6704 0.695421C10.3454 0.420714 9.84766 0.651735 9.84766 1.07729V15.9227C9.84766 16.3483 10.3454 16.5793 10.6704 16.3046L19.4524 8.88187C19.6887 8.68213 19.6887 8.31787 19.4524 8.11813Z",
                    fill: "white"
                  }
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "0.796875",
                    y: "6.1842",
                    width: "10.321",
                    height: "4.73679",
                    rx: "0.5",
                    fill: "white"
                  }
                })
              ]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }