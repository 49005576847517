<template>
	<AppModal
		ref="modal"
		@cta="submitModalForm"
		@secondary-cta="hide"
		action="Confirm"
		secondary-action="Cancel"
		title="Add Audience"
		:loading="isLoading"
		hideClose
		fullscreenOnMobile
		large
	>
		<div class="audience-modal-container">
			<div class="audience-body">
				<AppTableV3
					class="desktop audience-table"
					:page="parseInt(currentPage)"
					:totalPages="totalPages"
					@paginateHandle="paginateHandle"
					:isLoading="isLoading"
					:isEmpty="!totalPages"
					:emptyHeader="computedEmptyHeader"
					:emptySubheader="computedEmptySubheader"
					removeTableWrapperStyling
				>
					<template #subheader>
						<div class="mb-md-2 overflow-x">
							<template>
								<div class="d-flex flex-column justify-content-between align-items-top tag-editor">
									<EditFanTags
										class="w-100"
										ref="editFanTags"
										:showTitle="false"
										mode="include-tags"
										:pseudoTags="productOptions"
										:disabled="updatesDisabled"
										:searchable="false"
										@tags-updated="tagsUpdated"
										@fan-tag-list-loaded="populateTags"
									/>
									<div class="d-flex align-items-center">
										<AppToggle
											toggleId="exclude-users-toggle"
											v-model="excludeUsersToggle"
											size="sm"
											:disabled="updatesDisabled"
										/>
										<label for="exclude-users-toggle" class="ml-2 para-3 mb-0">Exclude Users</label>
									</div>

									<div v-if="excludeUsersToggle">
										<EditFanTags
											class="w-100"
											ref="editExcludedFanTags"
											:showTitle="false"
											mode="exclude-tags"
											:pseudoTags="productOptions"
											:disabled="updatesDisabled"
											:searchable="false"
											@tags-updated="excludedTagsUpdated"
											@fan-tag-list-loaded="populateExcludedTags"
										/>
									</div>
								</div>
							</template>
						</div>
					</template>
					<template #footer>
						<div class="selected-count">{{ recipientCount }} recipients selected</div>
					</template>
					<tr>
						<th>Name</th>
						<th class="mobile-column">Email</th>
					</tr>
					<tr v-for="customer in paginatedCurrentFanList" :key="customer.fan_id">
						<td class="text-truncate">{{ customer.name }}</td>
						<td class="text-truncate mobile-column">{{ customer.email }}</td>
					</tr>
				</AppTableV3>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				filterArray: [],
				availableFanTags: [],
				pageSize: 5,
				excludeUsersToggle: false,
				tags: [],
				excludedTags: [],
				currentFanList: [],
				recipientCount: 0,
				isLoading: false,
				currentPage: 1,
				everyoneTag: { name: 'Everyone', type: 'pseudo', data: {} },
			}
		},
		mounted() {
			this.refreshRecipientCount()
		},
		computed: {
			...mapGetters('Stores', ['getStore', 'getFunnels']),
			paginatedCurrentFanList() {
				const startIndex = (this.currentPage - 1) * this.pageSize
				const endIndex = startIndex + this.pageSize
				return this.currentFanList.slice(startIndex, Math.min(endIndex, this.currentFanList.length))
			},
			productOptions() {
				return [...this.$commonHelper.buildProductList(this.getStore, this.getFunnels)]
			},
			updatesDisabled() {
				return this.saving || (this.status === this.$constants.FLOW_STATUS.PUBLISHED && this.pastAllowedRescheduleTime)
			},
			totalPages() {
				return Math.ceil(this.recipientCount / this.pageSize)
			},
			computedEmptyHeader() {
				if (this.computedTags.length) {
					return 'No matches for this tag!'
				}

				return 'Add tags to continue'
			},
			computedEmptySubheader() {
				if (this.computedTags.length) {
					return 'Try a different tag or create a new one'
				}

				return 'Include tags to segment your customers and personalize emails'
			},
			computedTags() {
				/*
						now we need two types of tags to compute the list of recipients
						we can use 1 array or 2 arrays in the json data
						I decided to go with 1, because less is better

						Anyway, just noting now the "tag" dictionary have up to 4 keys now:
						type: if "pseudo", it's used to indicate a group based on product purchase, or "Everyone"
						name: what shows up inside the multi-select
						data: contains data like "page_id" used in pseudo-tag computation
						excluded: if === 1, it indicates this should be excluded from the recipients
						*/
				const tags = []
				this.tags.forEach(tag => {
					tags.push(tag)
				})
				this.excludedTags.forEach(tag => {
					tag.excluded = 1
					tags.push(tag)
				})
				return tags
			},
		},
		methods: {
			show() {
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')
			},
			populateTags() {
				this.$refs.editFanTags.forceInjectTags(this.tags)
			},
			populateExcludedTags() {
				this.$refs.editExcludedFanTags.forceInjectTags(this.excludedTags)
			},
			reset() {
				this.tags = []
				this.excludedTags = []
				this.currentFanList = []
				this.recipientCount = 0
				this.excludeUsersToggle = false
				this.$refs.editFanTags.reset()
			},
			tagsUpdated(event) {
				// To not update tags unless we change the tags (this call takes a bit to load)
				if (!this.areArraysEqual(this.tags, event)) {
					this.tags = event
					this.refreshRecipientCount()
				}
			},
			excludedTagsUpdated(event) {
				// To not update tags unless we change the tags (this call takes a bit to load)
				if (!this.areArraysEqual(this.excludedTags, event)) {
					this.excludedTags = event
					this.refreshRecipientCount()
				}
			},
			areArraysEqual(array1, array2) {
				if (array1.length !== array2.length) return false
				return array1.every((item, index) => JSON.stringify(item) === JSON.stringify(array2[index]))
			},
			paginateHandle(page) {
				this.currentPage = page
			},
			submitModalForm() {
				this.hide()
				this.$emit('tags-confirmed', this.computedTags || [], this.recipientCount)
			},
			refreshRecipientCount() {
				this.currentPage = 1
				const data = {
					tags: this.computedTags,
				}
				this.isLoading = true
				this.$axios
					.post('v1/tags/tag-audience', data)
					.then(({ data }) => {
						this.currentFanList = data?.fans
						this.recipientCount = data?.count
					})
					.catch(error => {
						console.error(error)
						this.recipientCount = 0
					})
					.finally(() => {
						this.isLoading = false
					})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.selected-count {
		flex: 1;
		color: var(--stan-primary-primary-color);
		font-size: 14px;
		font-weight: 600;
		line-height: 18px;
		letter-spacing: -0.28px;
	}
	.tag-editor {
		gap: 8px;
	}
</style>
