var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pagination-control" }, [
    _c("div", { staticClass: "button-group" }, [
      _c(
        "button",
        {
          staticClass: "pagination-arrow",
          class: { "pagination-arrow-disabled": _vm.isLeftDisabled },
          attrs: {
            disabled: _vm.isLeftDisabled,
            "aria-label": "Previous item"
          },
          on: { click: _vm.onLeftClick }
        },
        [
          _c("InlineSvg", {
            attrs: { src: "images/icons/ic-chevron-left.svg" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "pagination-arrow",
          class: { "pagination-arrow-disabled": _vm.isRightDisabled },
          attrs: { disabled: _vm.isRightDisabled, "aria-label": "Next item" },
          on: { click: _vm.onRightClick }
        },
        [
          _c("InlineSvg", {
            attrs: { src: "images/icons/ic-chevron-right.svg" }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }