var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "modal",
      attrs: {
        id: "stanleyAiHaveDigitalDownload",
        title: "Describe Your Digital Product",
        subheading:
          "Tell us what you want to create—be it a guide, eBook, or series. Include details like topic, tone, and structure.",
        action: "Generate"
      },
      on: { cta: _vm.genetate }
    },
    [
      _c("div", { staticClass: "mt-4" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content"
            }
          ],
          staticClass: "form-control styled-input",
          attrs: {
            rows: "5",
            name: "content",
            placeholder: _vm.$t(
              "(e.g., a step-by-step TikTok guide or a business guide for Etsy)."
            )
          },
          domProps: { value: _vm.content },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.content = $event.target.value
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }