<template>
	<AppModal ref="modal" id="stanleyAiHaveDigitalDownload" title="Do you have a digital download ready to sell?">
		<!-- d-flex flex-row gap-2 align-items-center w-100 -->
		<div class="d-flex flex-row flex-wrap gap-3 w-100 align-items-center justify-content-center">
			<div class="option-box">
				<InlineSvg src="images/ai-have-product.svg" class="px-1" />
				<div class="mt-1 mb-3">Yes I do!</div>
				<AppButton name="save-cancellation-flow-survey" size="sm" @click="useExisting" :outline="true">Create my product</AppButton>
			</div>
			<div class="option-box">
				<InlineSvg src="images/ai-need-product.svg" class="px-1" />
				<div class="mt-1 mb-3">No, I need help</div>
				<AppButton name="save-cancellation-flow-survey" size="sm" :outline="true" @click="goToStanley">Build with AI</AppButton>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		computed: {
			...mapGetters('Auth', ['user']),
		},
		methods: {
			show() {
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
			},
			useExisting() {
				this.$stanAnalytics('ai-use-existing-product', { meta: { user_id: this.user.user_id, username: this.user.username } })
				this.hide()
			},
			async goToStanley() {
				$(this.$refs.modal.$el).modal('hide')
				await this.$nextTick()
				this.$router.push({
					name: 'ask_stanley',
					query: {
						q: 'aiDigitalDownload',
					},
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.option-box {
		border-radius: 12px;
		border: solid var(--stan-gray-light-color) 2px;
		display: flex;
		flex-direction: column;
		padding: 10px;
		justify-content: start;
		align-items: start;
		width: 200px;
	}
</style>
