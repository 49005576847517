var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "back-button" },
    [
      _c("InlineSvg", {
        staticClass: "back-button-icon",
        attrs: {
          src: _vm.src,
          width: _vm.widthString,
          height: _vm.heightString
        },
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }