<template>
	<div class="d-flex flex-column gap-12px" v-if="internalValue">
		<div>
			<AppButtonGroup class="mb-3" :options="intervalOptions" :initialValue="recurringIntervalType" @selected="toggleRecurringPrice" />
			<PriceForm
				v-model="internalValue"
				:validation="priceValidation"
				:currency="user.data.currency"
				:recurring="recurringIntervalType === 'recurring' && !isAnnualSubscriptionDiscountEnabled"
				:hideRecurringSubscription="isAnnualSubscriptionDiscountEnabled"
			/>
		</div>
		<div class="position-relative">
			<div :class="{ 'creator-pro-feature-not-allowed': !isAdvancedPricingAllowed }">
				<AnnualSubscriptionDiscountOption
					v-if="
						annualSubscriptionExperiment &&
							internalValue.price_options &&
							internalValue.price_options[annualSubscriptionKey] &&
							!isStoreFunnel &&
							recurringIntervalType == 'recurring'
					"
					v-model="internalValue.price_options[annualSubscriptionKey]"
					:validation="priceValidation.price_options.$each[annualSubscriptionKey]"
					:currency="user.data.currency"
					:disabled="internalValue.interval != 'month' || (!isStripeConnected && !isStripeMarketplaceConnected)"
				/>
				<StanFlexiblePaymentOption
					v-if="!isStoreFunnel && recurringIntervalType !== 'recurring' && internalValue.amount"
					v-model="internalValue.stan_flexible_payment"
					:validation="priceValidation.stan_flexible_payment"
					:currency="user.data.currency"
					:oneTimePrice="internalValue.amount"
					:disabled="!isPaid || (!isStripeConnected && !isStripeMarketplaceConnected)"
				/>
				<DiscountCodes
					v-if="!isAnnualSubscriptionDiscountEnabled"
					class="mb-3"
					v-model="internalValue"
					:enableDuration="recurringIntervalType === 'recurring'"
					:validation="priceValidation"
					@addDiscountCode="() => $emit('addDiscountCode')"
					@removeDiscountCode="i => $emit('removeDiscountCode', i)"
				/>
			</div>
			<AppUpgrade v-if="!isAdvancedPricingAllowed" buttonTitle="Upgrade to Unlock" />
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'ProductPriceForm',
		beforeUpdate() {
			// debugger
		},
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			isStoreFunnel: {
				type: Boolean,
				default: false,
			},
			isStoreLinkSite: {
				type: Boolean,
				default: false,
			},
			isStoreLandingPage: {
				type: Boolean,
				default: false,
			},
			priceValidation: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				recurringIntervalType: this.value?.interval ? 'recurring' : 'one-time', // 'one-time' or 'recurring'
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('SiteSettingsStore', [
				'isStripeConnected',
				'isStripeAfterpayEnabled',
				'stripeDefaultCurrency',
				'stripeCountryDefaultCurrency',
				'isStripeMarketplaceConnected',
			]),
			internalValue: {
				get() {
					return this.value
				},
				set(newValue) {
					this.$emit('input', newValue)
				},
			},
			intervalOptions() {
				return [
					{
						name: 'One-Time',
						value: 'one-time',
						label: 'One-Time Payment',
						mobileLabel: 'One-Time',
					},
					{
						name: 'Subscription',
						value: 'recurring',
						label: 'Subscription',
						mobileLabel: 'Subscription',
					},
				]
			},
			isAdvancedPricingAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('advanced-pricing') !== -1
			},
			isPaid() {
				const price = this.internalValue
				return (price?.sale_amount ? price?.sale_amount : price?.amount) > 0
			},
			isAnnualSubscriptionDiscountEnabled() {
				return this.internalValue?.price_options?.[this.annualSubscriptionKey]?.enabled && this.recurringIntervalType === 'recurring'
			},
			annualSubscriptionExperiment() {
				return this.user.experiments?.annual_monthly_subscription === 1
			},
			annualSubscriptionKey() {
				return this.$constants.PRICE_OPTION_NAMES.ANNUAL_SUBSCRIPTION
			},
		},
		methods: {
			getCurrencySymbol: commonHelper.getCurrencySymbol,
			formatCurrency: commonHelper.formatCurrency,
			toggleRecurringPrice(recurringIntervalType) {
				this.recurringIntervalType = recurringIntervalType
				if (this.recurringIntervalType === 'one-time') {
					this.intervalBuffer = this.internalValue.interval
					this.internalValue.interval = undefined
				} else if (this.recurringIntervalType === 'recurring' && !this.internalValue.interval) {
					this.internalValue.interval = this.intervalBuffer || 'month'
					this.internalValue = this.$lodash.cloneDeep(this.internalValue) // force preview to update
				}
			},

			addDiscountCode() {
				const newCode = this.$lodash.cloneDeep(this.discountCodeTemplate)
				this.internalValue.discount_codes.push(newCode)
			},
		},
		directives: {},
		watch: {
			isAnnualSubscriptionDiscountEnabled: {
				immediate: true,
				handler(newVal) {
					// If annual subscription discount is enabled force price to be recurring monthly and no ending
					if (newVal) {
						this.internalValue.membership_duration_available = false
						this.internalValue.membership_duration = 0
						this.internalValue.interval = 'month'
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.price-input-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 18px;
		align-items: center;
		@media (max-width: 575px) {
			grid-template-columns: auto;
		}
		.error {
			opacity: 0;
			display: block;
			font-size: 70%;
			@media (max-width: 767px) {
				font-size: 10px;
			}
		}
		.form-error {
			.error {
				opacity: 1;
			}
		}
		.price-wrapper {
			display: flex;
			flex-direction: column;
		}
	}
</style>
