<template>
	<div>
		<div :class="{ 'table-wrapper': !removeTableWrapperStyling }">
			<div v-if="title || icon" class="table-wrapper-header">
				<div class="stan-icon-wrapper">
					<InlineSvg :src="icon" class="stan-icon mr-2" />
					<h3 class="text-bold">{{ title }}</h3>
				</div>
			</div>
			<slot name="subheader" class="d-flex flex-wrap"></slot>
			<div class="table-container">
				<div v-if="isLoading || isEmpty" class="w-100 d-flex justify-content-center my-5">
					<!-- Is Loading -->
					<InlineSvg
						v-if="isLoading"
						class="loading-icon"
						src="/images/icons/ic-reload.svg"
						style="position: absolute; place-self: center; justify-self: center; align-self: center"
					/>

					<!-- Is Empty -->
					<!-- Opacity over v-show because we don't want the size container jumping between loading -> empty, only 2 diff sizes -->
					<div
						class="w-100 h-100 d-flex flex-column align-items-center align-self-center"
						style="gap: 20px;"
						:style="{
							opacity: isLoading ? 0 : 1,
						}"
					>
						<InlineSvg src="/images/empty-table-icon.svg" />

						<div class="d-flex flex-column gap-15px text-center align-items-center">
							<h2 class="text-bold">{{ emptyHeader }}</h2>
							<span v-if="emptySubheader" v-html="emptySubheader" class="para-1 text-light" style="max-width: 300px;" />
						</div>
					</div>
				</div>
				<table v-else style="table-layout: fixed; width: 100%" class="para-2 overflow-visible">
					<slot></slot>
				</table>

				<div v-if="!isEmpty && !isLoading" class="table-wrapper-header justify-content-center justify-content-md-end">
					<slot name="footer"></slot>
					<div class="para-2 table-wrapper-header-actions">
						<div class="header-custom-slot"><slot name="header-custom"></slot></div>
						<div class="table-pagination" v-if="totalPages">
							{{ `Page ${page} of ${totalPages}` }}
							<InlineSvg
								id="previous-page"
								src="images/icons/ic-chevron-up.svg"
								@click="previousPage"
								:class="{ disabled: page === 1 }"
							/>
							<InlineSvg
								id="next-page"
								src="images/icons/ic-chevron-up.svg"
								@click="nextPage"
								:class="{ disabled: page === totalPages }"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			title: { type: String, default: '' },
			icon: { type: String, default: '' },
			page: { type: Number, default: 0 },
			totalPages: { type: Number, default: 0 },
			isLoading: { type: Boolean, default: true },
			isEmpty: { type: Boolean, default: false },
			emptyHeader: { type: String, default: 'No Entries Yet' },
			emptySubheader: { type: String, default: '' },
			removeTableWrapperStyling: { type: Boolean, default: false },
		},
		methods: {
			previousPage() {
				this.$emit('paginateHandle', Math.max(this.page - 1, 1))
			},
			nextPage() {
				this.$emit('paginateHandle', Math.min(this.page + 1, this.totalPages))
			},
		},
	}
</script>
<style lang="scss" scoped>
	$fadeInDuration: 0.3s;
	.loading {
		opacity: 50%;
	}
	table {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;

		tr {
			padding-left: 20px;
			padding-right: 20px;
			border-top: 1px solid var(--stan-grey);
			border-bottom: 1px solid var(--stan-grey);
			height: 50px;

			&:first-child {
				border-top: none;
				&:hover {
					background-color: white;
				}
			}

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				background-color: var(--stan-blue-8);
			}

			th {
				padding: 0.25rem 1rem;
				color: var(--stan-text-light-color);
				height: 50px;
				font-weight: 600;
				&.controls {
					width: 50px;
					&.has-action-button {
						width: 180px;
					}
				}
			}

			td {
				padding: 0.25rem 1rem;
				color: var(--stan-primary-dark-color);
				height: 50px;
			}
		}
	}

	tr {
		&:hover {
			cursor: pointer;
		}
	}

	.table-wrapper {
		border: none;
		border-radius: 12px;
		background-color: white;
		padding: 1rem;
		box-shadow: var(--stan-box-shadow);
	}
	.table-wrapper-header {
		display: flex;
		justify-content: space-between;
		margin: 10px;
		align-items: center;
		.table-title {
		}
		.table-wrapper-header-actions {
			display: grid;
			grid-template-columns: auto auto;
			align-items: center;
			gap: 1rem;
		}
	}
	.table-pagination {
		margin: 20px;
		margin-left: 10px;
		margin-right: 0px;
		.disabled {
			opacity: 0.3;
			pointer-events: none;
		}

		svg::v-deep {
			path {
				fill: var(--stan-primary-primary-color);
			}
		}
		#previous-page {
			cursor: pointer;
			transform: rotate(-90deg);
		}
		#next-page {
			cursor: pointer;
			transform: rotate(90deg);
		}
	}

	.table-container {
		width: 100%;
		overflow: inherit;
		overflow-x: auto;
		border-radius: 12px;
	}

	// Loading spinner
	.loading-icon {
		width: 50px;
		height: auto;
		display: flex;
		animation: rotate 2s ease-in-out infinite;
		animation-delay: calc($fadeInDuration / 2);

		svg {
			width: 100%;
			height: auto;
		}
	}

	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
</style>
