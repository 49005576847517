<template>
	<div class="w-100" :style="scssVars" :class="{ disabled: disabled }">
		<div class="d-flex justify-content-between">
			<div>
				<label v-if="label" class="control-label">{{ label }}</label>
			</div>

			<div class="character-count-container">
				<div v-if="maxLength" class="character-count para-3" :class="{ 'character-count-error': characterCount > maxLength }">
					{{ characterCount }} / {{ maxLength }}
				</div>
			</div>
		</div>
		<div>
			<FileUpload
				ref="editor-file-upload"
				@assetUp="imageUploaded"
				v-if="showImage"
				accepts="image/bmp,image/jpeg,image/png,image/gif,img/svg+xml"
			/>
			<FileUpload
				v-if="showVideoEmbed"
				ref="editor-file-upload-video"
				accepts="video/*"
				@assetUp="videoUploaded"
				@errorUp="errorUploadingVideo"
				@filePicked="startedVideoUploading"
			/>
			<div class="editor-container">
				<div v-if="editor && !readOnly && !hideMenuBar" class="menu-bar pr-2 py-2 d-flex flex-wrap align-items-center">
					<div class="px-0" :class="{ 'col-auto': variableItemsData, col: !variableItemsData }">
						<label
							v-if="extended"
							class="menu-button"
							:class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
							@click="
								editor
									.chain()
									.focus()
									.toggleHeading({ level: 3 })
									.run()
							"
						>
							<InlineSvg src="/images/icons/ic-font-size.svg" />
						</label>
						<label
							class="menu-button"
							:class="{ 'is-active': editor.isActive('bold') }"
							@click="
								editor
									.chain()
									.focus()
									.toggleBold()
									.run()
							"
						>
							<InlineSvg src="/images/icons/ic-bold.svg" />
						</label>
						<label
							class="menu-button"
							:class="{ 'is-active': editor.isActive('strike') }"
							@click="
								editor
									.chain()
									.focus()
									.toggleStrike()
									.run()
							"
						>
							<InlineSvg src="/images/icons/ic-strike.svg" />
						</label>
						<label
							class="menu-button"
							:class="{ 'is-active': editor.isActive('italic') }"
							@click="
								editor
									.chain()
									.focus()
									.toggleItalic()
									.run()
							"
						>
							<InlineSvg src="/images/icons/ic-italic.svg" />
						</label>
						<label
							class="menu-button"
							:class="{ 'is-active': editor.isActive('bulletList') }"
							@click="
								editor
									.chain()
									.focus()
									.toggleBulletList()
									.run()
							"
						>
							<InlineSvg src="/images/icons/ic-list.svg" />
						</label>
						<label class="menu-button" @click="addImage" v-if="showImage">
							<InlineSvg src="/images/icons/ic-image.svg" />
						</label>
						<label class="menu-button" @click="addLoomVideo" v-if="showVideoEmbed">
							<InlineSvg src="/images/icons/ic-video.svg" />
						</label>
						<label class="menu-button" @click="setLink" v-if="showLink">
							<InlineSvg src="/images/icons/ic-link.svg" />
						</label>
					</div>
					<AppDropdownMenu class="d-inline col px-0" v-if="showPersonalize && !showPersonalizeAtBottom && variableItemsData">
						<AppButton name="tiptap-editor-personalize" class="inverse" size="sm" outline type="button" icon="images/icons/ic-plus.svg"
							>Personalize</AppButton
						>
						<template v-slot:content>
							<button
								class="dropdown-item"
								type="button"
								v-for="item in sortedVariableItemsData"
								:key="item.id"
								@click="
									editor
										.chain()
										.focus()
										.insertContent(`<variable-item id='${item.id}' name='${item.name}' class='para-2'></variable-item>`)
										.run()
								"
							>
								{{ item.name }}
							</button>
						</template>
					</AppDropdownMenu>
					<AppButton
						v-if="showProductList"
						name="tiptap-editor-product-link"
						class="inverse"
						size="sm"
						outline
						type="button"
						icon="images/icons/ic-link.svg"
						@click="openProductSelectionModal"
						>Add Product Link</AppButton
					>
					<AppButton
						v-if="showGenerateWithAI"
						name="tiptap-editor-ai-assistance"
						class="inverse tiptap-editor-ai-button"
						size="sm"
						outline
						type="button"
						icon="images/icons/ic-ai-generate.svg"
						:loading="isAISuggestionLoading"
						:disabled="isAISuggestionLoading"
						@click="handleGenerateWithAIClick"
					>
						<span class="ai-button-text">Generate with AI</span>
					</AppButton>
					<AIPromptBox v-if="showAIPrompt" v-on-click-outside="closeAIPrompt" @requestAIAssistance="requestAIAssistance" />
				</div>
				<div :class="{ 'p-editor': !readOnly }" @drop.prevent="drop($event)" v-on:click="focusEditor()">
					<EditorContent class="col column editor__content" :editor="editor" ref="editor" id="editor-content" />
				</div>
			</div>
			<div class="bottom-options">
				<div>
					<AppDropdownMenu v-if="showPersonalize && showPersonalizeAtBottom && variableItemsData" class="d-inline col px-0">
						<a type="button" class="float-right my-2 restore-button">
							{{ $t('+ Personalize') }}
						</a>
						<template v-slot:content>
							<button
								class="dropdown-item"
								type="button"
								v-for="item in sortedVariableItemsData"
								:key="item.id"
								@click="
									editor
										.chain()
										.focus()
										.insertContent(`<variable-item id='${item.id}' name='${item.name}' class='para-2'></variable-item>`)
										.run()
								"
							>
								{{ item.name }}
							</button>
						</template>
					</AppDropdownMenu>
				</div>
				<div>
					<a
						v-if="defaultValue"
						type="button"
						class="float-right my-2 restore-button"
						@click="
							editor
								.chain()
								.focus()
								.setContent(defaultValue, true)
								.run()
						"
					>
						{{ $t('Restore Default') }}
					</a>
				</div>
			</div>
		</div>
		<div class="ai-block" v-if="videoUploading">
			<div class="loading" v-if="!videoUploadingError">
				<div class="icon mr-2">
					<AppRipple size="sm" />
				</div>
				<div class="text para-2 text-bold">Video Uploading...</div>
			</div>
			<div class="error" v-else-if="videoUploadingError">
				<div class="icon mr-2">
					<InlineSvg class="image ic-danger" src="images/icons/ic-warning-circle.svg" />
				</div>
				<div class="text para-2 text-bold">Failed to upload video.</div>
				<div class="try-again para-2 text-bold" @click="addVideo">Try Again</div>
			</div>
		</div>
		<div
			class="ai-block"
			id="ai-suggestion-block"
			v-if="isAIAssistanceRequested"
			:class="[{ error: aiError }, { 'h-0': aiSuggestionAcceptedTransition }]"
		>
			<div class="loading" v-if="isAISuggestionLoading">
				<div class="icon mr-2">
					<AppRipple size="sm" />
				</div>
				<div class="text para-2 text-bold">Generating...</div>
				<div class="cancel para-2 text-bold" @click="cancelAIRequest">Cancel</div>
			</div>
			<div class="error" v-else-if="aiError">
				<div class="icon mr-2">
					<InlineSvg class="image ic-danger" src="images/icons/ic-warning-circle.svg" />
				</div>
				<div class="text para-2 text-bold">Failed to reach Stanley.</div>
				<div class="try-again para-2 text-bold" @click="requestAIAssistance">Try Again</div>
			</div>
			<div class="suggestion" v-else>
				<div class="icon">
					<InlineSvg class="image" src="/images/icons/ic-stanley-profile.svg" />
				</div>
				<div class="text">
					<div class="para-2" v-html="AISuggestion"></div>
					<div class="buttons">
						<AppButton
							name="tiptap-editor-ai-accept"
							size="sm"
							outline
							type="button"
							icon="/images/icons/ic-thumbs-up-outline.svg"
							@click="acceptAISuggestion"
							>Replace
						</AppButton>
						<AppButton
							name="tiptap-editor-ai-cancel"
							size="sm"
							color="gray"
							outline
							type="button"
							icon="images/icons/ic-thumbs-down-outline.svg"
							@click="cancelAIRequest"
							>Dismiss</AppButton
						>
					</div>
				</div>
			</div>
		</div>

		<LinkWindow v-if="editor" :editor="editor" :attributes="linkWindowAttributes" ref="link-window" />
		<InsertVideoModal
			v-if="editor"
			:editor="editor"
			:attributes="linkWindowAttributes"
			ref="insert-video-modal"
			id="insert-video-modal"
			@uploadVideo="addVideo"
		/>
		<ProductSelectionModal v-if="showProductList" @confirm-selection="confirmProductSelection" />
	</div>
</template>
<script>
	// import Vue from 'vue'
	import { mapGetters } from 'vuex'
	import { Editor, EditorContent } from '@tiptap/vue-2'
	import StarterKit from '@tiptap/starter-kit'
	import Link from '@tiptap/extension-link'
	import Placeholder from '@tiptap/extension-placeholder'
	import Youtube from '@tiptap/extension-youtube'
	import LinkWindow from '~/components/shared/tiptap/extension/LinkWindow'
	import InsertVideoModal from '~/components/shared/tiptap/extension/InsertVideoModal'
	import AIPromptBox from '~/components/shared/tiptap/extension/AIPromptBox'
	import FileUpload from '~/stan-vue-shared/components/FileUpload'
	import VariableItem from '~/components/shared/tiptap/extension/VariableItem.js'
	import ProductSelectionModal from '~/components/modals/ProductSelectionModal'
	import ResizableImage from '~/components/shared/tiptap/extension/ImageExtension.js'
	import VideoEmbed from '~/components/shared/tiptap/extension/embedVideo.js'

	export default {
		components: {
			EditorContent,
			FileUpload,
			LinkWindow,
			ProductSelectionModal,
			InsertVideoModal,
			AIPromptBox,
		},
		props: {
			value: { type: String },
			readOnly: {
				type: Boolean,
				default: false,
			},
			label: {
				type: String,
				default: '',
			},
			placeholder: {
				type: String,
				default: 'Write Something...',
			},
			extended: Boolean,
			showImage: { type: Boolean, default: true },
			optimizeImage: { type: Boolean, default: false },
			showVideoEmbed: { type: Boolean, default: false },
			showLink: { type: Boolean, default: true },
			accentColor: {
				type: String,
				default: '#000000',
			},
			variableItemsData: { type: Array },
			defaultValue: { type: String },
			hideMenuBar: { type: Boolean, default: false },
			height: {
				type: Number,
			},
			width: {
				type: [String, Number],
				default: 'auto',
			},
			enableYoutube: {
				type: Boolean,
				default: true,
			},
			enableDrop: {
				type: Boolean,
				default: true,
			},
			showGenerateWithAI: { type: Boolean, default: false },
			AISuggestion: { type: String, default: '' },
			aiError: { type: Boolean, default: false },
			disabled: { type: Boolean, default: false },
			sendDefaultNull: { type: Boolean, default: true },
			showProductList: { type: Boolean, default: false },
			showResize: { type: Boolean, default: false },
			showPersonalizeAtBottom: { type: Boolean, default: false },
			showPersonalize: { type: Boolean, default: true },
			maxLength: {
				type: Number,
			},
			displayCharacterCount: {
				type: Boolean,
				default: false,
			},
			useOnlyPlainText: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				editor: null,
				filesUploading: 0,
				tick: 0,
				html: '',
				defaultContent: '',
				parsedDefaultValueHtmlString: '',
				lastPrompt: '',
				showAIPrompt: false,
				isAISuggestionLoading: false,
				isAIAssistanceRequested: false,
				aiSuggestionAcceptedTransition: false,
				videoUploading: false,
				videoUploadingError: false,
				characterCount: 0,
				characterCountError: '',
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			scssVars() {
				return {
					'--tip-tap-accent-color': this.accentColor,
					'--tip-tap-editor-height': this.height ? `${this.height}px` : 'auto',
					'--tip-tap-editor-width': this.width ? `${this.width}px` : 'auto',
				}
			},
			sortedVariableItemsData() {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				return this.variableItemsData.sort((a, b) => (a.name > b.name ? 1 : -1))
			},
			linkWindowAttributes() {
				const { view, state } = this.editor
				const { from, to } = view.state.selection
				const text = state.doc.textBetween(from, to, '')

				return { text, ...this.editor.getAttributes('link') }
			},
		},
		watch: {
			value(newVal) {
				// Allow data to be modified by parent components when readonly.
				// Makes it easier for previews
				if (this.readOnly) this.editor.commands.setContent(newVal)
				if (newVal === '') this.editor.commands.setContent(newVal)
			},
			AISuggestion(newVal, oldVal) {
				if (newVal !== oldVal && newVal !== '') {
					this.isAISuggestionLoading = false
				}
			},
			aiError(newVal, oldVal) {
				if (newVal !== oldVal && newVal) {
					this.isAISuggestionLoading = false
				}
			},
		},
		created() {
			this.setParsedDefaultValueHtmlString()

			const extensions = [
				StarterKit,
				Placeholder.configure({
					placeholder: this.placeholder,
				}),
			]

			if (this.showImage) {
				extensions.push(
					ResizableImage.configure({
						inline: false,
						allowBase64: false,
						showResize: this.showResize,
						optimizeImage: this.optimizeImage,
					})
				)
			}

			if (this.showLink) {
				extensions.push(
					Link.configure({
						openOnClick: false,
						autolink: false,
					})
				)
			}

			if (this.variableItemsData) {
				extensions.push(VariableItem)
			}

			if (this.showVideoEmbed) {
				extensions.push(VideoEmbed)
			}

			if (this.enableYoutube) {
				extensions.push(
					Youtube.configure({
						inline: false,
						controls: false,
						nocookie: false,
						allowFullscreen: true,
						width: '100%',
						height: '100%',
					})
				)
			}

			this.editor = new Editor({
				editable: !this.readOnly,
				extensions,
				content: this.value || this.defaultContent,
				editorProps: {
					handleDOMEvents: {
						drop: (view, e) => {
							if (!this.enableDrop) {
								e.preventDefault()
								return
							}
							if (!this.showImage) return
							const coordinates = view.posAtCoords({ left: e.clientX, top: e.clientY })
							const currentPosition = coordinates.pos
							const files = e.dataTransfer.files
							if (files.length > 0) {
								files.forEach(file => {
									if (file.type.split('/')[0] === 'image') {
										file.metadata = { ...file.metadata, positionInEditor: currentPosition }
										this.$refs['editor-file-upload'].uploadFile(file)
										this.filesUploading += 1
									}
								})
							}
						},
					},
					handleDrop: (view, e, slice) => {
						let hasInvalidVariableItem = false

						if (slice && slice.content && this.variableItemsData) {
							slice.content.descendants(child => {
								if (child.type.name === VariableItem.name && !this.variableItemsData.find(item => item.name === child.attrs.name)) {
									hasInvalidVariableItem = true
								}
							})
						}

						if (hasInvalidVariableItem) {
							e.preventDefault()
							return true
						}

						return false
					},
					handlePaste: (view, e) => {
						const items = Array.from(e.clipboardData?.items || [])
						for (const item of items) {
							if (item.type.indexOf('image') === 0) {
								const file = item.getAsFile()
								this.$refs['editor-file-upload'].uploadFile(file)
								return true // handled
							}
						}
						return false
					},
				},
				onUpdate: () => {
					this.html = this.useOnlyPlainText ? this.editor.getText() : this.editor.getHTML()
					const json = this.editor.getJSON().content

					if (Array.isArray(json) && json.length === 1 && this.editor.isEmpty) {
						this.html = null // or any other default value
					}

					if (this.sendDefaultNull && this.parsedDefaultValueHtmlString === this.html) {
						this.html = null
					}

					this.$emit('input', this.html)

					this.updateCharacterCount()
				},
			})
		},
		mounted() {
			this.editor.commands.setContent(this.value)
			if (this.displayCharacterCount) {
				this.updateCharacterCount()
			}
		},

		methods: {
			closeAIPrompt() {
				this.showAIPrompt = false
			},
			toggleAIPrompt() {
				this.showAIPrompt = !this.showAIPrompt
			},
			addImage() {
				this.$refs['editor-file-upload'].onPickFile()
			},
			addVideo() {
				this.filesUploading += 1
				this.$refs['editor-file-upload-video'].onPickFile()
			},
			addLoomVideo() {
				this.$refs['insert-video-modal'].onLoad()
			},
			imageUploaded(payload) {
				this.filesUploading -= 1

				if (payload?.metadata?.positionInEditor) {
					this.editor
						.chain()
						.focus(payload.metadata.positionInEditor)
						.setImage({ src: payload.src })
						.run()
				} else {
					this.editor
						.chain()
						.focus()
						.setImage({ src: payload.src })
						.run()
				}
				this.tick += 1
			},
			videoUploaded(payload) {
				this.videoUploading = false
				this.videoUploadingError = false
				this.filesUploading -= 1
				const url = `https://${this.siteSettings.noHttpsDomain}embed?src=${payload.src}`
				this.editor
					.chain()
					.focus()
					.insertContent(
						`<iframe style="width:100%; height:100%; border:0; aspect-ratio:1.65;overflow:hidden;" scrolling="no" src="${url}"></iframe>`
					)
					.run()
				this.tick += 1
			},
			errorUploadingVideo() {
				this.videoUploadingError = true
			},
			startedVideoUploading() {
				this.videoUploading = true
				this.videoUploadingError = false
			},
			setParsedDefaultValueHtmlString() {
				if (this.defaultValue) {
					const htmlArray = this.defaultValue.split('\n')
					this.parsedDefaultValueHtmlString = htmlArray.map(line => line.trim()).join('')
				}
			},
			setLink() {
				this.$refs['link-window'].onLoad()
			},
			drop(e) {
				e.preventDefault()
			},
			handleGenerateWithAIClick() {
				this.toggleAIPrompt()
			},
			requestAIAssistance(prompt) {
				if (typeof prompt === 'string') {
					this.lastPrompt = prompt
				}

				this.showAIPrompt = false
				this.isAISuggestionLoading = true
				this.isAIAssistanceRequested = true
				this.aiSuggestionAcceptedTransition = false
				this.$emit('getAISuggestion', this.lastPrompt)

				setTimeout(() => {
					const aiBlock = document.getElementById('ai-suggestion-block')

					if (aiBlock) {
						aiBlock.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}
				}, 100)
			},
			acceptAISuggestion() {
				this.$emit('acceptAISuggestion')
				this.editor
					.chain()
					.focus()
					.setContent(this.AISuggestion, true)
					.run()

				this.isAIAssistanceRequested = false
				this.aiSuggestionAcceptedTransition = true

				setTimeout(() => {
					const editorContentEl = document.getElementById('editor-content')
					if (editorContentEl) {
						editorContentEl.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}
				}, 100)
			},
			cancelAIRequest() {
				this.isAISuggestionLoading = false
				this.isAIAssistanceRequested = false
			},
			openProductSelectionModal() {
				$('#product-selection-modal').modal('show')
			},
			confirmProductSelection(event) {
				this.editor
					.chain()
					.focus()
					.insertContent(`<p><a target="_blank" rel="noopener noreferrer nofollow" href="${event.url}">${event.label}</a></p><p>`)
					.run()
			},
			focusEditor() {
				this.editor.commands.focus()
			},
			updateCharacterCount() {
				const text = this.editor.getText()
				this.characterCount = text.length
			},
		},
	}
</script>
<style lang="scss" scoped>
	/* Basic editor styles */
	:focus {
		outline: none;
	}

	.editor-container {
		> :first-child {
			border-start-start-radius: 8px;
			border-start-end-radius: 8px;
			min-height: 44px !important;
		}

		> :last-child {
			border-end-start-radius: 8px;
			border-end-end-radius: 8px;
			min-height: 130px;
		}
	}

	.menu-bar {
		border-bottom: 1px solid #f3f6fd;
		background-color: var(--stan-primary-light-color);
		gap: 8px;
		padding-left: 12px;
		position: -webkit-sticky;
		position: sticky;
		top: calc(-1 * var(--padding-right-panel-top));
		z-index: 1;
	}

	.p-editor {
		padding: 15px;
		background-color: var(--stan-gray-light-color);
	}

	.menu-button {
		cursor: pointer;
		margin-bottom: 0;
		margin-right: 10px;
	}

	.tiptap-editor-ai-button.button-wrap.button-sm::v-deep {
		min-width: auto;
		padding: 0 8px;

		.button-content .button-icon {
			margin-right: 4px;
			transform: none;

			.icon-svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	@media screen and (max-width: 576px) {
		.tiptap-editor-ai-button.button-wrap.button-sm::v-deep {
			padding: 0px 3px;

			.button-content .button-icon {
				margin-right: 0px;
			}

			.ai-button-text {
				display: none;
			}
		}

		.tiptap-editor-ai-button.loading.button-wrap.button-sm::v-deep {
			padding: 0px 8px;
		}

		.menu-button {
			margin-right: 8px;
		}
	}

	.is-active {
		color: #3a36db;
	}

	.p-editor::v-deep {
		p {
			margin-bottom: 0;
			font-size: 0.875rem;
			line-height: 1.125rem;
		}
	}

	.personalize-link {
		-webkit-appearance: none;
	}

	.editor__content::v-deep {
		padding: 0;

		.ProseMirror-focused {
			outline-width: 0;
			outline: none;
		}

		.ProseMirror {
			margin-top: 4px; // a hack to leave some space above the text in tiptap editor

			> * + * {
				margin-top: 1.25em;
			}

			ul,
			ol {
				padding: 0 1rem;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				line-height: 1.1;
			}

			code {
				background-color: rgba(#616161, 0.1);
				color: #616161;
			}

			pre {
				background: #0d0d0d;
				color: var(--stan-white);
				font-family: 'JetBrainsMono', monospace;
				padding: 0.75rem 1rem;
				border-radius: 0.5rem;

				code {
					color: inherit;
					padding: 0;
					background: none;
					font-size: 0.8rem;
				}
			}

			img {
				max-width: 100%;
				height: auto;
			}

			blockquote {
				padding-left: 1rem;
				border-left: 2px solid rgba(#0d0d0d, 0.1);
			}

			hr {
				border: none;
				border-top: 2px solid rgba(#0d0d0d, 0.1);
				margin: 2rem 0;
			}

			ul,
			li {
				padding: revert;
			}

			::marker {
				color: var(--tip-tap-accent-color);
			}
		}

		/* Placeholder (on every new line) */
		.ProseMirror p.is-editor-empty:first-child::before {
			content: attr(data-placeholder);
			float: left;
			color: var(--stan-text-light-color) !important;
			pointer-events: none;
			height: 0;
		}

		.ProseMirror_readonly {
			.editor__content {
				width: 100px;
				.ProseMirror {
					overflow-y: unset !important;
					height: auto !important;

					::marker {
						color: inherit !important;
					}

					ul {
						li {
							p {
								text-align: left;
							}
						}
					}
				}
			}
		}

		.ProseMirror {
			overflow-y: auto;
			height: var(--tip-tap-editor-height);
			width: var(--tip-tap-editor-width);
		}

		.flexible {
			.ProseMirror {
				height: auto;
				min-height: var(--tip-tap-editor-height);
				min-width: var(--tip-tap-editor-width);
			}
		}

		.variable-item {
			display: inline-flex;
			padding: 3px;

			background-color: var(--stan-primary-light-color);
			border-radius: 5px;
			cursor: grab;
			user-select: all;

			.content {
				flex: 1 1 auto;
				line-height: 1;
			}
		}

		.ProseMirror ol,
		.ProseMirror ul {
			padding: 0 2rem !important;
		}

		[data-youtube-video] {
			max-width: 600px;
			width: 100%;
			aspect-ratio: 1.5;
		}
	}

	.restore-button {
		font-size: 0.875rem;
	}

	@keyframes float {
		from {
			opacity: 0;
			transform: translateY(-20px);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.ai-block {
		animation: float 0.3s ease;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 18px 20px;
		gap: 15px;
		background: #f9f9ff;
		border-radius: 10px;
		margin-top: 20px;

		&.error {
			background: #fff2f2;
		}

		&.h-0 {
			height: 0;
		}

		.loading {
			display: flex;
			width: 100%;
			align-items: center;

			.icon {
				align-items: center;
				display: flex;
			}

			.text {
				flex-grow: 1;
			}

			.cancel {
				color: #ff5050;
				cursor: pointer;
			}
		}

		.error {
			display: flex;
			align-items: center;
			gap: 20px;
			width: 100%;

			.text {
				flex-grow: 1;
			}

			.try-again {
				color: #ff5050;
				cursor: pointer;
			}
		}

		.suggestion {
			display: flex;
			align-items: flex-start;
			gap: 20px;

			.icon {
				svg {
					height: auto;
					width: 28px;
				}
			}

			.text {
				color: var(--stan-primary-dark-color);

				.buttons {
					display: flex;
					gap: 10px;
					margin-top: 25px;
				}
			}
		}
	}

	.disabled {
		opacity: 0.6;
		pointer-events: none;
	}

	.tiptap-editor-subject::v-deep {
		.p-editor {
			padding: 10px 15px 5px 15px;
		}
	}

	.bottom-options {
		display: flex;
		justify-content: space-between;
	}
	.character-count-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.character-count {
		display: block;
		text-align: right;
		margin-bottom: 0.5rem;
		color: var(--stan-text-light-color);
	}

	.character-count-error {
		color: var(--stan-negative-primary-color);
	}
</style>
