<template>
	<div class="form-group">
		<div class="d-flex align-items-center justify-content-start mb-0">
			<AppToggle size="sm" class="mr-2" v-model="value.enabled" :disabled="annualDiscountDisabled" />
			<label class="para-3 mb-0 d-inline-flex align-items-center">Add Annual Option </label>
			<AppTooltip
				class="ml-1 d-inline-flex align-items-center"
				click
				tip="Provide an ongoing monthly subscription with a annual option."
				:html="true"
			>
				<InlineSvg src="./images/income/help.svg" style="height: 12px" />
			</AppTooltip>
		</div>

		<div v-if="value.enabled" class="sub-options">
			<div class="mr-2 mt-1">
				<span class="para-3 mb-0 d-inline-flex align-items-center text-light"
					>By offering a lower-priced annual subscription, fans are incentivized to opt for the discount, leading to more reliable long-term
					income and increased retention throughout the subscription period.</span
				>
			</div>
			<div class="price-input-section mt-3">
				<div class="price-wrapper" :class="{ 'form-error': validation.amount.$error }">
					<AppInput
						:label="`${$t('Annual Price')}(${getCurrencySymbol(currency)}) *`"
						input-class="mb-1"
						placeholder=""
						v-model.number="value.amount"
						type="number"
						@blur="validation.$touch"
						hasPrecision
					/>
					<small v-if="price_validation_error_message" class="stan-text-danger error">{{ price_validation_error_message }}</small>
					<small v-else class="para-3 color-gray">{{ monthly_equivalent_message }}</small>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import StripeConnectMixin from '~/components/stripeConnectMixin.js'
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'AnnualSubscriptionDiscountOption',
		mixins: [StripeConnectMixin],
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			currency: {
				type: String,
				default: 'USD',
			},
			validation: {
				type: Object,
				default: () => {},
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			monthly_equivalent_message() {
				if (this.value.amount > 0) {
					const monthly = this.formatCurrency(this.value.amount / 12, this.currency, 'en-US', 2)
					return `Equivalent to ${monthly} Monthly.`
				}
				return null
			},
			price_validation_error_message() {
				const fiftyCents = this.formatCurrency(0.5, this.currency, 'en-US', 2)
				const twentyFiveThousand = this.formatCurrency(25000, this.currency, 'en-US', 0)
				if (this.value.amount < 0.5 || this.value.amount > 25000) {
					return `Must be between ${fiftyCents} and ${twentyFiveThousand}.`
				}
				return null
			},
			annualDiscountDisabled() {
				return this.disabled
			},
		},
		mounted() {},
		methods: {
			getCurrencySymbol: commonHelper.getCurrencySymbol,
			formatCurrency: commonHelper.formatCurrency,
			toggleDiscountAvailable() {
				if (!('sale_amount_available' in this.value)) {
					this.$set(this.value, 'sale_amount_available', false)
				}
				this.value.sale_amount_available = !this.value.sale_amount_available
				if (!this.value.sale_amount_available) {
					this.value.sale_amount = 0
				}
			},
			toggleDurationAvailable() {
				if (!('membership_duration_available' in this.value)) {
					this.$set(this.value, 'membership_duration_available', false)
				}
				this.value.membership_duration_available = !this.value.membership_duration_available
				if (!this.value.membership_duration_available) {
					this.value.membership_duration = 0
				} else if (this.value.membership_duration_available && !this.value.membership_duration) {
					this.value.membership_duration = 3
				}
			},
		},
		directives: {
			'two-dp': {
				bind: function twoDp(el) {
					el.addEventListener('keyup', e => {
						// evolved from https://stackoverflow.com/a/52685887/9614402
						const keyCode = e.keyCode ? e.keyCode : e.which
						const value = e.target.value
						const i = value.indexOf('.')

						if ((keyCode < 48 || keyCode > 57) && !(keyCode === 46 && i === -1)) {
							// 46 is dot
							// only allow numbers and one dot
							e.preventDefault()
						} else {
							const splits = e.target.value.split('.')
							if (splits.length > 1 && splits[1].length > 1) {
								e.target.value = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
								e.target.dispatchEvent(new Event('input'))
							}
						}
					})
					// modify the initial value if it's not 2dp
					const inputElement = el.nodeName === 'INPUT' ? el : el.querySelector('input')
					const stringValue = inputElement.value
					const splits = stringValue.split('.')
					if (splits.length > 1 && splits[1]?.length > 2) {
						const newValue = Number(`${splits[0]}.${splits[1].substr(0, 2)}`).toFixed(2)
						if (inputElement.value !== newValue) {
							inputElement.value = newValue
							inputElement.dispatchEvent(new Event('input'))
						}
					}
				},
			},
		},
		watch: {
			annualDiscountDisabled: {
				immediate: true,
				handler(newVal) {
					if (newVal) {
						this.value.enabled = false
					}
				},
			},
		},
	}
</script>
<style lang="scss" scoped>
	.sub-options {
		margin-left: 2rem !important;
	}
	.price-input-section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 18px;
		align-items: center;
		@media (max-width: 575px) {
			grid-template-columns: auto;
		}
		.error {
			opacity: 0;
			display: block;
			font-size: 70%;
			@media (max-width: 767px) {
				font-size: 10px;
			}
		}
		.form-error {
			.error {
				opacity: 1;
			}
		}
		.price-wrapper {
			display: flex;
			flex-direction: column;
		}
	}
</style>
