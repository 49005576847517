<template>
	<div>
		<AppModal id="change-plan-modal" :title="'Select Plan'" :width="710">
			<div>
				<template>
					<div class="d-flex flex-column gap-28px">
						<PlanIntervalSelector @toggleInterval="togglePlanInterval" :interval="newSubscriptionInterval" />
						<div class="main-container">
							<PlanDetailCard
								@onCardClick="handlePlanClick($constants.TIER.CREATOR)"
								:isCurrentPlan="isCurrentPlan($constants.TIER.CREATOR)"
								:tier="$constants.TIER.CREATOR"
								:selected="newSubscriptionTier === $constants.TIER.CREATOR"
								:interval="newSubscriptionInterval"
							/>

							<PlanDetailCard
								@onCardClick="handlePlanClick($constants.TIER.CREATOR_PRO)"
								:isCurrentPlan="isCurrentPlan($constants.TIER.CREATOR_PRO)"
								:tier="$constants.TIER.CREATOR_PRO"
								:selected="newSubscriptionTier === $constants.TIER.CREATOR_PRO"
								:interval="newSubscriptionInterval"
							/>
						</div>

						<AppButton
							class="w-100"
							:loading="!user || refreshingEstimate"
							:disabled="(!hasPlanBeenEdited && !subscriptionIsCancelled) || !user || refreshingEstimate"
							@click="triggerConfirmModal()"
							>{{ buttonText }}</AppButton
						>
					</div>
				</template>
			</div>
		</AppModal>
		<ChangePlanConfirmationModal
			:tier="newSubscriptionTier"
			:interval="newSubscriptionInterval"
			:isUpdatingPlan="isUpdatingPlan"
			:estimateSubscriptionChangeObject="estimateSubscriptionChangeObject"
			@on-confirm-button-click="handleConfirmaButtonClick"
		/>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import axios from 'axios'
	import { compareAsc } from 'date-fns'
	import PlanDetailCard from './PlanDetailCard/PlanDetailCard'
	import PlanIntervalSelector from './PlanIntervalSelector'
	import ChangePlanConfirmationModal from './ChangePlanConfirmationModal/ChangePlanConfirmationModal'

	export default {
		middleware: 'auth',
		components: {
			PlanDetailCard,
			PlanIntervalSelector,
			ChangePlanConfirmationModal,
		},
		data() {
			return {
				isUpdatingPlan: false,
				/**
				 * New subscription duration
				 * true - Annual, false - Monthly
				 */
				subscriptionDuration: false,
				estimateError: '',
				newSubscriptionTier: 'creator',
				newSubscriptionInterval: undefined,
				refreshingEstimate: false,
				estimateSubscriptionChangeObject: {
					cost: '',
					date: '',
					status: '',
				},
			}
		},
		async mounted() {
			this.scrollableContainer = this.$refs.scrollableContainer

			// If we already have user info in store no need to re-fetch on mount
			if (!this.user) {
				await this.fetchUser()
			}
			this.newSubscriptionTier = this.currentSubscriptionTier
			this.getSubscriptionData()
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			async close() {
				$('#change-plan-modal').modal('hide')
			},
			togglePlanInterval(interval) {
				this.$stanAnalytics('subscription-change-tier-modal-interval-toggle', {
					// User_id and username are optional as non login page should not have user object
					meta: { user_id: this.user?.user_id, username: this.user?.username },
					props: { interval },
				})

				if (this.newSubscriptionInterval !== interval) {
					this.newSubscriptionInterval = interval
				}
			},
			triggerConfirmModal() {
				$('#confirm-change-plan-modal').modal('show')
				this.close()
			},
			async getSubscriptionData() {
				// Set the initial plan interval
				if (this.user?.subscriptions?.platform && !this.newSubscriptionInterval) {
					this.newSubscriptionInterval = this.user.subscriptions.platform.data.price.interval
				}
			},
			handleConfirmaButtonClick() {
				this.submitSubscriptionChange()
			},
			renewSubscription() {
				this.isUpdatingPlan = true
				axios
					.post('v1/users/subscriptions/renew', { stripe_id: this.platformSubscriptionId })
					.then(() => {
						this.$stanNotify({ type: 'success', title: this.$t('Success!'), text: this.$t('Your subscription has been renewed.') })
						this.fetchUser()
						this.subscriptionStatusTick += 1
						this.isUpdatingPlan = false
						this.close()
						$('#confirm-change-plan-modal').modal('hide')
					})
					.catch(error => {
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: error?.response?.data?.message ?? this.$t('Please try again later.'),
						})
						this.isUpdatingPlan = false
						this.$logError(error)
					})
			},
			submitSubscriptionChange() {
				this.isUpdatingPlan = true
				axios
					.post('v1/users/subscriptions/change-tier', {
						stripe_id: this.platformSubscriptionId,
						price_id: this.newSubscriptionPriceId,
					})
					.then(() => {
						this.$stanNotify({ type: 'success', title: this.$t('Success!'), text: this.$t('We have updated your subscription!') })
						this.fetchUser()
						this.subscriptionStatusTick += 1
						this.isUpdatingPlan = false
						this.close()
						$('#confirm-change-plan-modal').modal('hide')
					})
					.catch(error => {
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: error?.response?.data?.message ?? this.$t('Please try again later.'),
						})
						this.isUpdatingPlan = false
						this.$logError(error)
					})
			},
			handlePlanClick(tier) {
				if (tier !== this.newSubscriptionTier) {
					this.newSubscriptionTier = tier
				}
			},
			isCurrentPlan(tier) {
				if (this.newSubscriptionTier === tier) {
					return this.user?.subscriptions?.platform?.data.price.interval === this.newSubscriptionInterval
				}

				return false
			},
			async estimateSubscriptionChange() {
				const platformSubscription = this?.user?.subscriptions?.platform
				if (
					platformSubscription &&
					(platformSubscription?.status > 0 ||
						(platformSubscription?.status === 0 && compareAsc(new Date(platformSubscription?.valid_until * 1000), new Date()) >= 0)) &&
					this.platformSubscriptionId
				) {
					this.refreshingEstimate = true
					try {
						const res = await axios.post('v1/users/subscriptions/estimate', {
							stripe_id: this.platformSubscriptionId,
							price_id: this.newSubscriptionPriceId,
						})

						this.estimateSubscriptionChangeObject = {
							cost: this.$commonHelper.formatCurrency(Math.abs(res.data.estimated_cost) / 100, this.platformCurrency),
							date: this.$commonHelper.formatDateShort(res.data.start_date * 1000, true, true),
							status: res.data.status,
						}

						this.refreshingEstimate = false
					} catch (error) {
						this.estimateError = true
						this.refreshingEstimate = true
						this.$logError(error)
					}
				}
				return {}
			},
			hidePlatformDiscountPercentExperiment() {
				return this.user?.experiments?.hide_platform_discount_percent
			},
		},
		computed: {
			...mapGetters('Auth', ['user', 'userCountryCode', 'hasActiveSubscription', 'getSubscribeFlags']),
			...mapGetters('PlatformSubscriptions', [
				'creatorMonthlyPriceId',
				'creatorAnnualPriceId',
				'creatorProMonthlyPriceId',
				'creatorProAnnualPriceId',
				'creatorMonthlyPrice',
				'creatorAnnualPrice',
				'creatorProMonthlyPrice',
				'creatorProAnnualPrice',
				'platformCurrency',
			]),
			platformSubscriptionId() {
				return this.user?.subscriptions?.platform?.stripe_id || ''
			},
			currentSubscriptionTier() {
				// "transient tier" --> "creator"
				// Check the real subscription tier
				return this.user?.subscriptions?.platform?.data.subscription_tier === this.$constants.TIER.CREATOR_PRO
					? this.$constants.TIER.CREATOR_PRO
					: this.$constants.TIER.CREATOR
			},
			currentSubscription() {
				if (this.user?.subscriptions?.platform) {
					const platformSubscription = this.user?.subscriptions?.platform
					return `${platformSubscription?.data.subscription_tier ?? this.$constants.TIER.CREATOR}:${
						platformSubscription?.data.price.interval
					}`
				}
				return ''
			},
			subscriptionChanged() {
				if (this.user?.subscriptions?.platform) {
					return this.newSubscriptionId !== this.currentSubscription
				}
				return false
			},
			subscriptionIsCancelled() {
				return this.user?.subscriptions?.platform?.status === 0
			},
			creatorCost() {
				return Math.round(this.creatorMonthlyPrice)
			},
			creatorDiscountCost() {
				if (this.subscriptionDuration) {
					return Math.round(this.creatorAnnualPrice / 12)
				}
				return NaN
			},
			creatorProCost() {
				return Math.round(this.creatorProMonthlyPrice)
			},
			creatorProDiscountCost() {
				if (this.subscriptionDuration) {
					return Math.round(this.creatorProAnnualPrice / 12)
				}
				return NaN
			},
			newSubscriptionId() {
				const dur = this.newSubscriptionInterval
				return `${this.newSubscriptionTier}:${dur}`
			},
			newSubscriptionPriceId() {
				switch (this.newSubscriptionId) {
					case 'creator-pro:year':
						return this.creatorProAnnualPriceId
					case 'creator-pro:month':
						return this.creatorProMonthlyPriceId
					case 'creator:year':
						return this.creatorAnnualPriceId
					case 'creator:month':
					default:
						return this.creatorMonthlyPriceId
				}
			},
			hasPlanBeenEdited() {
				return this.currentSubscription !== `${this.newSubscriptionTier}:${this.newSubscriptionInterval}`
			},
			buttonText() {
				if (!this.hasPlanBeenEdited) {
					if (this.subscriptionIsCancelled) {
						return this.$t('Renew plan')
					}
					return this.$t('Current Plan')
				}
				const platformSubscription = this.user.subscriptions.platform
				const currentTier = platformSubscription?.data.subscription_tier
				const currentInterval = platformSubscription?.data.price.interval
				let concattedText = ''

				// Determine upgrade or downgrade message based on tier changes
				if (currentTier !== this.newSubscriptionTier) {
					const intervalText = this.newSubscriptionInterval === this.$constants.TIER_INTERVAL.YEAR ? this.$t('Annual') : this.$t('Monthly')
					const upgradeDowngrade = this.newSubscriptionTier === this.$constants.TIER.CREATOR ? this.$t('Downgrade') : this.$t('Upgrade')

					if (currentInterval !== this.newSubscriptionInterval) {
						concattedText = this.$t(`${upgradeDowngrade} and switch to ${intervalText}`)
					} else {
						concattedText = this.$t(`${upgradeDowngrade} plan`)
					}
				} else if (this.newSubscriptionInterval === this.$constants.TIER_INTERVAL.MONTH) {
					concattedText = this.$t('Switch to Monthly')
				} else {
					concattedText = this.$t('Switch to Yearly')
				}

				// Append renewal message if subscription is cancelled
				if (this.subscriptionIsCancelled) {
					concattedText = `${this.$t('Renew + ')} ${concattedText}`
				}

				return concattedText
			},
		},
		watch: {
			newSubscriptionPriceId: {
				handler: 'estimateSubscriptionChange',
				immediate: true,
			},
		},
	}
</script>

<style lang="scss" scoped>
	.main-container {
		display: flex;
		position: relative;
		flex-direction: row;
		width: 100%;
		background-color: #ebebff;
		border-radius: 15px;

		@media (max-width: 991px) {
			flex-direction: column;
		}
	}
</style>
