var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "modal",
      attrs: {
        action: "Confirm",
        "secondary-action": "Cancel",
        title: "Add Audience",
        loading: _vm.isLoading,
        hideClose: "",
        fullscreenOnMobile: "",
        large: ""
      },
      on: { cta: _vm.submitModalForm, "secondary-cta": _vm.hide }
    },
    [
      _c("div", { staticClass: "audience-modal-container" }, [
        _c(
          "div",
          { staticClass: "audience-body" },
          [
            _c(
              "AppTableV3",
              {
                staticClass: "desktop audience-table",
                attrs: {
                  page: parseInt(_vm.currentPage),
                  totalPages: _vm.totalPages,
                  isLoading: _vm.isLoading,
                  isEmpty: !_vm.totalPages,
                  emptyHeader: _vm.computedEmptyHeader,
                  emptySubheader: _vm.computedEmptySubheader,
                  removeTableWrapperStyling: ""
                },
                on: { paginateHandle: _vm.paginateHandle },
                scopedSlots: _vm._u([
                  {
                    key: "subheader",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "mb-md-2 overflow-x" },
                          [
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-content-between align-items-top tag-editor"
                                },
                                [
                                  _c("EditFanTags", {
                                    ref: "editFanTags",
                                    staticClass: "w-100",
                                    attrs: {
                                      showTitle: false,
                                      mode: "include-tags",
                                      pseudoTags: _vm.productOptions,
                                      disabled: _vm.updatesDisabled,
                                      searchable: false
                                    },
                                    on: {
                                      "tags-updated": _vm.tagsUpdated,
                                      "fan-tag-list-loaded": _vm.populateTags
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center"
                                    },
                                    [
                                      _c("AppToggle", {
                                        attrs: {
                                          toggleId: "exclude-users-toggle",
                                          size: "sm",
                                          disabled: _vm.updatesDisabled
                                        },
                                        model: {
                                          value: _vm.excludeUsersToggle,
                                          callback: function($$v) {
                                            _vm.excludeUsersToggle = $$v
                                          },
                                          expression: "excludeUsersToggle"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "ml-2 para-3 mb-0",
                                          attrs: { for: "exclude-users-toggle" }
                                        },
                                        [_vm._v("Exclude Users")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.excludeUsersToggle
                                    ? _c(
                                        "div",
                                        [
                                          _c("EditFanTags", {
                                            ref: "editExcludedFanTags",
                                            staticClass: "w-100",
                                            attrs: {
                                              showTitle: false,
                                              mode: "exclude-tags",
                                              pseudoTags: _vm.productOptions,
                                              disabled: _vm.updatesDisabled,
                                              searchable: false
                                            },
                                            on: {
                                              "tags-updated":
                                                _vm.excludedTagsUpdated,
                                              "fan-tag-list-loaded":
                                                _vm.populateExcludedTags
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ],
                          2
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "selected-count" }, [
                          _vm._v(
                            _vm._s(_vm.recipientCount) + " recipients selected"
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _vm._v(" "),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "mobile-column" }, [_vm._v("Email")])
                ]),
                _vm._v(" "),
                _vm._l(_vm.paginatedCurrentFanList, function(customer) {
                  return _c("tr", { key: customer.fan_id }, [
                    _c("td", { staticClass: "text-truncate" }, [
                      _vm._v(_vm._s(customer.name))
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-truncate mobile-column" }, [
                      _vm._v(_vm._s(customer.email))
                    ])
                  ])
                })
              ],
              2
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }