<template>
	<div class="">
		<AppSection :number="root" title="Pick a style" v-if="showButtonLayout">
			<div class="button-layout-wrapper mb-3 flex-wrap justify-content-center justify-content-md-start">
				<div class="col-auto p-0">
					<input type="radio" id="button" value="button" v-model="value.layout" />
					<label class="para-2" for="button"> <InlineSvg src="/images/icons/ic-button.svg" />{{ $t('Button') }} </label>
				</div>
				<div class="col-auto p-0">
					<input type="radio" id="callout" value="callout" v-model="value.layout" />
					<label class="para-2" for="callout"> <InlineSvg src="/images/icons/ic-callout.svg" />{{ $t('Callout') }} </label>
				</div>
				<div class="col-auto p-0" v-if="showPreview">
					<input type="radio" id="proview" value="preview" v-model="value.layout" />
					<label class="para-2" for="proview"> <InlineSvg src="/images/icons/ic-video.svg" />{{ $t('Preview') }} </label>
				</div>
			</div>
		</AppSection>
		<AppSection :number="root + 1" title="Select image">
			<FileDropZone
				:image="value.image"
				:userflowKey="userflowKey"
				class="new"
				drop-zone-class="header-image-drop-zone"
				drop-zone-placeholder="Thumbnail"
				expected-dimension="400x400"
				@start-upload="payload => $emit('start-upload', payload)"
				@uploaded="payload => $emit('imageUploaded', payload)"
				@uploaded-all="payload => $emit('uploaded-all', payload)"
				@delete-file="payload => $emit('delete-file', payload)"
				@cancel-file="payload => $emit('cancel-file', payload)"
				data-test-id="upload-button-file-button"
				:crop-aspect-ratio="1"
				accept="image"
			/>
		</AppSection>
		<AppSection :number="root + 2" title="Add text">
			<div class="c" v-if="['callout', 'preview'].includes(value.layout)">
				<AppInput
					class="form-group mb-3 pb-2"
					label="Title"
					maxlength="50"
					:placeholder="$t('Input Heading Here')"
					v-model="value.heading"
					required=""
				/>
				<AppInput
					class="form-group mb-3 pb-2"
					:label="$t('Subtitle')"
					maxlength="100"
					:placeholder="$t('Enter a description for your followers here')"
					v-model="value.tagline"
				/>
			</div>
			<AppInput
				class="form-group mb-0"
				:class="{ 'form-error': validation.title.$error }"
				:label="buttonTitle"
				:key="value.layout"
				maxlength="30"
				:placeholder="buttonPlaceholder"
				v-model="value.title"
			/>
		</AppSection>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import FileDropZone from '~/components/uploads/FileDropZone'

	export default {
		name: 'PageButtonFormStepped',
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			showButtonLayout: {
				type: Boolean,
				default: true,
			},
			userflowKey: {
				type: String,
				default: '',
			},
		},
		data() {
			return {}
		},
		components: {
			FileDropZone,
		},
		computed: {
			...mapGetters('Auth', ['user']),
			root() {
				return this.showButtonLayout ? 1 : 0
			},
			showPreview() {
				return ['meeting', 'webinar', 'course', 'digital-download'].includes(this.$route.params.slug)
			},
			hasNamesOnProductsConsistent() {
				return this.user.experiments.names_on_products_consistent
			},
			buttonTitle() {
				return this.hasNamesOnProductsConsistent && !['callout', 'preview'].includes(this.value.layout) ? 'Title*' : 'Button*'
			},
			buttonPlaceholder() {
				return this.hasNamesOnProductsConsistent && !['callout', 'preview'].includes(this.value.layout)
					? 'Title Text Here...'
					: 'Button Text Here...'
			},
		},
		watch: {
			'value.layout': {
				handler(newVal, oldVal) {
					if (oldVal && this.hasNamesOnProductsConsistent) {
						if (newVal === 'button') {
							this.value.title = this.value.heading
						}

						if (oldVal === 'button' && newVal !== 'button') {
							this.value.heading = this.value.title
						}
					}
				},
				deep: true,
				immediate: true,
			},
			'value.title': {
				handler(newVal) {
					if (this.hasNamesOnProductsConsistent && this.value.layout === 'button') {
						this.value.heading = newVal
					}
				},
				deep: true,
			},
		},
	}
</script>

<style lang="scss" scoped>
	.button-layout-wrapper {
		display: flex;
		align-items: center;
		gap: 20px;
		input[type='radio'] {
			display: none;
		}
		label {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 0;
			position: relative;
			width: 90px;
			height: 90px;
			border: 1px solid var(--stan-gray-primary-color);
			border-radius: 5px;
			letter-spacing: -0.2px;
			&:hover {
				cursor: pointer;
			}
		}
		input:checked + label {
			background-color: var(--stan-gray-primary-color);
		}
	}
</style>
