<template>
	<div v-if="!isMobile" class="container">
		<div class="body flex-grow-1 col-12 col-md-5 px-0">
			<div>
				<div class="modal-body w-full py-0 d-flex flex-column align-items-center">
					<h3 class="modal-heading">Get 50% off for the next 2 months️</h3>
					<div class="modal-subheading">
						Don’t miss out on the tools that help you win.🙋‍
					</div>
				</div>
				<div class="d-flex flex-column  align-items-center  gap v-gap pt-1">
					<AppButton name="accept-one-free-month" size="large" @click="acceptOffer">Get 50% off</AppButton>
					<AppButton name="decline-one-free-month" size="large" class="p-1 h-auto" link @click="nextStep">I’m good</AppButton>
				</div>
			</div>
		</div>

		<div class="body flex-grow-1 col-12 col-md-5 px-0">
			<div v-if="!imageReady" class="image-placeholder" />
			<img v-show="imageReady" src="/images/cancellation/50-discount.jpg" @load="imageReady = true" />
		</div>
	</div>
	<div v-else class="container mobile-container d-flex justify-content-center align-items-center">
		<div class="body flex-column flex-gap-50 h-100">
			<div class="body flex-shrink-1 px-0">
				<div v-if="!imageReady" class="image-placeholder" />
				<img v-show="imageReady" src="/images/cancellation/50-discount.jpg" @load="imageReady = true" />
			</div>
			<div class="body px-0">
				<div>
					<div class="modal-body w-full py-0 d-flex flex-column align-items-center">
						<h3 class="modal-heading">Get 50% off for the next 2 months️</h3>
						<div class="modal-subheading">
							Don’t miss out on the tools that help you win.🙋‍
						</div>
					</div>
					<div class="d-flex flex-column  align-items-center  gap v-gap pt-1">
						<AppButton name="accept-one-free-month" size="large" @click="acceptOffer">Get 50% off</AppButton>
						<AppButton name="decline-one-free-month" size="large" class="p-1 h-auto" link @click="nextStep">I’m good</AppButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {},
		data() {
			return {
				stepName: 'offer two months discount',
				offerName: '50% off - 2 Months',
				imageReady: false,
			}
		},
		computed: {
			isMobile() {
				return this.$commonHelper.isMobile()
			},
		},
		mounted() {
			this.start()
		},
		methods: {
			start() {
				this.$emit('start', {
					eventName: 'cancellation-present-offer-viewed',
					props: {
						step_name: this.stepName,
						offer_name: this.offerName,
					},
				})
			},
			acceptOffer() {
				this.$emit('two-month-discount-accepted', {
					eventName: 'cancellation-present-offer-accepted',
					props: {
						step_name: this.stepName,
						offer_name: this.offerName,
					},
				})
				this.close()
			},
			nextStep() {
				this.$emit('next-step', {
					eventName: 'cancellation-present-offer-ignored',
					props: {
						step_name: this.stepName,
						offer_name: this.offerName,
					},
				})
				this.close()
			},
			close() {
				this.reset()
			},
			reset() {
				this.imageReady = false
			},
		},
	}
</script>

<style lang="scss" scoped></style>
