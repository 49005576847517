var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-start mb-0" },
      [
        _c("AppToggle", {
          staticClass: "mr-2",
          attrs: { size: "sm", disabled: _vm.annualDiscountDisabled },
          model: {
            value: _vm.value.enabled,
            callback: function($$v) {
              _vm.$set(_vm.value, "enabled", $$v)
            },
            expression: "value.enabled"
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "para-3 mb-0 d-inline-flex align-items-center" },
          [_vm._v("Add Annual Option ")]
        ),
        _vm._v(" "),
        _c(
          "AppTooltip",
          {
            staticClass: "ml-1 d-inline-flex align-items-center",
            attrs: {
              click: "",
              tip:
                "Provide an ongoing monthly subscription with a annual option.",
              html: true
            }
          },
          [
            _c("InlineSvg", {
              staticStyle: { height: "12px" },
              attrs: { src: "./images/income/help.svg" }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.value.enabled
      ? _c("div", { staticClass: "sub-options" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "price-input-section mt-3" }, [
            _c(
              "div",
              {
                staticClass: "price-wrapper",
                class: { "form-error": _vm.validation.amount.$error }
              },
              [
                _c("AppInput", {
                  attrs: {
                    label: `${_vm.$t("Annual Price")}(${_vm.getCurrencySymbol(
                      _vm.currency
                    )}) *`,
                    "input-class": "mb-1",
                    placeholder: "",
                    type: "number",
                    hasPrecision: ""
                  },
                  on: { blur: _vm.validation.$touch },
                  model: {
                    value: _vm.value.amount,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "amount", _vm._n($$v))
                    },
                    expression: "value.amount"
                  }
                }),
                _vm._v(" "),
                _vm.price_validation_error_message
                  ? _c("small", { staticClass: "stan-text-danger error" }, [
                      _vm._v(_vm._s(_vm.price_validation_error_message))
                    ])
                  : _c("small", { staticClass: "para-3 color-gray" }, [
                      _vm._v(_vm._s(_vm.monthly_equivalent_message))
                    ])
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mr-2 mt-1" }, [
      _c(
        "span",
        {
          staticClass: "para-3 mb-0 d-inline-flex align-items-center text-light"
        },
        [
          _vm._v(
            "By offering a lower-priced annual subscription, fans are incentivized to opt for the discount, leading to more reliable long-term\n\t\t\t\tincome and increased retention throughout the subscription period."
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }