<template>
	<Transition v-if="showPreview" name="fade-transform-phone" appear mode="in-out">
		<div class="stan-device preview-type-page" ref="device-wrapper">
			<div class="site-preview-section mx-auto funnel-page-preview store-theme-page" :class="rootClasses">
				<div v-if="!isCheckout" class="page-container relative">
					<div v-if="!isFirst" class="page-highlight-topbar py-3" :style="userBackground">
						<TipTapViewer v-if="pageData.top_banner_text" v-model="pageData.top_banner_text" class="ProseMirror_readonly" read-only />
						<span v-else>
							<h3>
								{{ $t('WAIT!') }}
							</h3>
							<p>
								{{ $t('Your Order Is Not Yet Complete!') }}<br />
								<span v-if="product.price.sale_amount">{{ $t('Here’s a ONE TIME DISCOUNT!') }}</span>
							</p>
						</span>
					</div>
					<Banner
						class="mb-4 pages-block"
						:title="product.title"
						:image="bannerImage"
						:background-color="userPrimaryColor"
						:show-back="showBackButton"
					/>
					<div
						v-if="isStockManaged"
						key="stock_quantity"
						class="product-stock px-5-mod"
						:class="{ 'low-stock': isLowStock, 'out-of-stock': !isInStock }"
					>
						{{ quantity_text }}
					</div>
					<Price
						v-if="isInStock"
						class="px-5-mod mb-3 pages-block"
						:currency="user.data.currency"
						:amount="product.price.amount || 0"
						:sale-amount="product.price.sale_amount_available ? product.price.sale_amount : 0"
						:interval="product.price.interval"
						:accent-color="userSecondaryColor"
					/>

					<TipTapViewer
						v-if="product.description"
						v-model="product.description"
						class="mb-4 px-5-mod description ProseMirror_readonly pages-block"
						read-only
						:accent-color="userSecondaryColor"
					/>
					<div v-if="isOrderBumpAllowed && secondBuiltInProduct" key="one-time" class="pages-block px-5-mod relative text-center mx-3 mb-3">
						<div class="upsell">
							<strong class="stan-text-danger text-decoration-underline text-bold d-block">ONE TIME OFFER</strong>
							<small class="d-block mb-1">
								<span>
									{{ secondBuiltInProduct.data.product.title }} for just ${{
										secondBuiltInProduct.data.product.price.sale_amount_available
											? secondBuiltInProduct.data.product.price.sale_amount
											: secondBuiltInProduct.data.product.price.amount
									}}
									<span v-if="secondBuiltInProduct.data.product.price.sale_amount_available"
										>(<span style="text-decoration: line-through">${{ secondBuiltInProduct.data.product.price.amount }}</span>
										Value)
									</span>
								</span>
							</small>
							<div class="button" :style="{ background: userPrimaryColor }">
								<i class="v-icon blink" style="color: red; background-color: transparent">
									<svg width="24" height="24" viewBox="0 0 24 24">
										<path d="M4 15V9h8V4.16L19.84 12L12 19.84V15H4z">
											<animate
												attributeType="XML"
												attributeName="fill"
												values="#800;#f00;#800;#800"
												dur="0.8s"
												repeatCount="indefinite"
											/>
										</path>
									</svg>
								</i>
								<input id="upsell_check" v-model="add_upsell_to_order" :disabled="!secondBuiltInProduct" type="checkbox" />
								<label for="upsell_check"> Yes, I'd like this One-Time-Offer! </label>
							</div>
						</div>
					</div>
					<UserReviews v-if="product.reviews && product.reviews.length" class="pages-block" :reviews="product.reviews" isPreview />
					<div v-if="pageData.bottom_title" class="pages-block payments px-5-mod text-center mb-3">
						<h4>
							<span :style="userFontColor">{{ pageData.bottom_title }}</span>
						</h4>
					</div>
					<div :key="updateTick">
						<CountdownTimer key="countdown" v-if="isCountdownTimerEnabled" :countdownTimer="countdownTimer" />
					</div>

					<Calendar
						v-if="type == 'meeting'"
						class="orient-horizontal mb-5 px-5-mod pages-block"
						:primary-color="userPrimaryColor"
						:secondary-color="userSecondaryColor"
						:slug="product.meeting.slug"
						:is-demo="true"
						:username="user.username || ''"
						:availability="product.meeting.availability"
					/>
					<WebinarCalendar
						v-if="type == 'webinar'"
						class="orient-horizontal mb-5 px-1 pages-block"
						:primary-color="userPrimaryColor"
						:secondary-color="userSecondaryColor"
						:slug="product.webinar.slug"
						:is-demo="true"
						:username="user.username || ''"
						:preview-demo-sessions="previewWebinarSessions"
					/>
					<Form class="pages-block" :questions="questions" :fcp-complete="true" :disabled="true" />
					<Transition name="fade-transform" appear mode="out-in">
						<div class="pages-block">
							<div v-if="grossAmount && product.price.discount_codes_available && isInStock" class="discount-section-wrapper mb-4">
								<h3 class="mb-3 section-title">{{ $t('Discount Code') }}</h3>
								<div class="discount-code-wrapper input-box">
									<input v-model="discount_code" :placeholder="$t('Enter promo code here')" size="20" />
									<button>{{ $t('Apply') }}</button>
								</div>
							</div>
							<div v-if="grossAmount > 0">
								<div class="px-5-mod mb-4 relative">
									<div class="amount-wrapper">
										<span class="d-flex  justify-content-between align-items-center divide-bar w-100">
											<span class="total pr-2">{{ $t('Total :') }}</span>
											<div v-if="!isInStock" class="total-am pl-2" :style="{ color: userSecondaryColor }">
												{{ $t('Sold Out') }}
											</div>
											<div v-else-if="grossAmount > 0" class="total-am pl-2" :style="{ color: userSecondaryColor }">
												{{ formatCurrency(grossAmount, user.data.currency, 'en-US', true) }}{{ intervalText }}
											</div>
											<div v-else class="total-am pl-2 " :style="{ color: userSecondaryColor }">
												{{ $t('FREE') }}
											</div>
										</span>
									</div>
								</div>
							</div>
							<div v-if="showPaypal || showAfterpay" class="payment-gateway-section-wrapper mb-4">
								<h3 class="mb-3 section-title">{{ $t('Payment Method') }}</h3>
								<div v-if="showPaypal" id="payment-gateway-button-container" class="payment-gateway-button-container">
									<div class="d-flex justify-content-center cursor-pointer payment-gateway-button align-items-center">
										<img src="/images/paypal-logo-white.svg" class="img-fluid paypal-icon" />
									</div>
								</div>
								<div v-if="showAfterpay" id="payment-gateway-button-container" class="payment-gateway-button-container">
									<div class="d-flex justify-content-center cursor-pointer payment-gateway-button">
										<img src="/images/afterpay.svg" class="payment-method-img img-fluid" />
									</div>
								</div>
								<div class="separator">or</div>
							</div>

							<div class="px-4 submit-section">
								<button
									class="confirm-button mb-4"
									:style="userBackground"
									type="submit"
									:disabled="formSubmitting || !isInStock"
									@click="triggerSubmit"
								>
									<span class="text-center abos-loader">
										<HollowDotsSpinner
											v-if="formSubmitting"
											:animation-duration="1000"
											:dot-size="12"
											class="d-flex align-items-center"
											:dots-num="3"
											color="var(--stan-white)"
										/>

										<span v-if="!formSubmitting" class="white-clr">{{ pageData.submit_title || $t('PURCHASE') }}</span>
									</span>
								</button>
							</div>
						</div>
					</Transition>
					<div v-if="hasPrevious || hasNext" class="site__groupButton d-flex justify-content-between py-5">
						<a class="site__prev">
							<span v-if="hasPrevious" @click="prevPage()">{{ $t('Prev') }}</span></a
						>
						<a class="site__next">
							<span v-if="hasNext" @click="nextPage()">{{ $t('Next') }}</span></a
						>
					</div>
				</div>
				<div v-else>
					<Transition name="fade-transform" mode="out-in">
						<div>
							<Summary
								class="body-wrapper relative"
								:banner-image="funnelData.checkout_image || '/images/device/placeholder.svg'"
								:currency="user.data.currency"
								:user-primary-color="userPrimaryColor"
								:products="purchasedProducts"
								:heading="funnelData.checkout_heading"
								:title="funnelData.checkout_title"
								:button="funnelData.checkout_button_text"
							/>
						</div>
					</Transition>
				</div>
			</div>
		</div>
	</Transition>
</template>
<script>
	import { mapGetters } from 'vuex'
	import { format, parseISO } from 'date-fns'
	import { debounce as _debounce } from 'lodash'
	import Banner from '~/stan-vue-shared/components/Banner'
	import Price from '~/stan-vue-shared/components/Price'
	import TipTapViewer from '~/stan-vue-shared/components/TipTapViewer'
	import UserReviews from '~/stan-vue-shared/components/UserReviews'
	import Calendar from '~/stan-vue-shared/components/Calendar'
	import WebinarCalendar from '~/stan-vue-shared/components/WebinarCalendar'
	import Form from '~/stan-vue-shared/components/Form'
	import Summary from '~/stan-vue-shared/components/Summary'
	import CountdownTimer from '~/stan-vue-shared/components/CountdownTimer'
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'StorePagePreview',
		components: {
			TipTapViewer,
			Banner,
			Price,
			UserReviews,
			Calendar,
			WebinarCalendar,
			Form,
			Summary,
			CountdownTimer,
		},
		props: {
			user: { type: Object, default: () => {} },
			dynamic_class: { type: String, default: '' },
			pageData: { type: Object, default: () => {} },
			type: { type: String, default: '' },
			funnelData: { type: Object, default: () => {} },
			isCheckout: Boolean,
			isFirst: Boolean,
			purchasedProducts: {
				type: Array,
				default: () => [],
			},
			showBackButton: Boolean,
			hasPrevious: Boolean,
			hasNext: Boolean,
			store: { type: Object, default: () => {} },
			stripeElementIdSuffix: { type: String, default: '' }, // when there are multiple previews, make sure stripe fields are populated in separate elements.
		},
		data() {
			return {
				formSubmitting: false,
				// Payment Details
				creditCardInputTick: 0,
				stripe: null,
				showPreview: true,
				discount_code: '',
				add_upsell_to_order: false,
				themePrimaryColor: '',
				themeTone1Color: '',
				updateTick: 0,
			}
		},
		mounted() {
			// eslint-disable-next-line no-undef
			this.stripe = Stripe(this.siteSettings.stripePlatformKey)
			this.onWindowResize()
			window.addEventListener('resize', this.onWindowResize)
		},
		unmounted() {
			window.removeEventListener('resize', this.onWindowResize)
		},
		computed: {
			...mapGetters('Stores', ['getStoreTheme']),
			...mapGetters('SiteSettingsStore', ['getIntegrations']),
			showPaypal() {
				const enabled = this.getIntegrations.paypal?.status
				const eligible =
					this.pageData.product.type !== 'membership' && !this.pageData.second_built_in_product?.enabled && this.store.type !== 'funnel'
				return enabled && eligible && this.grossAmount > 0
			},
			showAfterpay() {
				const enabled = this.pageData.product?.payment_methods?.afterpay_clearpay
				const eligible =
					this.pageData.product.type !== 'membership' && !this.pageData.second_built_in_product?.enabled && this.store.type !== 'funnel'
				return enabled && eligible && this.grossAmount > 0
			},
			userPrimaryColor() {
				return (this.funnelData && this.funnelData.primary_color) || this.user.data.color_code || 'var(--stan-blue-4)'
			},
			userSecondaryColor() {
				return (
					(this.pageData && this.pageData.secondary_color) || (this.funnelData && this.funnelData.secondary_color) || this.userPrimaryColor
				)
			},
			isOrderBumpAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('order-bump') !== -1
			},
			userFontColor() {
				return { color: this.userPrimaryColor }
			},
			secondaryFontColor() {
				return { color: this.userSecondaryColor }
			},
			userBackground() {
				return { background: this.userPrimaryColor }
			},
			secondaryBackground() {
				return { background: this.userSecondaryColor }
			},
			product() {
				return this.pageData.product
			},
			isStockManaged() {
				return !!this.product?.inventory?.manage
			},
			stockQuantity() {
				return this.isStockManaged ? this.product.inventory.stock : undefined
			},
			isLowStock() {
				return this.stockQuantity > 0 && this.stockQuantity < 3
			},
			isInStock() {
				return this.stockQuantity === undefined || this.stockQuantity > 0
			},
			grossAmount() {
				let amount =
					this.product.price.sale_amount_available && this.product.price.sale_amount > 0
						? this.product.price.sale_amount
						: this.product.price.amount

				if (this.add_upsell_to_order && this.secondBuiltInProduct) {
					amount +=
						this.secondBuiltInProduct.data.product.price.sale_amount_available &&
						this.secondBuiltInProduct.data.product.price.sale_amount > 0
							? this.secondBuiltInProduct.data.product.price.sale_amount
							: this.secondBuiltInProduct.data.product.price.amount
				}
				return amount
			},
			questions() {
				return this.product.questions || []
			},
			isPaid() {
				let price = 0
				if (this.product.price) price = this.product.price.sale_amount ? this.product.price.sale_amount : this.product.price.amount
				return price > 0
			},
			bannerImage() {
				const defaults = {
					meeting: 'https://assets.stanwith.me/graphics/device/meeting.png',
					membership: 'https://assets.stanwith.me/graphics/device/membership.png',
					fulfillment: 'https://assets.stanwith.me/graphics/device/fulfillment.png',
					'digital-download': 'https://assets.stanwith.me/graphics/device/digital-download.png',
					'lead-magnet': 'https://assets.stanwith.me/graphics/device/lead-magnet.png',
					community: 'https://assets.stanwith.me/graphics/device/community.png',
				}
				return this.product.image ? this.product.image : defaults[this.type]
			},
			storeTheme() {
				return this.getStoreTheme || 'default'
			},
			rootClasses() {
				return `${this.dynamic_class} ${this.storeTheme}`
			},
			quantity_text() {
				if (this.isInStock) {
					let itemString = 'item'
					if (this.stockQuantity > 1) itemString = this.$t('items')
					return `${this.stockQuantity} ${itemString} ${this.$t('left')}`
				}
				return this.$t('Sold Out')
			},
			secondBuiltInProduct() {
				if (this.pageData?.second_built_in_product?.enabled && this.store) {
					const pages = this.store.pages.filter(p => p.page_id === this.pageData.second_built_in_product.page_id)
					if (pages.length > 0) {
						const page = pages[0]
						if (!page.data.product.price.interval) {
							return page
						}
					}
				}
				return null
			},
			countdownTimer() {
				return this.pageData?.countdown_timer
			},
			isCountdownTimerEnabled() {
				return this.countdownTimer?.enabled
			},
			previewWebinarSessions() {
				if (this.type === 'webinar') {
					const sessions = []

					this.product.webinar.sessions.forEach(s => {
						const session = {}
						const date = new Date(s.date)

						if (date && date > new Date(new Date().toISOString().split('T')[0])) {
							const startEnd = this.calculateEndTime(s.time, this.product.webinar.duration_minutes)
							session.date = format(parseISO(s.date), 'EEEE MMM dd, yyyy')
							session.stime = startEnd.startTime
							session.end_time = startEnd.endTime
							session.available_seats = this.product.webinar.attendees_limit - s.booked_seats
							sessions.push(session)
						}
					})

					return sessions
				}
				return []
			},
			intervalText() {
				if (this.product.price.interval) {
					return `/${this.product.price.interval.charAt(0).toUpperCase() + this.product.price.interval.slice(1)}`
				}
				return ''
			},
		},
		methods: {
			formatCurrency: commonHelper.formatCurrencyPreview,
			// eslint-disable-next-line prefer-arrow-callback
			onWindowResize: _debounce(function _() {
				const documentStyles = document.documentElement.style
				const previewWidth = $('.stan-device.preview-type-page').width() ?? 475
				documentStyles.setProperty('--preview-page-zoom', previewWidth / 475)
			}, 500),
			triggerSubmit() {
				this.formSubmitting = true
				setTimeout(() => {
					this.formSubmitting = false
				}, 3000)
			},
			async prevPage() {
				$('.site-preview-section').animate({ scrollTop: 0 }, 0)
				this.$emit('prevPage')
				this.showPreview = false
				await setTimeout(() => {
					this.showPreview = true
				}, 200)
			},
			async nextPage() {
				$('.site-preview-section').animate({ scrollTop: 0 }, 0)
				this.$emit('nextPage')
				this.showPreview = false
				await setTimeout(() => {
					this.showPreview = true
				}, 200)
			},
			calculateEndTime(decimal, duration) {
				// Configure Start
				const startHours = Math.floor(decimal)
				const startMinutes = Math.round((decimal - startHours) * 60)
				const formattedStartMinutes = String(startMinutes).padStart(2, '0')
				const displayStartHours = startHours % 12 || 12
				const startTimeString = `${displayStartHours}:${formattedStartMinutes}`

				// Configure End
				const endTimeDecimal = decimal + duration / 60
				let endHours = Math.floor(endTimeDecimal)
				const endMinutes = Math.round((endTimeDecimal - endHours) * 60)
				endHours %= 24
				const formattedEndMinutes = String(endMinutes).padStart(2, '0')
				const endPeriod = endHours >= 12 ? 'PM' : 'AM'
				const displayEndHours = endHours % 12 || 12
				const endTimeString = `${displayEndHours}:${formattedEndMinutes} ${endPeriod}`

				return { startTime: startTimeString, endTime: endTimeString }
			},
		},
		watch: {
			getStoreTheme(newVal) {
				if ((newVal === 'minima' || newVal === 'material') && this.userPrimaryColor) {
					const themeColors = this.$commonHelper.addThemeColorsToDocument(newVal, this.userPrimaryColor, this.userSecondaryColor)
					this.themePrimaryColor = themeColors.primary
					this.themeTone1Color = themeColors.tone2

					if (themeColors.stripePrimary) {
						this.themePrimaryColor = themeColors.stripePrimary
					}
					if (themeColors.stripeTone1) {
						this.themeTone1Color = themeColors.stripeTone1
					}
				}
				this.creditCardInputTick += 1
			},
			pageData: {
				deep: true,
				handler() {
					this.updateTick += 1
				},
			},
		},
	}
</script>
<style lang="scss">
	button,
	input,
	select,
	textarea {
		background-color: transparent;
		border-style: none;
	}
	.review-description {
		overflow: hidden !important;
	}
	.site-preview-section {
		.back-button {
			display: none !important;
		}
	}
</style>
<style lang="scss" scoped>
	@import 'src/stan-vue-shared/assets/styles/themes/store-page.scss';

	.stan-device {
		@media (max-width: 991px) {
			&.mobile-preview {
				.site-preview-section {
					padding-bottom: 40px;
				}
			}
		}
	}
	.credit-card-wrapper {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		.credit-title {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: var(--stan-black);
			i {
				font-size: 18px;
			}
		}
		.form-border-radius {
			border-radius: 0px !important;
		}
	}
	.product-price {
		font-family: 'Grobek', sans-serif !important;
		font-size: 28px;
		font-weight: 500;
	}
	.material {
		.discount-section-wrapper {
			padding: 0;
		}
		.payment-gateway-section-wrapper {
			padding: 0px !important;
		}
	}
	.discount-section-wrapper {
		padding: 0 20px;
		.section-title {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: #000000;
		}
		.discount-code-wrapper {
			display: flex;
			width: 100%;
			justify-content: space-between;
			&.input-box {
				max-width: 100%;
				max-height: 100%;
				box-sizing: border-box;
				border: 1px solid rgba(41, 42, 51, 0.2);
				position: relative;
				padding: 13px 16px;
				font-size: 14px;
				background-color: #f3f6fd;
			}
			.discount-successful {
				font-weight: bold;
			}
			button,
			input,
			select,
			textarea {
				font: inherit;
				background-color: transparent;
				border-style: none;
				overflow: visible;
			}
			button {
				font-weight: bold;
			}
		}
	}

	.payment-gateway-section-wrapper {
		padding: 0 20px;
	}

	.site-preview-section {
		&.onboarding-prev {
			width: 330px;
		}
	}

	.white_page_wrapper {
		font-family: 'Inter';
		padding-top: 0;
		padding-bottom: 0;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		height: calc(100vh - 20px);
		@supports (height: 100dvh) {
			max-height: calc(100dvh - 20px);
		}
		overflow-y: auto;
	}

	.page-highlight-topbar::v-deep {
		color: var(--stan-white);
		text-align: center;
		h3 {
			color: white;
		}
	}

	.banner,
	.product-price {
		font-family: 'Grobek';
	}

	.reviews {
		color: #262328;
	}

	.payments {
		font-family: 'Grobek' !important;
		color: var(--stan-black);
	}
	.confirm-button {
		display: flex;
		position: relative;
		flex-flow: row nowrap;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		z-index: 1;
		width: 20px;
		float: left;
		line-height: 22px;
		min-height: 50px;
		padding: 0 35px;
	}

	// .pay-form-wrapper::v-deep {
	// 	#payment-form {
	// 		background-color: #f3f6fd;
	// 	}
	// }

	// .pay-now {
	// 	color: var(--stan-white);
	// 	background-color: #52c18f;
	// 	border-radius: 100px;
	// 	width: 100%;
	// 	font-weight: 600;
	// 	border-radius: 0;
	// 	float: none;
	// 	font-size: 20px;
	// 	padding: 22px;
	// 	span {
	// 		text-align: center;
	// 		font-family: 'Inter';
	// 	}
	// }

	.amount-wrapper {
		position: relative;
		font-family: 'Grobek';
		color: var(--stan-black);
		z-index: 0;

		.total-am,
		.total {
			z-index: 1;
		}
	}

	.total-am {
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		line-height: 130%;
	}

	.hollow-dots-spinner {
		height: 24px !important;
	}

	.btn-skip {
		color: var(--stan-blue-4);
		border: none;
		display: block;
		margin: auto;
	}

	.fade-transform-leave-active,
	.fade-transform-enter-active,
	.fade-transform-phone-enter-active {
		transition: all 0.5s;
	}
	.fade-transform-phone-leave-active {
		transform: none;
	}

	.fade-transform-enter,
	.fade-transform-phone-enter {
		opacity: 0;
		transform: translateX(-30px);
	}

	.fade-transform-leave-to,
	.fade-transform-phone-leave-to {
		opacity: 0;
		transform: translateX(30px);
	}
	.site__next {
		float: right;
	}
	.site__next,
	.site__prev {
		cursor: pointer;
		color: var(--stan-primary-dark-color);
	}
	.site__groupButton {
		padding: 0 15px;
	}
	.fan-details-form::v-deep {
		// width: 100%
		padding-left: 20px;
		padding-right: 20px;
		.form-field {
			margin-bottom: 15px;
			margin-top: 16px;
			.layout-rect {
				// background-color: var(--stan-boulder-5)
				background-color: transparent;
				.input-bar {
					display: block;
					flex: 1;
					width: 100%;
					max-width: 100%;
					max-height: 100%;
					outline: none;
					box-sizing: border-box;
					border: 1px solid rgba(41, 42, 51, 0.09);
					position: relative;
					padding: 13px 16px;
					font-size: 14px;
					background-color: #f3f6fd;
				}
			}
			.input-bar {
				display: block;
				flex: 1;
				width: 100%;
				max-width: 100%;
				max-height: 100%;
				border: 0;
				outline: none;
				background: transparent;
				box-sizing: border-box;
				border: 1px solid rgba(41, 42, 51, 0.2);
				position: relative;
				padding: 13px 16px;
				font-size: 14px;
				background-color: #f3f6fd;
				&:disabled,
				&.disabled {
					cursor: not-allowed;
				}
			}
			input {
				border-radius: 0;
				overflow: visible;
			}
			p {
				color: var(--stan-danger) !important;
				padding-left: 0;
				font-size: 12px;
				margin-bottom: 0 !important;
				margin: 5px 0 0;
			}
		}
	}
	.fan-details-form::v-deep .form-field .layout-rect .input-bar.disabled {
		background-color: #f3f6fd;
	}
	.upsell {
		border: 2px black dashed;
		padding: 20px;
		margin: 0;
		display: block;
		.text-decoration-underline {
			text-decoration: underline !important;
		}
		.d-block {
			display: block !important;
		}
		.mb-1 {
			margin-bottom: 4px !important;
		}
		.button {
			text-align: center;
			background: pink;
			color: white;
			font-weight: bold;
			padding: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-gap: 5px;
			gap: 5px;
		}
		label {
			margin: 0;
		}
		.v-icon {
			align-items: center;
			display: inline-flex;
			font-feature-settings: 'liga';
			font-size: 24px;
			justify-content: center;
			letter-spacing: normal;
			line-height: 1;
			position: relative;
			text-indent: 0;
			transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
			vertical-align: middle;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		@keyframes blink-svg {
			100%,
			0% {
				fill: #500;
			}
			60% {
				fill: #f00;
			}
		}
		.blink {
			svg path {
				-webkit-animation: blink-svg 0.8s infinite;
				animation: blink-svg 0.8s infinite;
			}
		}
	}

	.payment-gateway-button-container {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.payment-gateway-button {
		height: 45px;
		background: #2c2e2f;
		border-radius: 4px;
		padding: 7px;
	}
	.payment-method-img {
		object-fit: cover;
		width: 100%;
		max-width: 130px;
		max-height: 45px;
	}
	.paypal-icon {
		height: 16px;
	}

	.px-5-mod {
		padding-right: 20px !important;
		padding-left: 20px !important;
	}
	.minima::v-deep {
		.product-price {
			margin-top: 0px !important;
		}
		.banner .banner-image .video-image-preview {
			border-radius: 24px !important;
		}
	}
</style>
