var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column cursor-pointer w-100 plan-detail-card position-relative",
      class: {
        selected: _vm.isPlanSelected
      },
      on: { click: _vm.handleCardClick }
    },
    [
      _vm.isPlanSelected
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center position-absolute align-self-center selected-pill"
            },
            [
              _c("span", { staticClass: "text-white para-3 text-bold" }, [
                _vm._v(_vm._s(_vm.$t("Selected")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column gap-5px" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row w-100 justify-content-between align-items-center position-relative"
          },
          [
            _c("span", { staticClass: "d-flex flex-row gap-4px" }, [
              _c("span", { staticClass: "text-dark plan-title" }, [
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(_vm.$t("Creator")) + "\n\t\t\t\t"
                )
              ]),
              _vm._v(" "),
              _vm.tier === _vm.$constants.TIER.CREATOR_PRO
                ? _c(
                    "span",
                    { staticClass: "text-primary-color plan-title playfair" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.$t("Pro")) + "\n\t\t\t\t"
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.isCurrentPlan ||
            (!_vm.isCurrentPlan &&
              _vm.interval === _vm.$constants.TIER_INTERVAL.YEAR)
              ? _c("div", { staticClass: "plan-info-pill" }, [
                  _vm.isCurrentPlan
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "para-3 text-primary-color text-bold d-block pilly",
                          class: {
                            "is-selected-plan-pill": _vm.isPlanSelected
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Current Plan")))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isLoading &&
                  !_vm.isCurrentPlan &&
                  _vm.interval === _vm.$constants.TIER_INTERVAL.YEAR
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "para-3 text-primary-color text-bold d-block pilly",
                          class: {
                            "is-selected-plan-pill": _vm.isPlanSelected
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Save")) +
                              " " +
                              _vm._s(
                                _vm.formatCurrency(
                                  _vm.basePrice.monthlyAnnualized -
                                    _vm.basePrice.yearly
                                )
                              ) +
                              "/yr"
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-row" },
          [
            _vm.isLoading
              ? _c("HollowDotsSpinner", {
                  staticClass: "h-100 m-1 d-flex align-items-center mx-auto",
                  attrs: {
                    "animation-duration": 1000,
                    "dot-size": 14,
                    "dots-num": 3,
                    color: "var(--stan-primary-soft-color)"
                  }
                })
              : [
                  _vm.interval === _vm.$constants.TIER_INTERVAL.MONTH
                    ? _c("span", { staticClass: "d-flex flex-row" }, [
                        _c(
                          "span",
                          { staticClass: "para-1 text-primary-color" },
                          [
                            _c("span", { staticClass: "text-bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.formatCurrency(_vm.basePrice.monthly)
                                )
                              )
                            ]),
                            _vm._v("/mo")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.interval === _vm.$constants.TIER_INTERVAL.YEAR
                    ? _c("span", { staticClass: "d-flex flex-row gap-8px" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "para-1 text-decoration-line-through plan-line-through-cost"
                          },
                          [
                            _c("span", { staticClass: "text-bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.formatCurrency(
                                    _vm.basePrice.monthlyAnnualized
                                  )
                                )
                              )
                            ]),
                            _vm._v("/yr")
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "para-1 text-primary-color" },
                          [
                            _c("span", { staticClass: "text-bold" }, [
                              _vm._v(
                                _vm._s(_vm.formatCurrency(_vm.basePrice.yearly))
                              )
                            ]),
                            _vm._v("/yr\n\t\t\t\t\t")
                          ]
                        )
                      ])
                    : _vm._e()
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column gap-15px" }, [
        _vm.tier === _vm.$constants.TIER.CREATOR_PRO
          ? _c(
              "span",
              {
                staticClass: "para-2 text-bold everything-in-creator-text-div"
              },
              [
                _c("span", { staticClass: "text-italic" }, [
                  _vm._v(_vm._s(_vm.$t("Everything")))
                ]),
                _vm._v(" " + _vm._s(_vm.$t("in Creator, plus...")))
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex flex-column gap-10px",
            class: {
              "everything-in-creator-pill-div":
                _vm.tier === _vm.$constants.TIER.CREATOR_PRO
            }
          },
          [
            _c("PlanFeaturePill", {
              attrs: {
                icon: "📱",
                header: _vm.$t("Mobile Optimized Link-in-Bio Store"),
                description: _vm.$t("Replaces Linktree, Squarespace")
              }
            }),
            _vm._v(" "),
            _c("PlanFeaturePill", {
              attrs: {
                icon: "🗓️",
                header: _vm.$t("Calendar Bookings"),
                description: _vm.$t("Replaces Calendly")
              }
            }),
            _vm._v(" "),
            _c("PlanFeaturePill", {
              attrs: {
                icon: "🎓",
                header: _vm.$t("Course Builder"),
                description: _vm.$t("Replaces Kajabi, Teachable")
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.tier === _vm.$constants.TIER.CREATOR_PRO
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column feature-list-container pro-feature-list-container gap-10px"
              },
              [
                _c("PlanFeaturePill", {
                  attrs: {
                    icon: "✉️",
                    header: _vm.$t("Unlimited Email Marketing"),
                    description: _vm.$t("Save $100s vs. Mailchimp"),
                    isPro: true
                  }
                }),
                _vm._v(" "),
                _c("PlanFeaturePill", {
                  attrs: {
                    icon: "📊",
                    header: _vm.$t("Order Bumps"),
                    description: _vm.$t("Increase your sales by 40%+"),
                    isPro: true
                  }
                }),
                _vm._v(" "),
                _c("PlanFeaturePill", {
                  attrs: {
                    icon: "💸",
                    header: _vm.$t("Payment Plans"),
                    description: _vm.$t(
                      "Increase your conversion rates by 20%+"
                    ),
                    isPro: true
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }