<template>
	<AppModal id="paypal-manage-modal" title="Manage Your PayPal" @cta="goToPaypal" action="Go to PayPal">
		<div class="paypal-subheading para-2">
			Your connected PayPal account email is: <strong>{{ email }}</strong>
		</div>
	</AppModal>
</template>
<script>
	export default {
		props: { email: String },
		methods: {
			goToPaypal() {
				this.$emit('goToPaypal')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.paypal-subheading {
		width: 85%;
		text-align: center;
		margin: auto;
		margin-bottom: 10px;
		color: var(--stan-text-light-color);
	}
</style>
