var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "div",
        { staticClass: "wrapper", class: `route-${_vm.route_name}` },
        [
          _c("LeftMenu", { ref: "leftMenu" }),
          _vm._v(" "),
          _c("div", { staticClass: "rightPanel" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [_c("NavigationTop", { ref: "form" })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "content",
                staticClass: "content d-flex flex-column",
                attrs: { id: "main-container" }
              },
              [
                _vm.showTopBannerBox
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-column pb-4" },
                      [
                        _vm.user.status === 0
                          ? _c("AccountDisableHeader")
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.hasActiveSubscription
                          ? _c("WarningHeader", [
                              _vm._v(
                                "\n\t\t\t\t\tYour store isn't live yet. No worries! You can renew your subscription "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { target: "_self", href: "/subscribe" }
                                },
                                [_vm._v("here")]
                              ),
                              _vm._v(".\n\t\t\t\t")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showStripeSetupBanner
                          ? _c("WarningHeader", [
                              _vm._v(
                                "\n\t\t\t\t\tHeads up, customers can't purchase from you yet! Please\n\t\t\t\t\t"
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_self",
                                    href: "/account_settings/?tab=store"
                                  }
                                },
                                [_vm._v("set up your Direct Deposit")]
                              ),
                              _vm._v("\n\t\t\t\t\tto start selling\n\t\t\t\t")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("RouterView")
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("AppLottie", {
            attrs: { name: "store_loading", cacheAnimation: "" }
          }),
          _vm._v(" "),
          _c("AppLottie", {
            attrs: { name: "phone_width_sparkles", cacheAnimation: "" }
          }),
          _vm._v(" "),
          _c("ProductFeedbackModal"),
          _vm._v(" "),
          _c("PlayVideoModal"),
          _vm._v(" "),
          _c("ChangePlanModal"),
          _vm._v(" "),
          _c("StartTrialModal"),
          _vm._v(" "),
          _c("EnterStoreFullScreenModal", {
            ref: "enterStoreFullScreen",
            attrs: { skipFadeIn: "" }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "h-100 w-100" },
        [
          _c("HollowDotsSpinner", {
            staticClass: "h-100 d-flex align-items-center mx-auto",
            attrs: {
              "animation-duration": 1000,
              "dot-size": 18,
              "dots-num": 3,
              color: "#3a36db"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }