<template>
	<div class="cancellation-modal-overlay">
		<div class="d-flex flex-wrap justify-content-center h-100">
			<div class="cancellation-modal">
				<div class="close close-modal" data-dismiss="modal" aria-label="Close" @click="flowAbort()">
					<InlineSvg class="close-icon" src="/images/icons/ic-close.svg" />
				</div>
				<Transition name="slide" mode="out-in">
					<OfferTwoMonthDiscount
						v-if="activeDiscountOffer == twoMonthDiscountOffer && step == discountOfferStep && !discountOfferAccepted"
						:cancelPredefinedReason="cancelPredefinedReason"
						:cancelReasonExtraDetail="cancelReasonExtraDetail"
						:cancelFeedback="cancelFeedback"
						@start="payload => this.stepStart(payload)"
						@next-step="nextStep"
						@two-month-discount-accepted="payload => this.twoMonthDiscountAccepted(payload)"
					/>
					<TwoMonthDiscountOfferAccepted
						v-if="activeDiscountOffer == twoMonthDiscountOffer && step == discountOfferStep && discountOfferAccepted"
						@start="payload => this.stepStart(payload)"
						@next-step="flowEnd"
					/>
					<OfferOneFreeMonth
						v-if="activeDiscountOffer == oneMonthFreeOffer && step == discountOfferStep && !discountOfferAccepted"
						:cancelPredefinedReason="cancelPredefinedReason"
						:cancelReasonExtraDetail="cancelReasonExtraDetail"
						:cancelFeedback="cancelFeedback"
						@start="payload => this.stepStart(payload)"
						@next-step="nextStep"
						@free-one-month-offer-accepted="payload => this.freeOneMonthOfferAccepted(payload)"
					/>
					<OneFreeMonthOfferAccepted
						v-if="activeDiscountOffer == oneMonthFreeOffer && step == discountOfferStep && discountOfferAccepted"
						@start="payload => this.stepStart(payload)"
						@next-step="flowEnd"
					/>
					<CancellationSurvey
						v-if="step == 2"
						@start="payload => this.stepStart(payload)"
						@next-step="payload => this.cancelConfirmation(payload)"
					/>
					<CancelSubscriptionConfirmation v-if="step == 3" @start="payload => this.stepStart(payload)" @next-step="nextStep" />
					<CancellationFlowSurvey
						v-if="step == 4"
						@start="payload => this.stepStart(payload)"
						@log-analytics="payload => log(build_step_props(payload))"
						@next-step="flowEnd"
					/>
				</Transition>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { mapGetters } from 'vuex'
	import OfferTwoMonthDiscount from '~/components/modals/cancellation/step/OfferTwoMonthDiscount'
	import TwoMonthDiscountOfferAccepted from '~/components/modals/cancellation/step/TwoMonthDiscountOfferAccepted'
	import OfferOneFreeMonth from '~/components/modals/cancellation/step/OfferOneFreeMonth'
	import CancelSubscriptionConfirmation from '~/components/modals/cancellation/step/CancelSubscriptionConfirmation'
	import CancellationFlowSurvey from '~/components/modals/cancellation/step/CancellationFlowSurvey'
	import CancellationSurvey from '~/components/modals/cancellation/step/SubscriptionCancellationFeedback'
	import OneFreeMonthOfferAccepted from '~/components/modals/cancellation/step/OneFreeMonthOfferAccepted'

	export default {
		props: {
			platformSubscriptionId: { type: String },
		},
		components: {
			OfferTwoMonthDiscount,
			TwoMonthDiscountOfferAccepted,
			OfferOneFreeMonth,
			OneFreeMonthOfferAccepted,
			CancellationSurvey,
			CancelSubscriptionConfirmation,
			CancellationFlowSurvey,
		},
		name: 'CancelPlatformSubscriptionModal',
		data() {
			return {
				step: 1,
				discountOfferStep: 1,
				discountOfferAccepted: false,
				oneMonthFreeOffer: 'one_month_free',
				twoMonthDiscountOffer: 'two_month_discount',
				cancelPredefinedReason: '',
				cancelReasonExtraDetail: '',
				cancelFeedback: '',
				cancellationFlowComplete: false,
				error: false,
				// TODO: APP-4909 - Bring back one free month offer as an A/B experiment
				disableOneFreeMonthOffer: true,
			}
		},
		computed: {
			...mapGetters('Auth', ['ajax_error', 'user']),
			qualifiesForDiscount() {
				return this.user.subscriptions?.platform?.data?.price.interval === 'month' && this.user.subscriptions?.platform?.status === 1
			},
			activeDiscountOffer() {
				// return this.user.experiments?.cancellation_two_month_discount_offer ? this.twoMonthDiscountOffer : this.oneMonthFreeOffer
				return this.oneMonthFreeOffer
			},
		},
		created() {
			// Check and skip any steps on creation to avoid any jittery loading
			this.checkSkipDiscountOfferStep()
		},
		mounted() {
			this.flowStart()
		},
		methods: {
			flowStart() {
				this.log(
					this.build_flow_props({
						eventName: 'cancellation-flow-start',
					})
				)
			},
			flowEnd() {
				this.log(
					this.build_step_props({
						eventName: 'cancellation-flow-end',
					})
				)
				this.reset()
			},
			flowAbort() {
				if (this.discountOfferAccepted) {
					this.flowEnd()
					return
				}
				this.log(
					this.build_step_props({
						eventName: 'cancellation-flow-abort',
					})
				)
				this.reset()
			},
			stepStart(payload) {
				this.log(this.build_step_props(payload))
			},
			stepAbort(payload) {
				payload.eventName = 'cancellation-abort'
				this.log(this.build_step_props(payload))
				this.flowAbort(payload)
				// eslint-disable-next-line no-underscore-dangle
				if (window._ctrack) {
					// eslint-disable-next-line no-undef
					_ctrack.track('cancel_subscription_click')
				}
			},
			checkSkipDiscountOfferStep() {
				// Skip first step if free month has been used
				if (this.step === this.discountOfferStep && this.qualifiesForDiscount && !!this.user.tags.platform_cancellation_free_month_applied) {
					this.nextStep({
						eventName: 'cancellation-present-offer-already-redeemed',
						props: {
							stepName: 'offer one free month',
							offerName: 'one free month',
						},
					})
				} else if (
					this.step === this.discountOfferStep &&
					this.qualifiesForDiscount &&
					!!this.user.tags.platform_cancellation_two_month_discount_applied
				) {
					this.nextStep({
						eventName: 'cancellation-present-offer-already-redeemed',
						props: {
							stepName: 'offer two months discount',
							offerName: '50% off - 2 Months',
						},
					})
				} else if (this.step === this.discountOfferStep && !this.qualifiesForDiscount) {
					this.nextStep()
				}
			},
			nextStep(payload) {
				if (payload) {
					this.log(this.build_step_props(payload))
				}
				this.step++

				this.checkSkipDiscountOfferStep()
			},
			reset() {
				this.$emit('close')
				this.$nextTick(() => {
					setTimeout(() => {
						this.discountOfferAccepted = false
						this.step = 1
						this.cancelPredefinedReason = ''
						this.cancelReasonExtraDetail = ''
						this.cancelFeedback = ''
						this.cancellationFlowComplete = false
						this.error = false
					}, 200)
				})
			},
			log(payload) {
				if (payload) {
					const eventName = payload.eventName
					const props = payload.props
					const data = {
						meta: { user_id: this.user.user_id, username: this.user.username },
						props,
					}
					if (eventName) {
						this.$stanAnalytics(eventName, data)
					}
				}
			},
			build_step_props(payload) {
				return { eventName: payload?.eventName, props: { step: this.step, ...payload?.props } }
			},
			build_flow_props(payload) {
				return { eventName: payload?.eventName, props: { ...payload?.props } }
			},
			freeOneMonthOfferAccepted(payload) {
				this.discountOfferAccepted = true
				this.$emit('free-one-month-offer-accepted', payload)
			},
			twoMonthDiscountAccepted(payload) {
				this.discountOfferAccepted = true
				this.$emit('two-month-discount-accepted', payload)
			},
			cancelConfirmation(payload) {
				this.invalidatePlatformSubscription()
				this.log(this.build_step_props(payload))
				this.nextStep()
			},
			invalidatePlatformSubscription() {
				axios
					.post('/v1/users/subscriptions/invalidate', {
						stripe_id: this.platformSubscriptionId,
					})
					.then(() => {
						this.$emit('subscription-invalidated')
					})
					.catch(error => {
						this.error = true
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: error?.response?.data?.message ?? this.$t('Please try again later.'),
						})
						this.$logError(error)
					})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.container::v-deep {
		width: 100%;
		justify-content: space-around;
		display: flex;
		flex-wrap: wrap;
		gap: 30px;

		&.mobile-container {
			height: 100%;
		}

		.body {
			justify-content: center;
			display: flex;
			align-items: center;

			@media (min-width: 768px) {
				max-width: 50% !important;
			}

			.modal-heading {
				color: var(--stan-text-dark-color);
				text-align: center;
				font-family: Plus Jakarta Sans;
				font-size: 44px;
				font-style: normal;
				font-weight: 800;
				line-height: 110%; /* 48.4px */
				letter-spacing: -0.44px;
				margin-bottom: 40px;

				@media (max-width: 768px) {
					font-size: 28px;
					font-style: normal;
					font-weight: 800;
					line-height: 110%; /* 30.8px */
					letter-spacing: -0.28px;
				}
			}

			.modal-subheading {
				color: var(--stan-text-light-color);
				text-align: center;
				font-size: 18px;
				font-style: normal;
				font-weight: 300;
				line-height: 130%;
				letter-spacing: -0.2px;
				margin-bottom: 60px;

				@media (max-width: 768px) {
					font-size: 16px;
					font-style: normal;
					font-weight: 300;
					line-height: 130%;
					letter-spacing: -0.2px;
				}
			}

			.app-button {
				width: min(395px, 100%);
			}
		}
	}

	/* Style the overlay */
	.cancellation-modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: white; /* add transparency to the overlay */
		z-index: 999; /* make sure the overlay is on top of everything else */
	}

	.cancellation-modal {
		background-color: white;
		padding: 20px;
		height: 100%;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
	}

	.close-modal {
		position: absolute;
		right: 15px;
		top: 15px;
		display: flex;
		width: 28px;
		height: 28px;
		background-color: var(--stan-gray-light-color) !important;
		border-radius: 50%;
		cursor: pointer;
		z-index: 9;
		color: var(--stan-primary-primary-color);
		opacity: 1;
		&:hover {
			background-color: var(--stan-gray-soft-color) !important;
		}

		.close-icon {
			margin: auto;
		}
	}
</style>
