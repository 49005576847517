<template>
	<AppModal
		ref="modal"
		id="stanleyAiHaveDigitalDownload"
		title="Describe Your Digital Product"
		subheading="Tell us what you want to create—be it a guide, eBook, or series. Include details like topic, tone, and structure."
		action="Generate"
		@cta="genetate"
	>
		<div class="mt-4">
			<textarea
				class="form-control styled-input"
				rows="5"
				name="content"
				v-model="content"
				:placeholder="$t('(e.g., a step-by-step TikTok guide or a business guide for Etsy).')"
			/>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				content: '',
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
		},
		methods: {
			show() {
				this.$stanAnalytics('ai-product-description-modal-seen', { meta: { user_id: this.user.user_id, username: this.user.username } })
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')
			},
			genetate() {
				this.$stanAnalytics('ai-product-generated-cta', { meta: { user_id: this.user.user_id, username: this.user.username } })

				this.hide()
				this.$emit('generate', [this.content])
			},
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.option-box {
		border-radius: 12px;
		border: solid var(--stan-gray-light-color) 2px;
		display: flex;
		flex-direction: column;
		padding: 10px;
		justify-content: start;
		align-items: start;
		width: 200px;
	}
</style>
