/* eslint-disable no-sequences */
/* eslint-disable block-scoped-var */
/* eslint-disable prefer-template */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable no-undef */
/* eslint-disable func-names */
// eslint-disable-next-line import/named
import { configSettings } from '~/plugins/config-settings.js'
import CONSTANTS from '~/global_helper/constants.js'

/**
 * MINI OVERVIEW
 * https://www.loom.com/share/dd7f58210c614674955134c4ba3d3c41
 */

function InitializeTrackers() {
	// Facebook Pixel
	!(function(f, b, e, v, n, t, s) {
		if (f.fbq) return
		n = f.fbq = function() {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
		}
		if (!f._fbq) f._fbq = n
		n.push = n
		n.loaded = !0
		n.version = '2.0'
		n.queue = []
		t = b.createElement(e)
		t.async = !0
		t.src = v
		s = b.getElementsByTagName(e)[0]
		s.parentNode.insertBefore(t, s)
	})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

	fbq('init', '4296631060464751') // Jay Hoovy (2286147324795074)

	// Tiktok Pixel
	!(function(w, d, t) {
		w.TiktokAnalyticsObject = t
		var ttq = (w[t] = w[t] || [])
		;(ttq.methods = [
			'page',
			'track',
			'identify',
			'instances',
			'debug',
			'on',
			'off',
			'once',
			'ready',
			'alias',
			'group',
			'enableCookie',
			'disableCookie',
		]),
			(ttq.setAndDefer = function(t, e) {
				t[e] = function() {
					t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
				}
			})
		for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i])
		;(ttq.instance = function(t) {
			for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n])
			return e
		}),
			(ttq.load = function(e, n) {
				var i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
				;(ttq._i = ttq._i || {}),
					(ttq._i[e] = []),
					(ttq._i[e]._u = i),
					(ttq._t = ttq._t || {}),
					(ttq._t[e] = +new Date()),
					(ttq._o = ttq._o || {}),
					(ttq._o[e] = n || {})
				var o = document.createElement('script')
				;(o.type = 'text/javascript'), (o.async = !0), (o.src = i + '?sdkid=' + e + '&lib=' + t)
				var a = document.getElementsByTagName('script')[0]
				a.parentNode.insertBefore(o, a)
			})

		ttq.load('CVCARNJC77U2KF3E4G70') // Account: ads.management@stanwith.me
		ttq.page()
	})(window, document, 'ttq')

	// Linkedin Pixel
	!(function(w) {
		;(function() {
			var s = document.getElementsByTagName('body')[0]
			var b = document.createElement('script')
			b.type = 'text/javascript'
			b.async = true
			b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
			s.parentNode.insertBefore(b, s)
		})()

		// Example usage:
		w._linkedin_data_partner_ids = []
		w._linkedin_data_partner_ids.push('6951194') // Account ID: 514276835
	})(window)

	sessionStorage.setItem('initializedPixels', '1')
}

function FbTrack(event, data = {}, event_id_data = {}) {
	for (let i = 0; i < 10; i++) {
		let pixelsInitialized = false
		try {
			pixelsInitialized = sessionStorage.getItem('initializedPixels') === '1'
		} catch (e) {
			console.log(e)
		}
		if (pixelsInitialized) {
			fbq('track', event, data, event_id_data)
			return true
		}
		InitializeTrackers()
	}
	return false
}
function TTTrack(event, data = {}, event_id_data = {}) {
	for (let i = 0; i < 10; i++) {
		let pixelsInitialized = false
		try {
			pixelsInitialized = sessionStorage.getItem('initializedPixels') === '1'
		} catch (e) {
			console.log(e)
		}
		if (pixelsInitialized) {
			ttq.track(event, data, event_id_data)
			return true
		}
		InitializeTrackers()
	}
	return false
}
function LiTrack(event, eventId = null) {
	for (let i = 0; i < 10; i++) {
		const LI_EVENTS = {
			[CONSTANTS.ADS_ANALYTIC_EVENTS.LOGIN]: 20317682,
			[CONSTANTS.ADS_ANALYTIC_EVENTS.ADD_TO_CART]: 20317690,
			[CONSTANTS.ADS_ANALYTIC_EVENTS.INITIATE_CHECKOUT]: 20317698,
			[CONSTANTS.ADS_ANALYTIC_EVENTS.COMPLETE_REGISTRATION]: 20317706,
			[CONSTANTS.ADS_ANALYTIC_EVENTS.START_TRIAL]: 20317714,
			[CONSTANTS.ADS_ANALYTIC_EVENTS.ADD_PAYMENT_INFO]: 20317770,
			[CONSTANTS.ADS_ANALYTIC_EVENTS.OTHER]: 20317722,
		}

		let pixelsInitialized = false
		try {
			pixelsInitialized = sessionStorage.getItem('initializedPixels') === '1'
		} catch (e) {
			console.log(e)
		}
		if (pixelsInitialized) {
			try {
				const payload = {
					conversion_id: LI_EVENTS[CONSTANTS.ADS_ANALYTIC_EVENTS.OTHER],
				}
				if (event in LI_EVENTS) {
					payload.conversion_id = LI_EVENTS[event]
				}
				if (eventId) {
					payload.event_id = eventId
				}

				window.lintrk('track', payload)

				return true
			} catch (e) {
				console.log(e)
			}
			// In-case linktrk did not load in (delayed or blocked by ads)
			InitializeTrackers()
		}
	}
	return false
}

/**
 * Sends events Data VIA API & Then via frontend analytics. With dedupe id
 */
function AdsTrackFrontEndAndAPi(event, data = {}, serverData = {}, self) {
	let serverPayload = { event_name: event, url: window.location.href }

	const fbc = self.$cookies.get('_fbc')
	const fbp = self.$cookies.get('_fbp')
	const ttclid = self.$cookies.get('ttclid')
	if (fbc || fbp) {
		serverPayload = { ...serverPayload, ...{ fbc, fbp } }
	}
	if (ttclid) {
		serverPayload = { ...serverPayload, ...{ ttclid } }
	}

	if (Object.keys(data).length !== 0) {
		serverPayload.custom_data = data
	}

	serverPayload = { ...serverPayload, ...serverData }

	self.$axios
		.post('v1/analytics/track-capi-event', serverPayload)
		.then(response => {
			const eventId = response?.data?.event_id
			FbTrack(event, data, { eventID: eventId })
			TTTrack(event, data, { eventID: eventId })
			LiTrack(event, eventId)
		})
		.catch(() => {
			FbTrack(event, data)
			TTTrack(event, data)
			LiTrack(event)
		})
}

export default function AdsTracking(task, event = '', data = {}, serverData = {}) {
	if (configSettings.env_name === 'production') {
		if (task === 'Initialize') {
			InitializeTrackers()
		}
		if (task === 'track') {
			AdsTrackFrontEndAndAPi(event, data, serverData, this)
		}
	}
}
