<template>
	<div>
		<!-- desktop table -->
		<div class="d-none d-md-block" :class="{ 'table-wrapper': !removeTableWrapperStyling }">
			<div class="table-wrapper-header">
				<div class="stan-icon-wrapper">
					<InlineSvg :src="icon" class="stan-icon mr-2" />
					<h3 class="text-bold">{{ title }}</h3>
				</div>
			</div>

			<AppFilters
				class="d-flex flex-wrap align-items-center justify-content-between"
				:class="this.$slots['options'] ? 'dropdownEnabled' : ''"
				:filters="filters"
				:showCsvDownload="showCsvDownload"
				@filterChanged="filterChanged"
				@downloadCsv="$emit('downloadCsv')"
				@resetFilters="resetFilters"
				v-if="filters.length > 0"
			/>
			<div class="table-container" :class="{ loading: isLoading }">
				<table style="table-layout: fixed; width: 100%" class="para-2">
					<tr>
						<th
							class="text-truncate align-self-center"
							v-for="(col, i) in tableColumns"
							:key="`col-${i}`"
							:style="{
								width: col.width,
							}"
							:class="{ 'text-right': col.headerRightAlign }"
						>
							{{ col.label }}
						</th>
						<th class="controls" v-if="showActionCell" :class="{ 'has-action-button': hasActionButton }"></th>
					</tr>
					<tr v-for="(row, j) in items" :key="`row-${j}`" @click.self="moreDetails(row)" :class="{ pointer: rowClickable }">
						<td v-for="(col, k) in tableColumns" :key="`data-${j}-${k}`" @click="moreDetails(row)">
							<div class="d-flex" v-if="!col.html" :class="col.classObject">
								<div v-if="col.tags && col.tags.length > 0" class="d-flex">
									<AppTag
										v-for="(tag, n) in col.tags"
										:key="`tag-desktop-${n}`"
										:type="tag.type"
										:field="tag.field"
										:value="getNestedValue(row, tag.field)"
										:label="getNestedValue(row, tag.label)"
										:toolTip="getNestedValue(row, tag.toolTip)"
										:backgroundColor="tag.backgroundColor"
										:textColor="tag.textColor"
									></AppTag>
								</div>
								<div v-if="getNestedValue(row, col.progress) >= 0" class="progress-tracker">
									<div class="progress-tracker-total" style="width: 100%"></div>
									<div class="progress-tracker-complete" :style="`width: ${getNestedValue(row, col.progress)}%`"></div>
								</div>
								<div
									v-if="!(typeof getNestedValue(row, col.field) === 'object') && !col.tagOnly"
									class="text-truncate align-self-center"
									:class="col.classObject"
								>
									{{ getNestedValue(row, col.field) }}
								</div>
								<div v-else-if="typeof getNestedValue(row, col.field) === 'object' && !col.tagOnly">
									<div v-if="!row[col.field].toolTip" class="text-truncate align-self-center" :class="row[col.field].classObject">
										{{ getNestedValue(row, col.field).value }}
									</div>
									<div v-else class="text-truncate align-self-center" :class="row[col.field].classObject">
										<AppTooltip
											hover
											direction="bottom"
											:tip="row[col.field].toolTip"
											class="d-inline"
											:html="true"
											:hideDelay="row[col.field].toolTipHideDelay"
										>
											{{ getNestedValue(row, col.field).value }}
											<InlineSvg
												v-if="getNestedValue(row, col.field).icon"
												:src="getNestedValue(row, col.field).icon"
												width="16"
												height="16"
											/>
										</AppTooltip>
									</div>
								</div>
							</div>
							<div
								v-else
								v-html="getNestedValue(row, col.field)"
								style="max-height: 100px; overflow-y: auto;"
								:class="col.classObject"
							></div>
						</td>
						<td v-if="showActionCell" @click.self="moreDetails(row)">
							<div v-if="rowHasActions(row)" class="d-flex justify-content-end" @click.self="moreDetails(row)">
								<AppButton
									v-if="row.actionButton"
									:name="`action-button-${row.actionButton.label}`"
									:disabled="!row.actionButton.enabled"
									size="sm"
									@click="actionButtonClickHandler($event, row)"
									class="overflow-hidden mr-2"
									:outline="row.actionButton.outline"
									:customColor="row.actionButton.customColor"
									:danger="row.actionButton.danger"
									:icon="row.actionButton.image"
								>
									<div class="d-flex align-content-center">
										<span>{{ row.actionButton.label }} </span>
									</div>
								</AppButton>

								<AppDropdownMenu
									v-if="row.dropdownMenu && row.dropdownMenu.enabled"
									class="mx-1"
									:options="row.dropdownMenu.options"
									@selected="dropdownSelectHandler($event, row)"
									:useStaticPosition="true"
									:manual-control="true"
								/>
							</div>
						</td>
					</tr>
				</table>

				<div class="table-wrapper-header justify-content-end">
					<div class="para-2 table-wrapper-header-actions">
						<div class="header-custom-slot"><slot name="header-custom"></slot></div>
						<div class="table-pagination" v-if="totalPages">
							{{ `Page ${page} of ${totalPages}` }}
							<InlineSvg
								id="previous-page"
								src="images/icons/ic-chevron-up.svg"
								@click="previousPage"
								:class="{ disabled: page === 1 || isLoading }"
							/>
							<InlineSvg
								id="next-page"
								src="images/icons/ic-chevron-up.svg"
								@click="nextPage"
								:class="{ disabled: page === totalPages || isLoading }"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- mobile table -->
		<div class=" d-block d-md-none">
			<div :class="{ 'table-wrapper': !removeTableWrapperStyling }">
				<div class="table-container">
					<div class="mobile-headers border-bottom p-3">
						<div class="row">
							<div class="text-truncate align-self-center px-0" :class="mobileFirstColumnClass">
								<div>{{ mobileColumns.firstColumn.label }}</div>
							</div>
							<div class="text-truncate align-self-center" :class="mobileSecondColumnClass">
								<div>{{ mobileColumns.secondColumn.label }}</div>
							</div>
							<div v-if="showThirdColumnLabel" class="text-truncate align-self-center" :class="mobileThirdColumnClass">
								<div>{{ mobileColumns.thirdColumn.label }}</div>
							</div>
						</div>
					</div>
					<div class="mobile-items">
						<div class="mobile-item border-bottom p-3" v-for="(row, j) in items" :key="`row-${j}-m`" @click.self="moreDetails(row)">
							<div class="row item-toggle" @click="moreDetails(row)">
								<div class="px-0" :class="mobileFirstColumnClass">
									<div v-if="mobileColumns.firstColumn.tags && mobileColumns.firstColumn.tags.length > 0" class="d-inline-block">
										<AppTag
											v-for="(tag, n) in mobileColumns.firstColumn.tags"
											:key="`tag-mobile-${n}`"
											:type="tag.type"
											:field="tag.field"
											:value="getNestedValue(row, tag.field)"
											:label="getNestedValue(row, tag.label)"
											:toolTip="getNestedValue(row, tag.toolTip)"
											:backgroundColor="tag.backgroundColor"
											:textColor="tag.textColor"
										></AppTag>
									</div>
									<div
										v-if="mobileColumns.firstColumn.isHTML"
										style="line-height: 24px"
										:class="mobileColumns.firstColumn.classObject"
										v-html="getNestedValue(row, mobileColumns.firstColumn.field)"
									></div>
									<div v-else style="line-height: 24px" :class="mobileColumns.firstColumn.classObject">
										{{ getNestedValue(row, mobileColumns.firstColumn.field) }}
									</div>
								</div>
								<div :class="mobileSecondColumnClass">
									<div
										v-if="mobileColumns.secondColumn.isHTML"
										class="text-truncate align-self-center"
										:class="mobileColumns.secondColumn.classObject"
										v-html="getNestedValue(row, mobileColumns.secondColumn.field)"
									></div>
									<div v-else class="text-truncate align-self-center" :class="mobileColumns.secondColumn.classObject">
										{{ getNestedValue(row, mobileColumns.secondColumn.field) }}
									</div>
									<div v-if="mobileColumns.secondColumn.tags && mobileColumns.secondColumn.tags.length > 0" class="d-inline-flex">
										<div class="d-inline-flex flex-wrap">
											<AppTag
												v-for="(tag, n) in mobileColumns.secondColumn.tags"
												:key="`tag-mobile2-${n}`"
												:type="tag.type"
												:field="tag.field"
												:value="getNestedValue(row, tag.field)"
												:label="getNestedValue(row, tag.label)"
												:backgroundColor="tag.backgroundColor"
												:textColor="tag.textColor"
												:toolTip="getNestedValue(row, tag.toolTip)"
												:class="{ 'mt-2': !mobileColumns.secondColumn.progress }"
												@click="moreDetails(row)"
											></AppTag>
										</div>
										<div v-if="getNestedValue(row, mobileColumns.secondColumn.progress) >= 0" class="progress-tracker">
											<div class="progress-tracker-total" style="width: 100%"></div>
											<div
												class="progress-tracker-complete"
												:style="`width: ${getNestedValue(row, mobileColumns.secondColumn.progress)}%`"
											></div>
										</div>
									</div>
								</div>
								<div class="px-0" :class="mobileThirdColumnClass">
									<div
										style="height: 21px;"
										v-if="!showDetails(row) && mobileColumns.thirdColumn"
										:class="mobileColumns.thirdColumn.classObject"
									>
										<div>
											<div
												class="text-truncate text-right d-flex justify-content-end align-self-center"
												v-if="!(typeof getNestedValue(row, mobileColumns.thirdColumn.field) === 'object')"
											>
												{{ getNestedValue(row, mobileColumns.thirdColumn.field) }}
											</div>
											<div v-else-if="typeof getNestedValue(row, mobileColumns.thirdColumn.field) === 'object'">
												<div
													v-if="!row[mobileColumns.thirdColumn.field].toolTip"
													class="text-truncate d-flex justify-content-end align-self-center"
													:class="row[mobileColumns.thirdColumn.field].classObject"
												>
													{{ getNestedValue(row, mobileColumns.thirdColumn.field).value }}
												</div>
												<div
													v-else
													class="text-truncate d-flex justify-content-end align-self-center"
													:class="row[mobileColumns.thirdColumn.field].classObject"
												>
													<AppTooltip
														hover
														direction="bottom"
														:tip="row[mobileColumns.thirdColumn.field].toolTip"
														class="d-inline"
														>{{ getNestedValue(row, mobileColumns.thirdColumn.field).value }}
													</AppTooltip>
												</div>
											</div>
										</div>
									</div>

									<div v-if="mobileColumns.showExpandArrow" class="d-flex justify-content-end">
										<img
											class="expand-arrow"
											:class="{ 'rotate-90': !showDetails(row) }"
											src="/images/dropdown-arrow.svg"
											width="16px"
											@click.stop="moreDetails(row)"
										/>
									</div>
								</div>
							</div>
							<AppButton
								v-if="row.actionButton"
								:name="`action-button-${row.actionButton.label}`"
								:disabled="!row.actionButton.enabled"
								class="mt-3 w-100"
								:outline="row.actionButton.outline"
								:customColor="row.actionButton.customColor"
								:danger="row.actionButton.danger"
								@click="actionButtonClickHandler($event, row)"
								:icon="row.actionButton.image"
								size="md"
							>
								<div class="d-flex align-content-center">
									<span>{{ row.actionButton.label }} </span>
								</div>
							</AppButton>
							<div class="row row-expanded" v-if="showDetails(row)">
								<div v-for="(eCol, p) in mobileExpansionColumns" class="col-12 text-truncate align-self-center" :key="`row-${p}-e`">
									<div class="d-flex justify-content-between my-2">
										<div class="my-auto">{{ eCol.label }}</div>
										<div class="my-auto dotted-line"></div>
										<div :class="eCol.classObject" v-if="!eCol.html">
											<div v-if="!(typeof getNestedValue(row, eCol.field) === 'object')" :class="eCol.classObject">
												<div v-if="eCol.tags && eCol.tags.length > 0" class="d-flex">
													<AppTag
														v-for="(tag, n) in eCol.tags"
														:key="`tag-mobile3-${n}`"
														:type="tag.type"
														:field="tag.field"
														:value="getNestedValue(row, tag.field)"
														:label="getNestedValue(row, tag.label)"
														:toolTip="getNestedValue(row, tag.toolTip)"
														:backgroundColor="tag.backgroundColor"
														:textColor="tag.textColor"
													></AppTag>
												</div>
												<div v-if="!eCol.tagOnly">
													{{ getNestedValue(row, eCol.field) }}
												</div>
											</div>
											<div v-else-if="typeof getNestedValue(row, eCol.field) === 'object'">
												<div
													v-if="!row[eCol.field].toolTip"
													class="text-truncate align-self-center"
													:class="row[eCol.field].classObject"
												>
													{{ getNestedValue(row, eCol.field).value }}
												</div>
												<div v-else :class="row[eCol.field].classObject">
													<AppTooltip hover direction="bottom" :tip="row[eCol.field].toolTip" class="d-inline"
														>{{ getNestedValue(row, eCol.field).value }}
													</AppTooltip>
												</div>
											</div>
										</div>
										<div v-else v-html="getNestedValue(row, eCol.field)"></div>
									</div>
								</div>
								<div class="col-12" v-if="row.actionButton">
									<AppButton
										v-if="row.actionButton"
										:name="`action-button-${row.actionButton.label}`"
										:disabled="!row.actionButton.enabled"
										@click="actionButtonClickHandler($event, row)"
										class="w-100 mx-auto"
										:outline="row.actionButton.outline"
										:customColor="row.actionButton.customColor"
										:danger="row.actionButton.danger"
										:icon="row.actionButton.image"
									>
										<div>
											<span class="mr-1">{{ row.actionButton.label }} </span>
										</div>
									</AppButton>
								</div>
								<div class="col-12" v-if="row.dropdownMenu && row.dropdownMenu.enabled">
									<div class="my-2" v-for="(option, o) in row.dropdownMenu.options" :key="`option-button-${o}`">
										<AppButton
											:name="`dropdown-button-${option.label}`"
											@click="dropdownSelectHandler($event, row, option)"
											class="w-100 mx-auto"
											:outline="true"
										>
											<div>
												<span class="mr-1">{{ option.label }} </span>
											</div>
										</AppButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="table-pagination mt-4 text-center" v-if="totalPages">
				{{ `Page ${page} of ${totalPages}` }}
				<InlineSvg id="previous-page" src="images/icons/ic-chevron-up.svg" @click="previousPage" :class="{ disabled: page === 1 }" />
				<InlineSvg id="next-page" src="images/icons/ic-chevron-up.svg" @click="nextPage" :class="{ disabled: page === totalPages }" />
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppTableV2',
		props: {
			title: { type: String, default: '' },
			icon: { type: String, default: '' },
			headers: { type: Array, default: () => [] },
			items: { type: Array, default: () => [] },
			filters: { type: Array, default: () => [] },
			filterArray: { type: Array, default: () => [] },
			defaultValue: { type: String, default: '' },
			page: { type: Number, default: 0 },
			totalPages: { type: Number, default: 0 },
			tableColumns: { type: Array, default: () => [] },
			mobileColumns: { type: Object, default: () => {} },
			mobileExpansionColumns: { type: Array, default: () => [] },
			hasActionButton: { type: Boolean, default: true },
			isLoading: { type: Boolean, default: false },
			itemId: { type: String, default: 'id' },
			rowClickable: { type: Boolean, default: false },
			showCsvDownload: { type: Boolean, default: true },
			removeTableWrapperStyling: { type: Boolean, default: false },
		},
		data() {
			return {
				showingDetails: [],
			}
		},
		computed: {
			showActionCell() {
				return this.items.reduce((showCell, row) => showCell || this.rowHasActions(row), false)
			},
			mobileFirstColumnClass() {
				const classObj = {}
				classObj[`col-${this.mobileColumns?.firstColumn?.widthGrid ?? 3}`] = true
				return classObj
			},
			mobileSecondColumnClass() {
				const classObj = {}
				classObj[`col-${this.mobileColumns?.secondColumn?.widthGrid ?? 7}`] = true
				if (this.mobileColumns?.secondColumn?.progress) {
					classObj['d-flex'] = true
				}
				return classObj
			},
			mobileThirdColumnClass() {
				const classObj = {}
				classObj[`col-${this.mobileColumns?.thirdColumn?.widthGrid ?? 2}`] = true
				return classObj
			},
			showThirdColumnLabel() {
				return this.mobileColumns?.thirdColumn?.label
			},
		},
		methods: {
			rowHasActions(row) {
				return row.actionButton || (row.dropdownMenu && row.dropdownMenu.enabled)
			},
			dropdownSelectHandler(e, row, option) {
				if (!option) option = e
				this.$emit('dropdownSelected', { option, row })
				const handler = option?.handler
				if (handler) {
					handler(row)
				}
			},
			actionButtonClickHandler(e, row) {
				this.$emit('actionButtonClicked', row)
				const handler = row?.actionButton?.handler
				if (handler) {
					handler(row)
				}
			},
			getNestedValue(item, field) {
				if (!field) return undefined
				const indexes = field.split('.')
				let nestedItem = item
				for (const index of indexes) {
					if (nestedItem) nestedItem = nestedItem[index]
				}
				return this.parseValue(nestedItem)
			},
			parseValue(value) {
				return value || value === 0 || typeof value === 'boolean' ? value : this.defaultValue
			},
			filterChanged(filterArray) {
				this.$emit('filterChanged', filterArray)
			},
			resetFilters() {
				this.$emit('resetFilters')
			},
			previousPage() {
				this.showingDetails = []
				this.$emit('paginateHandle', Math.max(this.page - 1, 1))
			},
			nextPage() {
				this.showingDetails = []
				this.$emit('paginateHandle', Math.min(this.page + 1, this.totalPages))
			},
			moreDetails(row) {
				if (!this.isLoading && this.rowClickable) {
					this.rowSelected(row)
				} else {
					this.toggleDetails(row)
				}
			},
			toggleDetails(row) {
				if (this.showingDetails.indexOf(row[this.itemId]) > -1) {
					this.showingDetails.splice(this.showingDetails.indexOf(row[this.itemId]), 1)
				} else {
					this.showingDetails.push(row[this.itemId])
				}
			},
			showDetails(row) {
				return this.showingDetails.indexOf(row[this.itemId]) > -1
			},
			rowSelected(row) {
				this.$emit('rowSelected', row)
			},
		},
	}
</script>
<style lang="scss" scoped>
	.loading {
		opacity: 50%;
	}
	table {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;

		tr {
			padding-left: 20px;
			padding-right: 20px;
			border-top: 1px solid var(--stan-grey);
			border-bottom: 1px solid var(--stan-grey);
			height: 50px;

			&:first-child {
				border-top: none;
				&:hover {
					background-color: white;
				}
			}

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				background-color: var(--stan-blue-8);
			}

			th {
				padding: 0.25rem 1rem;
				color: var(--stan-text-light-color);
				height: 50px;
				font-weight: 600;
				&.controls {
					width: 50px;
					&.has-action-button {
						width: 180px;
					}
				}
			}

			td {
				padding: 0.25rem 1rem;
				color: var(--stan-primary-dark-color);
				height: 50px;
			}
		}
	}

	.table-wrapper {
		border: none;
		border-radius: 12px;
		background-color: white;
		padding: 1rem;
		box-shadow: var(--stan-box-shadow);

		.table-wrapper-header {
			display: flex;
			justify-content: space-between;
			margin: 10px;
			.table-title {
			}
			.table-wrapper-header-actions {
				display: grid;
				grid-template-columns: auto auto;
				align-items: center;
				gap: 1rem;
			}
		}
	}
	.table-pagination {
		margin: 10px;
		margin-top: 20px;

		.disabled {
			opacity: 0.3;
			pointer-events: none;
		}

		svg::v-deep {
			path {
				fill: var(--stan-primary-primary-color);
			}
		}
		#previous-page {
			cursor: pointer;
			transform: rotate(-90deg);
		}
		#next-page {
			cursor: pointer;
			transform: rotate(90deg);
		}
	}

	.table-container {
		width: 100%;
		overflow: inherit;
		overflow-x: auto;
		border-radius: 12px;
	}

	.dotted-line {
		border-bottom: 1px dashed var(--stan-grey);
		width: 100%;
		margin: 10px;
	}

	.mobile-items {
		.mobile-item {
			font-size: 14px;
			.item-toggle {
				cursor: pointer;
			}
		}
	}

	.expand-arrow {
		height: 16px;
		width: 16px;
		margin-top: 12px;
		margin-right: 6px;
		&.rotate-90 {
			transform: rotate(-90deg);
		}
	}
	.mobile-expand-center {
		.expand-arrow {
			margin-top: 4px;
		}
	}

	.pagination-button {
		min-width: 100px;
	}

	.right-align {
		display: grid !important;
		justify-content: end;
		align-items: center;
	}

	.text-no-truncate {
		overflow: auto !important;
		text-overflow: inherit;
		white-space: normal !important;
	}
	.pointer {
		cursor: pointer;
	}
	.mobile-action-button {
		height: 24px;
	}
	.progress-tracker {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 4px;
		height: 4px;
		width: 6vw;
		min-width: 40px;
		border-radius: 50%;
		.progress-tracker-complete {
			margin-top: -4px;
			height: 100%;
			background-color: var(--stan-boulder-6);
			border-radius: 2px;
			z-index: 2;
		}
		.progress-tracker-total {
			height: 100%;
			background-color: var(--stan-oxford-blue-2);
			border-radius: 2px;
			z-index: 1;
		}
	}

	.order-last {
		order: 999;
		padding-top: 2px;
	}

	.dropdownEnabled {
		max-width: 97%;
	}
</style>
