<template>
	<div class="overflow-visible">
		<div v-if="showTitle" class="pb-2">
			<span class="text-bold text-dark h4">{{ $t('Tags') }}</span>
		</div>

		<div class="overflow-visible">
			<AppNewMultiSelect
				:placeholder="placeholder"
				:items="availableFanTags"
				:addNewItemPrefixCopy="allowAddTag ? '+ Press Enter to create a new tag' : ''"
				:canAddNewItem="allowAddTag"
				:characterLimit="allowAddTag ? 50 : null"
				:disabled="disabled"
				:selectedItems="fanTags"
				label="name"
				trackBy="name"
				@customSelectFunction="selectTag"
				@customRemoveFunction="removeTag"
			/>
		</div>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'
	import axios from 'axios'

	export default {
		props: {
			showTitle: {
				type: Boolean,
				default: true,
			},
			userFanId: {
				type: Number,
			},
			pseudoTags: {
				type: Array,
				default: () => [],
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			searchable: {
				type: Boolean,
				default: true,
			},
			mode: {
				type: String,
				default: 'include-tags', // 'include-tags', 'exclude-tags', 'single-fan', 'single-tag'
			},
		},

		mounted() {
			if (this.mode === 'include-tags') {
				this.fetchFanTagList()
			} else if (this.mode === 'exclude-tags') {
				this.fetchFanTagList()
			} else if (this.mode === 'single-fan') {
				this.fetchTagsForSingleFan()
			} else if (this.mode === 'single-tag') {
				this.fetchTagsForImport()
			}
		},
		data() {
			return {
				fanTags: [],
				availableFanTags: [],
				fanTagsWithData: [],
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			placeholder() {
				switch (this.mode) {
					case 'single-fan':
						return 'Select or add a tag'
					case 'single-tag':
						return 'Select a tag'
					case 'include-tags':
						return 'Select tags'
					case 'exclude-tags':
					default:
						return "Don't send to.."
				}
			},
			allowAddTag() {
				if (this.mode === 'include-tags' || this.mode === 'exclude-tags') {
					return false
				}
				return true
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			emitFanTagsWithData() {
				const fanTagsWithData = []
				this.fanTags.forEach(t => {
					fanTagsWithData.push(...this.availableFanTags.filter(tag => tag.name === t.name))
				})
				this.fanTagsWithData = fanTagsWithData
				this.$emit('tags-updated', fanTagsWithData)
			},
			selectTag(tag) {
				this.handleEveryoneTag(tag)
				this.postTags(tag.name)
				this.emitFanTagsWithData()
			},
			handleEveryoneTag(tag) {
				// there are 2 cases:
				// 1. Everyone is added, and other tags are present. Need to remove others.
				if (tag.name === 'Everyone') {
					this.fanTags.length = 0
					this.fanTags.push(tag)
				}
				// 2. Another tag is added, and "Everyone" is present. Need to remove "Everyone"
				else {
					const index = this.fanTags.findIndex(tag => tag.name === 'Everyone')
					if (index !== -1) {
						this.fanTags.splice(index, 1)
					}
				}
			},
			removeTag(tag) {
				this.deleteTags(tag.name)
				this.emitFanTagsWithData()
			},
			refreshFanTags() {
				this.$axios.get(`/v1/tags/fan-tags/${this.userFanId}`).then(res => {
					this.fanTags = []
					res.data.tags.forEach(tag => {
						this.fanTags.push({ name: tag })
					})
				})
			},
			fetchFanTagList() {
				this.$axios.get('/v1/tags/fan-tags-list').then(list => {
					if (this.mode === 'include-tags') {
						// add "everyone" tag
						const everyoneTag = { name: 'Everyone', type: 'pseudo', data: {} }
						this.availableFanTags.push(everyoneTag)
					}
					list.data.tags.forEach(tag => {
						this.availableFanTags.push({ name: tag })
					})
					// add list of pseudo tags
					this.pseudoTags.forEach(p => {
						this.availableFanTags.push({ name: p.tag, type: 'pseudo', data: p })
					})
				})
				this.$emit('fan-tag-list-loaded')
			},
			fetchTagsForSingleFan() {
				const getfanTagsList = axios.get('/v1/tags/fan-tags-list')
				const getfanTags = axios.get(`/v1/tags/fan-tags/${this.userFanId}`)
				this.$axios.all([getfanTagsList, getfanTags]).then(
					this.$axios.spread((...responses) => {
						const list = responses[0]
						const tags = responses[1]
						list.data.tags.forEach(tag => {
							this.availableFanTags.push({ name: tag })
						})
						tags.data.tags.forEach(tag => {
							this.fanTags.push({ name: tag })
						})
						this.$emit('tags-loaded')
					})
				)
			},
			fetchTagsForImport() {
				const newTag = `Imported on ${this.$commonHelper.formatDateShort(Date.now(), true, true)} - ${this.$commonHelper.formatTime(
					Date.now()
				)}`
				const importedTag = { name: newTag }
				this.availableFanTags = []
				this.availableFanTags.push(importedTag)
				this.fanTags.push(importedTag)
				this.emitFanTagsWithData()
			},
			async postTags(tagName) {
				if (this.mode === 'include-tags' || this.mode === 'exclude-tags') {
					return
				}
				if (this.mode === 'single-tag') {
					this.availableFanTags = []
					this.availableFanTags.push({ name: tagName })
					this.fanTags = []
					this.fanTags.push({ name: tagName })
					return
				}
				if (this.mode === 'single-fan') {
					const payload = {
						user_fan_id: this.userFanId,
						name: tagName,
					}
					await this.$axios
						.post('/v1/tags/fan-tags', payload)
						.then(() => this.refreshFanTags())
						.catch(error => {
							this.$stanNotify({
								type: 'error', // can be 'info', 'warning', 'success', 'error'
								title: this.$t('Oops'),
								text: error.response.data.message || this.$t('Something went wrong'),
								duration: 2000,
							})
							this.$logError(error)
						})
				}
			},
			deleteTags(tagName) {
				if (this.mode === 'include-tags' || this.mode === 'exclude-tags') {
					return
				}
				if (this.mode === 'single-tag') {
					this.fanTags = []
					return
				}
				if (this.mode === 'single-fan') {
					const query = {
						user_fan_id: this.userFanId,
						name: tagName,
					}
					axios
						.delete('/v1/tags/fan-tags', { params: query })
						.then(() => this.refreshFanTags())
						.catch(error => {
							this.$stanNotify({
								type: 'error', // can be 'info', 'warning', 'success', 'error'
								title: this.$t('Oops'),
								text: error.response.data.message || this.$t('Something went wrong'),
								duration: 2000,
							})
							this.$logError(error)
						})
				}
			},
			reset() {
				this.fanTags = []
			},
			// used only when the tag selector is used in email broadcast
			forceInjectTags(tags) {
				tags.forEach(tag => {
					this.fanTags.push(tag)
				})
				this.$emit('tags-updated', tags)
			},
		},
	}
</script>

<style lang="scss">
	.exclude-tags-mode {
		.multiselect-tag {
			background-color: var(--stan-gray-primary-color) !important;
			color: var(--stan-text-light-color) !important;
			i:before {
				color: var(--stan-text-light-color) !important;
			}
		}
	}
</style>
