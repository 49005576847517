var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "position-relative cursor-pointer d-flex flex-row justify-content-between align-self-center plan-options-container",
      class: {
        "hide-discount-experiment": _vm.hidePlatformDiscountPercentExperiment
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "plan-option-selected bg-white position-absolute d-flex justify-content-center align-items-center h-100",
          class: {
            "yearly-plan": _vm.isYearlyPlan,
            "monthly-plan": !_vm.isYearlyPlan,
            "hide-discount-experiment":
              _vm.hidePlatformDiscountPercentExperiment
          }
        },
        [
          _vm.isYearlyPlan
            ? _c("span", { staticClass: "para-2 text-bold text-light" }, [
                _vm._v("\n\t\t\t" + _vm._s(_vm.annualSliderText) + "\n\t\t")
              ])
            : _c("span", { staticClass: "para-2 text-bold text-light" }, [
                _vm._v("\n\t\t\tMonthly\n\t\t")
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "para-2 text-bold text-light monthly-plan-unselected",
          on: {
            click: function($event) {
              return _vm.handleToggleInterval(
                _vm.$constants.TIER_INTERVAL.MONTH
              )
            }
          }
        },
        [_vm._v("\n\t\tMonthly\n\t")]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "para-2 text-bold text-light yearly-plan-unselected",
          on: {
            click: function($event) {
              return _vm.handleToggleInterval(_vm.$constants.TIER_INTERVAL.YEAR)
            }
          }
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.annualSliderText) + "\n\t")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }