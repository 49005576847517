<template>
	<AppModal
		ref="exportCsvModal"
		:title="modalTitle"
		:staticDataBackdrop="false"
		:subheading="excelFilename"
		@close="closeModal"
		:hide-close="true"
		:action="'Finish'"
		@cta="finishDownload"
	>
		<div>
			<div class="importing-animation">
				<InlineSvg src="/images/icons/ic-box.svg" />
				<InlineSvg src="/images/icons/ic-box.svg" />
				<InlineSvg src="/images/icons/ic-box.svg" />
			</div>
			<AppProgress :min="0" :max="totalExportPages" :value="exportingPage" />
		</div>
	</AppModal>
</template>

<script>
	import axios from 'axios'

	export default {
		props: {
			apiEndpoint: {
				type: String,
				required: true,
			},
			requestType: {
				type: String,
				default: 'POST',
			},
			apiParams: {
				type: Object,
				default: () => ({}),
			},
			totalExportPages: {
				type: Number,
				default: 0,
			},
			excelFilename: {
				type: String,
				default: '',
			},
			modalTitle: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				isExporting: true,
				exportingPage: 0,
				jsonData: [],
			}
		},
		methods: {
			resetState() {
				this.isExporting = true
				this.exportingPage = 0
				this.jsonData = []
			},
			showModal() {
				$(this.$refs.exportCsvModal.$el).modal('show')
				this.resetState()
				this.$nextTick(() => {
					// Reason: Show and Download
					this.startDownload()
				})
			},
			closeModal() {
				setTimeout(() => {
					$(this.$refs.exportCsvModal.$el).modal('hide')
				}, 500)
				// Reason: doesn't close for speedy downloads and better visibility
			},
			finishDownload() {
				this.isExporting = false
			},
			async startDownload() {
				try {
					const allData = await this.fetchAllPages()
					this.$emit('data-fetched', allData)
					this.$nextTick(() => {
						this.closeModal()
					})
				} catch (error) {
					this.$stanNotify({
						type: 'error',
						title: `${this.modalTitle} could not be generated, let us help you with that!`,
						text: this.$t('Please email friends@stanwith.me'),
						duration: 4000,
					})
					this.$nextTick(() => {
						this.closeModal()
					})
				}
			},
			async fetchPageData(page) {
				if (this.requestType.toUpperCase() === 'POST') {
					const response = await axios.post(this.apiEndpoint, {
						...this.apiParams,
						page,
					})
					return response.data?.all?.data || []
				}
				const response = await axios.get(this.apiEndpoint, {
					params: {
						...this.apiParams,
						page,
					},
				})
				// recipient_list - is for retrieving email flow receipient exports
				return response.data?.all?.data || response.data.data || response?.data?.recipient_list || []
			},

			async fetchAllPages() {
				const pages = Array.from({ length: this.totalExportPages }, (_, i) => i + 1)
				const CONCURRENT_REQUESTS = 5 // This is the number of simultaneous requests we want to make.
				let allData = []

				const fetchBatch = async startIdx => {
					if (startIdx >= pages.length || !this.isExporting) return

					// Grab the next batch of pages.
					const batchPages = pages.slice(startIdx, startIdx + CONCURRENT_REQUESTS)

					const dataPromises = batchPages.map(page => this.fetchPageData(page))

					const batchData = await Promise.all(dataPromises)
					allData = allData.concat(...batchData)

					// Update the progress bar
					this.exportingPage += batchPages.length

					// Move to the next batch.
					await fetchBatch(startIdx + CONCURRENT_REQUESTS)
				}

				// Start the fetching with the first batch.
				await fetchBatch(0)

				return allData
			},
		},
	}
</script>

<style lang="scss" scoped>
	.importing-animation {
		padding: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		svg::v-deep {
			path {
				fill: var(--stan-primary-soft-color);
			}
		}
		svg:first-child {
			width: 78px;
			height: 78px;
			margin: 5px;
			transform: translateX(30px) rotate(8.88deg);
			animation: float1 3s ease-in-out infinite;
		}
		svg:nth-child(2) {
			width: 118px;
			height: 118px;
			animation: float2 4s ease-in-out infinite;
		}
		svg:last-child {
			width: 65px;
			height: 65px;
			margin: 5px;
			transform: translateX(-30px) rotate(-10deg);
			animation: float3 3s ease-in-out infinite;
		}
	}
	@keyframes float1 {
		0% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
		50% {
			transform: translateX(30px) rotate(8.88deg) translatey(0px);
		}
		100% {
			transform: translateX(30px) rotate(8.88deg) translatey(-10px);
		}
	}
	@keyframes float2 {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(-20px);
		}
		100% {
			transform: translatey(0px);
		}
	}
	@keyframes float3 {
		0% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
		50% {
			transform: translateX(-30px) rotate(-10deg) translatey(-10px);
		}
		100% {
			transform: translateX(-30px) rotate(-10deg) translatey(0px);
		}
	}
</style>
