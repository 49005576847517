var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        id: "confirm-change-plan-modal",
        title: _vm.$t("Confirm Payment"),
        subheading: `${_vm.$t("You will be charged the new fee starting")} ${
          _vm.computedStartDateOfNewPlan
        }`,
        overflow: true,
        width: 580
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "price-container" }, [
            _c("span", { staticClass: "para-3 text-light" }, [
              _vm._v(
                _vm._s(
                  `${_vm.$t("Your new rate starting")} ${
                    _vm.computedStartDateOfNewPlan
                  }`
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "price-info" }, [
              _c(
                "h1",
                {
                  staticClass: "text-bold",
                  staticStyle: { "font-size": "40px" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$commonHelper.formatCurrency(
                        _vm.priceTag,
                        _vm.platformCurrency
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "text-light" }, [
                _vm._v(_vm._s(_vm.stringInterval))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "receipt-image",
            attrs: {
              src: require("./assets/pay_confirmation.svg"),
              draggable: "false"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "info-container" }, [
            _c("div", { staticClass: "label-container" }, [
              _c("span", { staticClass: "para-3 text-light" }, [
                _vm._v(_vm._s(_vm.$t("To")))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "para-3 text-light" }, [
                _vm._v(_vm._s(_vm.$t("From")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value-container" }, [
              _c("span", { staticClass: "para-3 text-dark text-bold" }, [
                _vm._v("@" + _vm._s(this.user.username))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "para-3 text-dark text-bold" }, [
                _vm._v(_vm._s(_vm.$t("Stan - Your All-in-One Creator Store")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "billing-info" }, [
            _vm.estimateSubscriptionChangeObject.status === "upgrading"
              ? _c("span", { staticClass: "para-3" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.$t("You will be charged a prorated amount of")
                      ) +
                      "\n\t\t\t\t\t"
                  ),
                  _c("span", { staticClass: "para-3 text-bold" }, [
                    _vm._v(_vm._s(_vm.estimateSubscriptionChangeObject.cost))
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm.$t("today if you accept")) + "\n\t\t\t\t"
                  )
                ])
              : _c("span", { staticClass: "para-3" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.$t(
                          "Your Stan Subscription is a taxable write-off for your business."
                        )
                      ) +
                      "\n\t\t\t\t"
                  )
                ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "AppButton",
              {
                staticClass: "action-button",
                attrs: { loading: _vm.isUpdatingPlan },
                on: {
                  click: function($event) {
                    return _vm.handleUnderstandClick()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Yes, I understand")))]
            ),
            _vm._v(" "),
            _c(
              "AppButton",
              {
                staticClass: "action-button",
                attrs: { link: true, disabled: _vm.isUpdatingPlan },
                on: {
                  click: function($event) {
                    return _vm.goBack()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Go Back")))]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }