<template>
	<div
		@click="handleCardClick"
		class="d-flex flex-column cursor-pointer w-100 plan-detail-card position-relative"
		:class="{
			selected: isPlanSelected,
		}"
	>
		<div v-if="isPlanSelected" class="d-flex justify-content-center position-absolute align-self-center selected-pill">
			<span class="text-white para-3 text-bold">{{ $t('Selected') }}</span>
		</div>

		<!-- Section Header -->
		<div class="d-flex flex-column gap-5px">
			<div class="d-flex flex-row w-100 justify-content-between align-items-center position-relative">
				<span class="d-flex flex-row gap-4px">
					<span class="text-dark plan-title">
						{{ $t('Creator') }}
					</span>
					<span v-if="tier === $constants.TIER.CREATOR_PRO" class="text-primary-color plan-title playfair">
						{{ $t('Pro') }}
					</span>
				</span>

				<div v-if="isCurrentPlan || (!isCurrentPlan && interval === $constants.TIER_INTERVAL.YEAR)" class="plan-info-pill">
					<!-- Current Plan Pill -->
					<span
						v-if="isCurrentPlan"
						class="para-3 text-primary-color text-bold d-block pilly"
						:class="{
							'is-selected-plan-pill': isPlanSelected,
						}"
						>{{ $t('Current Plan') }}</span
					>

					<!-- Save $ Pill -->
					<span
						v-if="!isLoading && !isCurrentPlan && interval === $constants.TIER_INTERVAL.YEAR"
						class="para-3 text-primary-color text-bold d-block pilly"
						:class="{
							'is-selected-plan-pill': isPlanSelected,
						}"
						>{{ $t('Save') }} {{ formatCurrency(basePrice.monthlyAnnualized - basePrice.yearly) }}/yr</span
					>
				</div>
			</div>

			<div class="d-flex flex-row">
				<HollowDotsSpinner
					v-if="isLoading"
					:animation-duration="1000"
					:dot-size="14"
					class="h-100 m-1 d-flex align-items-center mx-auto"
					:dots-num="3"
					color="var(--stan-primary-soft-color)"
				/>
				<template v-else>
					<span v-if="interval === $constants.TIER_INTERVAL.MONTH" class="d-flex flex-row">
						<span class="para-1 text-primary-color">
							<span class="text-bold">{{ formatCurrency(basePrice.monthly) }}</span
							>/mo</span
						>
					</span>
					<span v-if="interval === $constants.TIER_INTERVAL.YEAR" class="d-flex flex-row gap-8px">
						<span class="para-1 text-decoration-line-through plan-line-through-cost"
							><span class="text-bold">{{ formatCurrency(basePrice.monthlyAnnualized) }}</span
							>/yr</span
						>

						<span class="para-1 text-primary-color">
							<span class="text-bold">{{ formatCurrency(basePrice.yearly) }}</span
							>/yr
						</span>
					</span>
				</template>
			</div>
		</div>

		<!-- List of features -->
		<div class="d-flex flex-column gap-15px">
			<span v-if="tier === $constants.TIER.CREATOR_PRO" class="para-2 text-bold everything-in-creator-text-div"
				><span class="text-italic">{{ $t('Everything') }}</span> {{ $t('in Creator, plus...') }}</span
			>
			<div
				class="d-flex flex-column gap-10px"
				:class="{
					'everything-in-creator-pill-div': tier === $constants.TIER.CREATOR_PRO,
				}"
			>
				<PlanFeaturePill
					:icon="'📱'"
					:header="$t('Mobile Optimized Link-in-Bio Store')"
					:description="$t('Replaces Linktree, Squarespace')"
				/>
				<PlanFeaturePill :icon="'🗓️'" :header="$t('Calendar Bookings')" :description="$t('Replaces Calendly')" />
				<PlanFeaturePill :icon="'🎓'" :header="$t('Course Builder')" :description="$t('Replaces Kajabi, Teachable')" />
			</div>

			<div v-if="tier === $constants.TIER.CREATOR_PRO" class="d-flex flex-column feature-list-container pro-feature-list-container gap-10px">
				<PlanFeaturePill :icon="'✉️'" :header="$t('Unlimited Email Marketing')" :description="$t('Save $100s vs. Mailchimp')" :isPro="true" />
				<PlanFeaturePill :icon="'📊'" :header="$t('Order Bumps')" :description="$t('Increase your sales by 40%+')" :isPro="true" />
				<PlanFeaturePill
					:icon="'💸'"
					:header="$t('Payment Plans')"
					:description="$t('Increase your conversion rates by 20%+')"
					:isPro="true"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import PlanFeaturePill from './PlanFeaturePill'

	export default {
		name: 'PlanDetailCard',
		components: {
			PlanFeaturePill,
		},
		props: {
			isCurrentPlan: {
				default: false,
				type: Boolean,
			},
			tier: {
				default: '',
				type: String,
			},
			interval: {
				default: '',
				type: String,
			},
			selected: {
				default: false,
				type: Boolean,
			},
		},
		computed: {
			...mapGetters('Auth', ['userCountryCode', 'user']),
			...mapGetters('PlatformSubscriptions', [
				'isLoading',
				'creatorMonthlyPrice',
				'creatorMonthlyAnnualizedPrice',
				'creatorAnnualPrice',
				'creatorProMonthlyPrice',
				'creatorProMonthlyAnnualizedPrice',
				'creatorProAnnualPrice',
				'platformCurrency',
			]),
			baseCreatorPrice() {
				return {
					monthly: this.creatorMonthlyPrice,
					yearly: this.creatorAnnualPrice,
					monthlyAnnualized: this.creatorMonthlyAnnualizedPrice,
				}
			},
			baseCreatorProPrice() {
				return {
					monthly: this.creatorProMonthlyPrice,
					yearly: this.creatorProAnnualPrice,
					monthlyAnnualized: this.creatorProMonthlyAnnualizedPrice,
				}
			},
			basePrice() {
				switch (this.tier) {
					case this.$constants.TIER.CREATOR:
						return this.baseCreatorPrice
					case this.$constants.TIER.CREATOR_PRO:
					default:
						return this.baseCreatorProPrice
				}
			},
			isPlanSelected() {
				return this.selected
			},
			hidePlatformDiscountPercentExperiment() {
				return this.user?.experiments?.hide_platform_discount_percent
			},
		},
		methods: {
			handleCardClick() {
				this.$emit('onCardClick')
			},
			formatCurrency(amount) {
				return this.$commonHelper.formatCurrency(amount, this.platformCurrency)
			},
		},
	}
</script>

<style lang="scss">
	.plan-detail-card {
		z-index: 10;
		padding: 30px 25px;
		gap: 20px;
		border-radius: 15px;
		border: 1.5px solid transparent;

		&.selected {
			background-color: white;
			border: 1.5px solid var(--stan-primary-primary-color);
			border-radius: 15px;
			box-shadow: var(--stan-box-shadow);
		}
	}

	.selected-pill {
		top: -15px;
		border-radius: 25px;
		padding: 7px 15px;
		background-color: var(--stan-primary-primary-color);
	}

	.plan-info-pill {
		position: absolute;
		top: 0;
		right: 0;
		.pilly {
			padding: 5px 8px;
			border-radius: 5px;
			background-color: rgba(99, 85, 255, 0.25);
			&.is-selected-plan-pill {
				background-color: var(--stan-primary-light-color);
			}
		}
	}

	.plan-title {
		font-weight: 800;
		font-size: 20px;
		line-height: 20px;

		&.playfair {
			font-family: 'Playfair Display';
		}
	}

	.plan-line-through-cost {
		color: var(--stan-primary-soft-color);
	}

	.pro-feature-list-container {
		background: rgba(181, 188, 255, 0.25);
		border: 1px dashed var(--stan-text-light-color);
		border-radius: 10px;
		padding: 12px;
	}

	.everything-in-creator-text-div {
		display: none;

		@media (max-width: 991px) {
			display: block;
		}
	}

	.everything-in-creator-pill-div {
		@media (max-width: 991px) {
			display: none !important;
		}
	}
</style>
