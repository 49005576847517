var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "modal",
      attrs: {
        id: "stanleyAiHaveDigitalDownload",
        title: "Do you have a digital download ready to sell?"
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-wrap gap-3 w-100 align-items-center justify-content-center"
        },
        [
          _c(
            "div",
            { staticClass: "option-box" },
            [
              _c("InlineSvg", {
                staticClass: "px-1",
                attrs: { src: "images/ai-have-product.svg" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-1 mb-3" }, [_vm._v("Yes I do!")]),
              _vm._v(" "),
              _c(
                "AppButton",
                {
                  attrs: {
                    name: "save-cancellation-flow-survey",
                    size: "sm",
                    outline: true
                  },
                  on: { click: _vm.useExisting }
                },
                [_vm._v("Create my product")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "option-box" },
            [
              _c("InlineSvg", {
                staticClass: "px-1",
                attrs: { src: "images/ai-need-product.svg" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-1 mb-3" }, [
                _vm._v("No, I need help")
              ]),
              _vm._v(" "),
              _c(
                "AppButton",
                {
                  attrs: {
                    name: "save-cancellation-flow-survey",
                    size: "sm",
                    outline: true
                  },
                  on: { click: _vm.goToStanley }
                },
                [_vm._v("Build with AI")]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }