var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { tabindex: "0" } }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center dropdown-item time-zone-wrapper",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.selectTimeZone.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "time-zone-iso" }, [
          _vm._v(_vm._s(_vm.option.iso) + " -")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "time-zone-name" }, [
          _vm._v(_vm._s(_vm.option.showName))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "time-zone-offset" }, [
          _vm._v("| UTC " + _vm._s(_vm.offset))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }