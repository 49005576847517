var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "calendar",
      class: { preview: _vm.preview },
      attrs: { id: `calendar__element__${_vm.slug}` }
    },
    [
      _c("div", { staticClass: "calendar__day-slots" }, [
        _c("div", { staticClass: "w-100 text-center calendar__title" }, [
          _vm._v(_vm._s(_vm.selectTitle))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-100 text-center calendar__timezone" }, [
          _vm._v(_vm._s(_vm.timezone))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex calendar__navigator w-100" }, [
          _c(
            "button",
            {
              staticClass: "px-2 font-size",
              attrs: { disabled: _vm.prevDisabled || _vm.isLoading },
              on: { click: _vm.previousMonth }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "16",
                    height: "24",
                    viewBox: "0 0 16 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M14 1L2 12L14 23",
                      stroke: "#000C2D",
                      "stroke-width": "3",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.step === 1
            ? _c("div", { staticClass: "calendar__subtitle month" }, [
                _vm._v(_vm._s(_vm.currentMonth))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.step === 2
            ? _c("div", { staticClass: "calendar__subtitle duration" }, [
                _c("strong", [_vm._v(_vm._s(_vm.selectedDate))]),
                _vm._v(
                  " (" +
                    _vm._s(
                      _vm.meeting &&
                        _vm.meeting.data &&
                        _vm.meeting.data.duration_minutes
                        ? _vm.meeting.data.duration_minutes
                        : 30
                    ) +
                    "\n\t\t\t\tmin)\n\t\t\t"
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.step === 3
            ? _c(
                "div",
                {
                  staticClass:
                    "calendar__subtitle final-time slide-in-fwd-bottom"
                },
                [_vm._v(_vm._s(_vm.finalTime))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "px-2 font-size",
              attrs: { disabled: _vm.isLoading },
              on: { click: _vm.nextMonth }
            },
            [
              _c(
                "svg",
                {
                  style: { visibility: _vm.step === 1 ? "visible" : "hidden" },
                  attrs: {
                    width: "16",
                    height: "24",
                    viewBox: "0 0 16 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M2 23L14 12L2 1",
                      stroke: "#000C2D",
                      "stroke-width": "3",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-100" }, [
          !_vm.isLoading
            ? _c(
                "div",
                { staticClass: "calendar__wrapper" },
                [
                  _vm.step === 1
                    ? _c("FlatPickr", {
                        key: "flatcalendar",
                        attrs: { config: _vm.config },
                        on: { "on-change": _vm.selectDate },
                        model: {
                          value: _vm.valueDate,
                          callback: function($$v) {
                            _vm.valueDate = $$v
                          },
                          expression: "valueDate"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading
            ? _c(
                "div",
                {
                  staticClass: "calendar__loader",
                  staticStyle: {
                    transition:
                      "0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms"
                  }
                },
                [
                  _c("HollowDotsSpinner", {
                    attrs: {
                      "animation-duration": 1000,
                      "dot-size": 12,
                      "dots-num": 3,
                      color: _vm.primaryColor
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      !_vm.isLoading && _vm.step === 2
        ? _c(
            "div",
            {
              staticClass: "calendar__time-slots",
              class: _vm.isDemo ? "preview-time-slots" : ""
            },
            [
              _vm._l(_vm.availableSlot, function(slot, idx) {
                return _c(
                  "div",
                  { key: `slot-${idx}`, staticClass: "calendar__time-slot" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "calendar__time-slot-button",
                        class: {
                          selected:
                            _vm.activeTime !== null &&
                            _vm.activeTime.startDateTime === slot.startDateTime
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectTime(slot)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "calendar__time" }, [
                          _vm._v(_vm._s(slot.stime) + " " + _vm._s(slot.ampm))
                        ])
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.availableSlot || _vm.availableSlot.length === 0
                ? _c(
                    "p",
                    { staticClass: "w-100 mt-3", attrs: { align: "center" } },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.$t("shared.calendar.no_available_times")) +
                          "\n\t\t"
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }