<template>
	<AppModal
		ref="modal"
		@cta="submitModalForm"
		@secondary-cta="hide"
		action="Confirm"
		secondary-action="Cancel"
		title="Edit flow"
		:loading="loading"
		fullscreenOnMobile
		hideClose
		large
	>
		<div class="edit-flow-content" :key="flowId">
			<div class="h3 text-bold">Details</div>
			<AppInput placeholder="Name Your Flow" v-model="flowName" label="Flow title" :disabled="updatesDisabled" />
			<div class="trigger-purchase-flow" v-if="!isDateTriggerActive">
				<div :class="{ 'form-error': $v.selectedProducts.$dirty && $v.selectedProducts.$invalid }">
					<label class="control-label mr-1">Product</label>
					<EditProductTags
						:key="editProductTagsKey"
						ref="editProductTags"
						:showTitle="false"
						:disabled="false"
						:searchable="true"
						@product-tag-list-loaded="populateProductTags"
						@tags-updated="productTagsUpdated"
						:onlyPaidProducts="isCartAbandonedTriggerActive"
					/>
				</div>
				<div class="d-flex my-2 align-items-center" v-if="!isCartAbandonedTriggerActive">
					<AppToggle v-model="showRemoveRecipients" class="mr-2" style="transform: translateY(-1px); min-width: 30px;" />
					<label class="control-label mr-1 mb-0">Remove customer from flow after product purchase</label>
				</div>
				<div v-show="showRemoveRecipients && !isCartAbandonedTriggerActive" class="trigger-purchase-flow mb-3">
					<div :class="{ 'form-error': $v.selectedProducts.$dirty && $v.selectedProducts.$invalid }">
						<EditProductTags
							:key="editExcludeTagsKey"
							ref="editExcludeTags"
							:showTitle="false"
							:disabled="false"
							:searchable="true"
							:includeAllProductsTag="false"
							:includeNewReferralTag="false"
							@product-tag-list-loaded="populateExcludeTags"
							@tags-updated="excludeTagsUpdated"
						/>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="mb-2">
					<label class="control-label">Timezone</label>
					<AppDropdown
						class="form-control"
						:options="timeZonesList"
						placeholder="Time Zone"
						reduce="name"
						label="label"
						:searchable="false"
						:dropdownRowComponent="timezoneRowComponent"
						v-model="selectedTimezone"
						icon="/images/icons/ic-globe-gray.svg"
						:disabled="updatesDisabled"
					/>
				</div>

				<div class="date-time-picker">
					<div class="date">
						<label class="control-label">Date</label>
						<AppFlatPickr
							ref="calendar"
							custom_class="form-control h-100"
							placeholder="Start Date"
							v-model="selectedDate"
							:disableMobile="true"
							class="h-100"
							:disabled="updatesDisabled"
						/>
					</div>
					<div class="time">
						<label class="control-label">Time</label>
						<AppDropdown
							:options="timesArrayList"
							:searchable="false"
							class="form-control"
							label="name"
							reduce="value"
							:rightAlign="false"
							v-model="selectedTime"
							:disabled="updatesDisabled"
						/>
					</div>
				</div>
			</div>
			<div class="immutable-details">
				<div class="detail-row">
					<div class="key">
						<InlineSvg src="/images/add-icon.svg" class="icon" />
						<span>Trigger</span>
					</div>
					<div class="value">
						{{ triggerText }}
					</div>
				</div>
				<div class="detail-row" v-if="recipientCount">
					<div class="key">
						<InlineSvg src="/images/icon-users.svg" class="icon" />
						<span>Audience</span>
					</div>
					<div class="value">{{ recipientCount }} Recipients</div>
				</div>
				<div class="detail-row" v-if="isDateTriggerActive">
					<div class="key">
						<InlineSvg src="/images/icon-users.svg" class="icon" />
						<span>Tags</span>
					</div>
					<div class="value tags">
						<AppTag v-for="(tag, index) in audienceTags" :key="index" :value="tag.name" type="referrer" />
					</div>
				</div>
			</div>
		</div>
	</AppModal>
</template>
<style lang="scss" scoped>
	.edit-flow-content {
		display: flex;
		flex-direction: column;
		gap: 24px;
		flex: 1 0 0;
		align-self: stretch;
	}
	.date-time-picker {
		display: flex;
		gap: 20px;
		align-items: center;
		.date {
			width: 50%;
		}
		.time {
			width: 50%;
		}
	}
	.immutable-details {
		display: flex;
		padding-top: 8px;
		flex-direction: column;
		gap: 40px;
		align-self: stretch;
		align-items: center;
		margin-bottom: 40px;

		.detail-row {
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 18px;
			letter-spacing: -0.28px;
			display: flex;
			gap: 72px;
			align-self: stretch;
			align-items: center;
			position: relative; /* Add position relative for pseudo-element positioning */

			/* Add a line after each element except the last */
			&:not(:last-child)::after {
				content: '';
				position: absolute;
				bottom: -20px; /* Position halfway in the gap (gap is 40px) */
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--stan-primary-light-color);
			}

			.key {
				.icon {
					margin-bottom: 2px;
					margin-right: 6px;
					width: 16px;
					height: 16px;
				}
				color: var(--stan-text-light-color);
				min-width: 128px;
				svg::v-deep {
					path:not(.no-fill) {
						stroke: var(--stan-text-light-color);
					}
				}
			}
			.tags {
				display: flex;
				flex-wrap: wrap;
				gap: 4px;
			}
			.value {
			}
		}
	}
</style>
<script>
	import { format, parseISO } from 'date-fns'
	import { mapActions, mapGetters } from 'vuex'
	import axios from 'axios'
	// eslint-disable-next-line import/extensions
	import { required, requiredIf } from 'vuelidate/lib/validators'

	import 'flatpickr/dist/flatpickr.css'
	import { timesArray, timeZonesRawList, getTimesValue, getTimesAPIValue } from '~/pages/stores/components/BaseDefaults.js'
	import EditProductTags from '~/components/modals/components/EditProductTags'
	import AppTimeZoneRow from '~/components/shared/AppTimeZoneRow'
	import FlowManagementMixin from '../../pages/flows/FlowManagementMixin.js'

	export default {
		components: { EditProductTags },
		mixins: [FlowManagementMixin],

		data() {
			return {
				loading: false,
				flowName: '',
				flowId: 0,
				editProductTagsKey: 0,
				editExcludeTagsKey: 0,
				selectedProducts: [],
				selectedExcludeProducts: [],
				audienceTags: [],
				showRemoveRecipients: false,
				selectedTrigger: this.$constants.FLOW_TRIGGER.CHECKOUT,
				timezoneRowComponent: AppTimeZoneRow,
				selectedDate: format(new Date(), this.$constants.DATE_FORMAT.ISO_8601_DATE),
				selectedDateError: false,
				selectedTime: (new Date().getHours() + 1) % 24,
				selectedTimeError: false,
				selectedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				recipientCount: 0,
				pastAllowedRescheduleTime: false,
				status: null,
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			isPurchaseTriggerActive() {
				return this.selectedTrigger === this.$constants.FLOW_TRIGGER.CHECKOUT
			},
			isCartAbandonedTriggerActive() {
				return this.selectedTrigger === this.$constants.FLOW_TRIGGER.CART_ABANDONMENT
			},
			isDateTriggerActive() {
				return this.selectedTrigger === this.$constants.FLOW_TRIGGER.TIME
			},
			triggerText() {
				if (this.selectedTrigger === this.$constants.FLOW_TRIGGER.CHECKOUT) return 'After product purchase'
				if (this.selectedTrigger === this.$constants.FLOW_TRIGGER.CART_ABANDONMENT) return 'After cart is abandoned'
				if (this.selectedTrigger === this.$constants.FLOW_TRIGGER.TIME) return 'On a specific date'
				return 'After product purchase'
			},
			timeZonesList() {
				return timeZonesRawList
			},
			timesArrayList() {
				return timesArray
			},
			minDateStart() {
				// We want to make sure the date contains no hours, minutes, seconds. To allow the auto loading
				return format(new Date(), this.$constants.DATE_FORMAT.ISO_8601_DATE)
			},
			noDateOrTime() {
				return !this.selectedDate || !this.selectedTime
			},
			updatesDisabled() {
				return this.status === this.$constants.FLOW_STATUS.PUBLISHED && this.pastAllowedRescheduleTime
			},
		},

		methods: {
			...mapActions('Flows', ['fetchFlows']),

			submitModalForm() {
				this.saveFlow().then(() => {
					this.$emit('confirmed-update')
				})
			},
			show(flow) {
				this.flow = flow
				this.setupModal()
				$(this.$refs.modal.$el).modal('show')
			},
			hide() {
				$(this.$refs.modal.$el).modal('hide')
				this.$emit('close')
			},
			setupModal() {
				this.pastAllowedRescheduleTime = false
				const flowData = this.flow.data || {}
				this.flowId = this.flow.flow_id
				this.status = this.flow.status
				this.flowName = flowData.name || ''
				this.flowType = this.flow.type
				this.selectedProducts = (flowData.filters && flowData.filters.page_id_list) || []
				this.selectedExcludeProducts = (flowData.filters && flowData.filters.exclude_page_id_list) || []
				this.selectedTrigger = this.flow.trigger || this.$constants.FLOW_TRIGGER.CHECKOUT
				this.showRemoveRecipients = this.selectedExcludeProducts.length > 0

				// Handle date trigger specific data
				if (this.isDateTriggerActive && flowData.specific_time) {
					this.selectedTime = getTimesValue(flowData.specific_time.time)
					this.selectedDate = flowData.specific_time.date
						? format(parseISO(flowData.specific_time.date), this.$constants.DATE_FORMAT.ISO_8601_DATE)
						: format(new Date(), this.$constants.DATE_FORMAT.ISO_8601_DATE)
					this.selectedTimezone = flowData.specific_time.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
					this.audienceTags = flowData.specific_time.tags || []

					const schedule = {
						selectedDate: this.selectedDate,
						selectedTime: this.selectedTime,
						selectedTimezone: this.selectedTimezone,
					}
					const checks = this.checkSchedule(schedule)

					if (!checks.isAtLeast10MinutesFromNow) {
						this.pastAllowedRescheduleTime = true
					}
				}

				this.recipientCount = this.flow.recipient_count ?? 0

				// Use nextTick to ensure DOM is updated before accessing refs
				this.$nextTick(() => {
					this.populateProductTags()
					this.populateExcludeTags()
				})
			},
			populateProductTags() {
				this.$nextTick(() => this.$refs.editProductTags?.forceInjectTags(this.selectedProducts))
			},
			populateExcludeTags() {
				this.$nextTick(() => this.$refs.editExcludeTags?.forceInjectTags(this.selectedExcludeProducts))
			},
			productTagsUpdated(event) {
				this.selectedProducts = event.map(e => e.page_id)
			},
			excludeTagsUpdated(event) {
				this.selectedExcludeProducts = event.map(e => e.page_id)
			},
			async saveFlow() {
				this.$v.$touch() // Trigger validations
				if (this.$v.$invalid) {
					this.$stanNotify({
						type: 'error', // can be 'info', 'warning', 'success', 'error'
						title: 'Wait up!',
						text: 'Please provide input for each field.',
						duration: 5000,
					})
					return
				}
				this.loading = true
				const data = {
					status: this.$constants.FLOW_STATUS.PUBLISHED,
					type: this.flowType,
					trigger: this.selectedTrigger,
					data: {
						name: this.flowName,
					},
				}
				if (this.isPurchaseTriggerActive || this.isCartAbandonedTriggerActive) {
					const filters = {
						page_id_list: this.selectedProducts,
					}
					if (this.selectedExcludeProducts.length > 0) filters.exclude_page_id_list = this.selectedExcludeProducts
					if (this.isCartAbandonedTriggerActive) filters.exclude_page_id_list = this.selectedProducts
					data.data = { ...data.data, filters }
				}

				if (this.isDateTriggerActive) {
					data.data.specific_time = {
						tags: this.audienceTags,
						date: this.selectedDate,
						time: getTimesAPIValue(this.selectedTime),
						timezone: this.selectedTimezone,
					}
				}

				if (this.flowId) {
					data.flow_id = this.flowId
				}
				await axios.put('v1/flows', data).catch(error => {
					if (error.response.status === 409) {
						this.error = error.response.data.message
						this.$stanNotify({
							type: 'error', // can be 'info', 'warning', 'success', 'error'
							title: 'Wait up!',
							text: this.error || 'An identical email flow already exists with the same audience/product/trigger.',
							duration: 5000,
						})
					}
				})
				this.$stanAnalytics('flow-updated', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: {
						flow_id: this.flowId,
					},
				})
				await this.fetchFlows()
				this.loading = false
				this.hide()
			},
		},
		validations() {
			return {
				flowName: {
					required,
				},
				flowType: {
					required,
				},
				selectedProducts: {
					// eslint-disable-next-line func-names
					required: requiredIf(function() {
						return (
							this.flowType === this.$constants.FLOW_TYPE.EVENT_TRIGGERED &&
							(this.selectedTrigger === this.$constants.FLOW_TRIGGER.CHECKOUT ||
								this.selectedTrigger === this.$constants.FLOW_TRIGGER.CART_ABANDONMENT)
						)
					}),
				},
				selectedTrigger: {
					// eslint-disable-next-line func-names
					required: requiredIf(function() {
						return this.flowType === this.$constants.FLOW_TYPE.EVENT_TRIGGERED
					}),
				},
			}
		},
	}
</script>
