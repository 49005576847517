<template>
	<div
		:id="id"
		ref="custom-modal"
		class="modal modal-block"
		:class="[{ fade: fade, 'p-0': position === 'end', 'is-video': isVideo }, { 'fullscreen-mobile': fullscreenOnMobile }]"
		data-keyboard="false"
		:data-backdrop="dataBackgroundValue"
		tabindex="-1"
		aria-hidden="true"
		@click="handleClickOutside"
	>
		<div
			:class="[
				sizeClass,
				'modal-dialog',
				{ 'modal-dialog-centered': position === 'center' },
				{ 'fit-content': width },
				{ 'modal-dialog-end move-up': position === 'end' },
				{ 'mobile-fullscreen': fullscreenOnMobile },
			]"
		>
			<Transition name="fade-quick" appear mode="out-in">
				<div
					ref="modalContent"
					class="modal-content add-section-modal"
					:key="String(transitionKey)"
					:class="{ framed: !frame, 'mobile-fullscreen-content': fullscreenOnMobile }"
					:style="computedStyle"
				>
					<div class="modal-content-wrapper" v-if="fullscreenOnMobile">
						<div v-if="!(isVideo || hideClose)" class="stan-modal-header" :class="{ 'sticky-header': fullscreenOnMobile }">
							<div class="close close-modal" data-dismiss="modal" aria-label="Close">
								<InlineSvg class="close-icon" src="/images/icons/ic-close.svg" />
							</div>
						</div>
						<div
							class="d-flex w-100 justify-content-center modal-heading"
							v-if="!(isVideo || hideClose) || title"
							:class="{ 'sticky-header': fullscreenOnMobile }"
						>
							<InlineSvg v-if="titleIcon" class="mr-2" :src="titleIcon" />
							<h3 v-if="title" class="h3 text-bold">{{ title }}</h3>
						</div>
						<div class="modal-inner-content" :class="{ 'overflow-visible': overflow, 'mobile-fullscreen-inner': fullscreenOnMobile }">
							<div v-if="subheading" class="modal-subheading para-2">
								{{ subheading }}
							</div>
							<div>
								<slot></slot>
							</div>
						</div>
						<div
							class="d-flex flex-column gap v-gap mt-3 pt-1 action-buttons"
							v-if="action"
							:class="{ 'sticky-footer': fullscreenOnMobile }"
						>
							<AppButton
								:name="`cta-button-modal-${id}`"
								:icon="actionIcon"
								:color="actionColor"
								:loading="loading"
								:disabled="disabled"
								@click="ctaClick"
								v-if="action"
								>{{ action }}</AppButton
							>
							<AppButton
								:name="`secondary-cta-button-modal-${id}`"
								class="p-0 h-auto"
								link
								:loading="loading"
								:disabled="disabled"
								:color="secondaryActionColor"
								@click="secondaryCtaClick"
								v-if="secondaryAction"
								>{{ secondaryAction }}</AppButton
							>
							<span v-if="footerNote.length" class="w-100 d-flex justify-content-center mt-3 para-4 text-light text-center">
								{{ footerNote }}
							</span>
						</div>
					</div>

					<!-- Non-mobile version without wrapper -->
					<template v-else>
						<div v-if="!(isVideo || hideClose)" class="stan-modal-header">
							<div class="close close-modal" data-dismiss="modal" aria-label="Close">
								<InlineSvg class="close-icon" src="/images/icons/ic-close.svg" />
							</div>
						</div>
						<div class="d-flex w-100 justify-content-center modal-heading" v-if="!(isVideo || hideClose) || title">
							<InlineSvg v-if="titleIcon" class="mr-2" :src="titleIcon" />
							<h3 v-if="title" class="h3 text-bold">{{ title }}</h3>
						</div>
						<div class="modal-inner-content" :class="{ 'overflow-visible': overflow }">
							<div v-if="subheading" class="modal-subheading para-2">
								{{ subheading }}
							</div>
							<div>
								<slot></slot>
							</div>
						</div>
						<div class="d-flex flex-column gap v-gap mt-3 pt-1 action-buttons" v-if="action">
							<AppButton
								:name="`cta-button-modal-${id}`"
								:icon="actionIcon"
								:color="actionColor"
								:loading="loading"
								:disabled="disabled"
								@click="ctaClick"
								v-if="action"
								>{{ action }}</AppButton
							>
							<AppButton
								:name="`secondary-cta-button-modal-${id}`"
								class="p-0 h-auto"
								link
								:loading="loading"
								:disabled="disabled"
								:color="secondaryActionColor"
								@click="secondaryCtaClick"
								v-if="secondaryAction"
								>{{ secondaryAction }}</AppButton
							>
							<span v-if="footerNote.length" class="w-100 d-flex justify-content-center mt-3 para-4 text-light text-center">
								{{ footerNote }}
							</span>
						</div>
					</template>
				</div>
			</Transition>
		</div>
	</div>
</template>
<script>
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'AppModal',
		props: {
			id: {
				type: String,
				default: '',
			},
			transitionKey: {
				default: '',
			},
			subheading: {
				type: String,
				default: undefined,
			},
			action: {
				type: String,
				default: '',
			},
			actionIcon: {
				type: String,
				default: '',
			},
			actionColor: {
				type: String,
				default: 'primary',
			},
			secondaryAction: {
				type: String,
				default: '',
			},
			secondaryActionColor: {
				type: String,
				default: 'primary',
			},
			title: {
				type: String,
				default: '',
			},
			titleIcon: {
				type: String,
				default: '',
			},
			extraLarge: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			large: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			frame: {
				type: Boolean,
				default: false,
				description: 'Used to remove all formatting and offer empty canvas',
			},
			fade: {
				type: Boolean,
				default: true,
				description: 'Whether the modal should fade in/out',
			},
			position: {
				type: String,
				default: 'center',
			},
			loading: {
				type: Boolean,
				default: false,
				description: 'Used to have the button on the modal show loading hollow dot spinners',
			},
			disableAction: {
				type: Boolean,
				default: false,
				description: 'Used to disable the button on the modal',
			},
			isVideo: {
				type: Boolean,
				default: false,
				description: 'Used to indicate if the modal is used as a video player',
			},
			staticDataBackdrop: {
				type: Boolean,
				default: false,
				description: 'Used to prevent the modal from being closed when clicking outside.',
			},
			hideDataBackdrop: {
				type: Boolean,
				default: false,
				description: 'Used to hide the boostrap background',
			},
			hideClose: {
				type: Boolean,
				default: false,
				description: 'Used to hide the close button in case we want a non-dismissable modal.',
			},
			overflow: {
				type: Boolean,
				default: false,
				description: 'Used to determine if the modal should allow overflow.',
			},
			width: {
				type: Number,
				description: 'Sets the modal to a custom width',
			},
			footerNote: {
				type: String,
				description: 'Goes below action button for very small notes.',
				default: '',
			},
			fullscreenOnMobile: {
				type: Boolean,
				default: false,
				description: 'Makes the modal fullscreen on mobile with sticky header and footer.',
			},
		},
		data() {
			return {
				showing: false,
			}
		},
		computed: {
			sizeClass() {
				if (this.extraLarge) return 'modal-xl'
				if (this.large) return 'modal-lg'
				return ''
			},
			disabled() {
				return this.disableAction || this.loading
			},
			isMobile() {
				return commonHelper.isMobile()
			},
			dataBackgroundValue() {
				if (this.hideDataBackdrop) {
					return 'false'
				}
				if (this.staticDataBackdrop) {
					return 'static'
				}
				return 'true'
			},
			computedStyle() {
				if (this.width !== null) {
					return { width: `${this.width}px` }
				}

				return null
			},
		},
		watch: {
			showing(newVal) {
				if (newVal && this.isMobile) {
					document.body.classList.add('overflow-hidden')
				} else {
					document.body.classList.remove('overflow-hidden')
				}
			},
		},
		mounted() {
			$(this.$refs['custom-modal']).on('shown.bs.modal', () => {
				this.showing = true
				document.addEventListener('keydown', this.handleEscPressed)
				this.$emit('open')
			})
			$(this.$refs['custom-modal']).on('hidden.bs.modal', () => {
				this.showing = false
				document.removeEventListener('keydown', this.handleEscPressed)
				this.$emit('close')
			})
		},
		methods: {
			ctaClick() {
				this.$emit('cta')
			},
			secondaryCtaClick() {
				this.$emit('secondary-cta')
			},
			handleClickOutside(event) {
				// Only use this as a fallback if the modal background is not visible
				if (this.dataBackgroundValue !== 'false' || !this.$refs?.modalContent) {
					return
				}

				if (this.$refs?.modalContent.contains(event.target)) {
					// event.stopPropagation()
				} else {
					this.hide()
				}
			},
			handleEscPressed(event) {
				if (event.key === 'Escape') {
					this.hide()
				}
			},
			show() {
				$(this.$refs['custom-modal']).modal('show')
			},
			hide() {
				$(this.$refs['custom-modal']).modal('hide')
			},
		},
	}
</script>
<style lang="scss" scoped>
	// Modal structure
	.modal-block {
		overflow: auto;
		&.fullscreen-mobile {
			@media (max-width: 576px) {
				overflow: hidden;
			}
		}
	}

	.stan-modal-header {
		position: relative;
		height: 25px;
		.close-modal {
			display: flex;
			width: 28px;
			height: 28px;
			background-color: var(--stan-gray-light-color) !important;
			border-radius: 50%;
			cursor: pointer;
			z-index: 9;
			color: var(--stan-primary-primary-color);
			opacity: 1;
			&:hover {
				background-color: var(--stan-gray-soft-color) !important;
			}

			.close-icon {
				margin: auto;
			}
		}
	}

	.modal-open .modal {
		padding: 10px;
	}

	.modal-title {
		position: absolute;
	}

	.modal-dialog-centered {
		margin: 0 auto;

		&.fit-content {
			max-width: max-content;
		}
	}

	.modal-content {
		max-height: 90vh;
		border-radius: 1.25rem;
		border: none;
		&.framed {
			padding: 30px 25px;
		}
		.modal-heading {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.modal-inner-content {
		overflow: auto;

		.modal-subheading {
			width: 85%;
			text-align: center;
			margin: auto;
			margin-bottom: 10px;
			color: var(--stan-text-light-color);
		}
	}

	.modal-dialog-end {
		display: flex;
		align-items: end;
		min-height: calc(100%);
		margin: 0px;
	}

	.move-up {
		animation: animatebottom 0.5s;
	}

	@keyframes animatebottom {
		from {
			bottom: -400px;
			opacity: 1;
		}

		to {
			bottom: 0;
			opacity: 1;
		}
	}

	.is-video {
		.modal-content {
			border: none;
		}
	}

	.modal .modal-backdrop {
		color: red;
	}

	/* Fullscreen mobile styles */
	@media (max-width: 576px) {
		.fullscreen-mobile {
			padding: 0 !important;
		}

		.mobile-fullscreen {
			width: 100vw !important;
			max-width: none !important;
			height: 100vh !important;
			@supports (height: 100dvh) {
				height: 100dvh !important;
			}
			margin: 0 !important;
		}

		.mobile-fullscreen-content {
			height: 100vh !important;
			max-height: 100vh !important;
			@supports (height: 100dvh) {
				height: 100dvh !important;
				max-height: 100dvh !important;
			}
			border-radius: 0 !important;
			padding: 0 !important;
			overflow: hidden !important;
		}

		.modal-content-wrapper {
			display: flex !important;
			flex-direction: column !important;
			height: 100% !important;
			width: 100% !important;
		}

		.mobile-fullscreen-inner {
			flex: 1 !important;
			overflow-y: auto !important;
			overflow-x: hidden !important;
			padding: 0 15px !important;
			padding-top: 16px !important;
		}

		.sticky-header {
			position: sticky !important;
			top: 0 !important;
			z-index: 10 !important;
			background-color: white !important;
			padding: 15px !important;
			padding-block: 20px !important;
			margin-bottom: 0 !important;
			border-bottom: 1px solid var(--stan-primary-light-color);
			flex-shrink: 0 !important;
			justify-content: flex-start !important;
		}

		.sticky-footer {
			position: sticky !important;
			bottom: 0 !important;
			z-index: 10 !important;
			background-color: white !important;
			padding: 15px !important;
			border-top: 1px solid var(--stan-primary-light-color);
			flex-shrink: 0 !important;
			margin-top: 0px !important;
		}
	}
</style>
