// eslint-disable-next-line import/extensions
import store from '~/store'
import experiment from '~/plugins/experiments.js'
import commonHelper from '~/global_helper/helpers.js'

export default async (to, from, next) => {
	if (store.getters['Auth/check']) {
		const experimentConfig = {}
		let needUpdate = null
		store.dispatch('Auth/fetchUser').then(() => {
			const user = store.getters['Auth/user']
			// Experiment setup example
			// const experimentLaunchStoreButton = user.experiments?.launch_store_button
			// if (experimentLaunchStoreButton === undefined) {
			// 	experimentConfig.launch_store_button = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
			// 	needUpdate = true
			// }
			needUpdate = false

			const getMeOrientedV1Jan25 = user.experiments?.get_me_oriented_v1_jan_25
			if (getMeOrientedV1Jan25 === undefined) {
				experimentConfig.get_me_oriented_v1_jan_25 = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			const whereAreMySalesFrom = user.experiments?.where_are_my_sales_from_take2
			if (whereAreMySalesFrom === undefined) {
				experimentConfig.where_are_my_sales_from_take2 = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			const crmActionVisibility = user.experiments?.crm_action_visibility
			if (crmActionVisibility === undefined) {
				experimentConfig.crm_action_visibility = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			const analyticsMtdFilter = user.experiments?.analytics_mtd_filter
			if (analyticsMtdFilter === undefined) {
				experimentConfig.analytics_mtd_filter = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			const onboardingChecklistGoLive = user.experiments?.onboarding_checklist_go_live
			if (onboardingChecklistGoLive === undefined) {
				experimentConfig.onboarding_checklist_go_live = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			if (user.created_at >= 1740001428) {
				const namesOnProductsConsistent = user.experiments?.names_on_products_consistent
				if (namesOnProductsConsistent === undefined) {
					experimentConfig.names_on_products_consistent = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
					needUpdate = true
				}
			}

			const stanleyaiMarketingIdeas = user.experiments?.stanleyai_marketing_ideas
			if (stanleyaiMarketingIdeas === undefined) {
				experimentConfig.stanleyai_marketing_ideas = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			const stanleyaiProductRecommendation = user.experiments?.stanleyai_product_recommendation
			if (stanleyaiProductRecommendation === undefined) {
				experimentConfig.stanleyai_product_recommendation = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}
			const aiDigitalProductCreation = user.experiments?.ai_digital_product_creation
			if (aiDigitalProductCreation === undefined) {
				experimentConfig.ai_digital_product_creation = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
				needUpdate = true
			}

			// const cancellationTwoMonthDiscountOffer = user.experiments?.cancellation_two_month_discount_offer
			// if (cancellationTwoMonthDiscountOffer === undefined) {
			// 	experimentConfig.cancellation_two_month_discount_offer = commonHelper.isProbable(0.5) ? 'treatment' : 'control'
			// 	needUpdate = true
			// }

			if (needUpdate) {
				experiment(experimentConfig)
				store.dispatch('Auth/fetchUser')
			}
		})
	}
	next()
}
