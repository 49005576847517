var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-price overflow-hidden" }, [
    _vm.displaySaleAmount
      ? _c("span", { staticClass: "sale-amount" }, [
          _vm._v(_vm._s(_vm.formattedSaleAmount))
        ])
      : _vm._e(),
    _vm.displayAmount
      ? _c("span", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm.formattedAmount))
        ])
      : _vm._e(),
    _vm.displayInterval
      ? _c("span", { staticClass: "price-interval" }, [
          _vm._v("/" + _vm._s(_vm.formattedInterval))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }