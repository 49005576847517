<template>
	<div class="overflow-visible">
		<div v-if="showTitle" class="pb-2">
			<span class="text-bold text-dark h4">{{ $t('Tags') }}</span>
		</div>

		<div class="overflow-visible">
			<AppNewMultiSelect
				:placeholder="placeholder"
				:items="availableProductTags"
				:canAddNewItem="false"
				:disabled="disabled"
				:selectedItems="productTags"
				label="name"
				trackBy="name"
				@customSelectFunction="selectTag"
				@customRemoveFunction="removeTag"
			/>
		</div>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'

	export default {
		props: {
			showTitle: {
				type: Boolean,
				default: true,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			searchable: {
				type: Boolean,
				default: true,
			},
			includeAllProductsTag: {
				type: Boolean,
				default: true,
			},
			includeNewReferralTag: {
				type: Boolean,
				default: true,
			},
			onlyPaidProducts: {
				type: Boolean,
				default: false,
			},
		},

		mounted() {},
		data() {
			return {
				productTags: [],
				availableProductTags: [],
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Stores', ['getStore', 'getFunnels', 'getStoreLoading']),
			placeholder() {
				return 'Select or add a product'
			},
			isNewReferralTagAllowed() {
				return this.user.experiments?.email_flow_new_referral_tag === 1
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			emitProductTags() {
				this.$emit('tags-updated', this.productTags)
			},
			selectTag(tag) {
				this.handleAllProductsTag(tag)
				this.emitProductTags()
			},
			handleAllProductsTag(tag) {
				// there are 2 cases:
				// 1. A pseudo-tag is added, and other tags are present. We remove the other tags.
				if (tag.name === 'All Products' || tag.name === 'New Referral') {
					this.productTags.length = 0
					this.productTags.push(tag)
				}
				// 2. Another tag is added, and a pseudo-tag is present. We remove the pseudo-tags.
				else {
					let index = this.productTags.findIndex(tag => tag.name === 'All Products')
					if (index !== -1) {
						this.productTags.splice(index, 1)
					}
					index = this.productTags.findIndex(tag => tag.name === 'New Referral')
					if (index !== -1) {
						this.productTags.splice(index, 1)
					}
				}
			},
			removeTag() {
				this.emitProductTags()
			},
			fetchProductTagList() {
				if (this.includeAllProductsTag) {
					const allProductsTag = { name: 'All Products', page_id: 0, icon: 'https://assets.stanwith.me/graphics/device/stan-logo.svg' }
					this.availableProductTags.push(allProductsTag)
				}
				if (this.includeNewReferralTag && this.isNewReferralTagAllowed) {
					// new icon?
					// page_id = -1 is a quick hack for shipping EOD
					const newReferralTag = { name: 'New Referral', page_id: -1, icon: 'https://assets.stanwith.me/graphics/device/stan-logo.svg' }
					this.availableProductTags.push(newReferralTag)
				}
				const productList = this.$commonHelper.buildProductList(
					this.getStore,
					this.getFunnels,
					null,
					false,
					true,
					this.user,
					-1,
					false,
					this.onlyPaidProducts
				)
				productList.forEach(p => {
					this.availableProductTags.push({ name: p.label, page_id: p.id, icon: p.image })
				})
				this.$emit('product-tag-list-loaded')
			},
			forceInjectTags(pageIdList) {
				this.productTags = []
				pageIdList.forEach(id => {
					const tagArray = this.availableProductTags.filter(t => t.page_id === id)
					if (tagArray.length === 1) {
						this.productTags.push(tagArray[0])
					}
				})
				this.$emit('tags-updated', this.productTags)
			},
		},
		watch: {
			getStoreLoading: {
				immediate: true,
				handler(newVal) {
					if (!newVal) {
						this.fetchProductTagList()
					}
				},
			},
		},
	}
</script>

<style lang="scss">
	.exclude-tags-mode {
		.multiselect-tag {
			background-color: var(--stan-gray-primary-color) !important;
			color: var(--stan-text-light-color) !important;
			i:before {
				color: var(--stan-text-light-color) !important;
			}
		}
	}
</style>
