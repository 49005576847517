var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fan-details-form" },
    [
      _vm.showBase && _vm.showAddress
        ? _c("div", { staticClass: "form-header" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("shared.form.basic_information")))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showBase
        ? _c("div", { staticClass: "form-field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              staticClass: "input-bar",
              attrs: {
                id: "name",
                type: "text",
                placeholder: _vm.$t("shared.form.placeholder_enter_name"),
                disabled: _vm.disabled
              },
              domProps: { value: _vm.name },
              on: {
                blur: e => _vm.validateName(e),
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "danger-msg show-msg" }, [
                _vm._v(_vm._s(_vm.errors.name))
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showBase
        ? _c("div", { staticClass: "form-field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "input-bar",
              attrs: {
                id: "email",
                type: "email",
                required: "",
                placeholder: _vm.$t("shared.form.placeholder_enter_email"),
                disabled: _vm.disabled
              },
              domProps: { value: _vm.email },
              on: {
                blur: e => _vm.validateEmail(e),
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "danger-msg show-msg" }, [
                _vm._v(_vm._s(_vm.errors.email))
              ]),
              _vm._v(" "),
              _vm.warnings.email
                ? _c("span", { staticClass: "danger-msg show-msg" }, [
                    _vm._v(_vm._s(_vm.warnings.email))
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showMessage
        ? _c("div", { staticClass: "form-field" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.note,
                  expression: "note"
                }
              ],
              staticClass: "input-bar",
              attrs: {
                cols: "3",
                rows: "3",
                type: "text",
                placeholder: _vm.$t("shared.form.placeholder_optional_message"),
                disabled: _vm.disabled
              },
              domProps: { value: _vm.note },
              on: {
                blur: _vm.onNoteInputChange,
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.note = $event.target.value
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddress && _vm.questions && _vm.questions.length
        ? _c("div", { staticClass: "form-header" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("shared.form.questions_header")))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.questions, function(question, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  (question.type === "phone" && question.enabled !== false) ||
                  question.type !== "phone",
                expression:
                  "(question.type === 'phone' && question.enabled !== false) || question.type !== 'phone'"
              }
            ],
            key: `questions-${index}`,
            staticClass: "form-field"
          },
          [
            question.type === "phone" &&
            question.enabled !== false &&
            !_vm.isCachedAnswerExists(question.content, index)
              ? [
                  _c("TelephoneInputLazy", {
                    attrs: {
                      phone: _vm.getAutofillAnswer(question.content),
                      phoneCountryCode: _vm.getAutofillAnswer("countryCode"),
                      fcpComplete: _vm.fcpComplete,
                      debounce: 300,
                      disabled: _vm.disabled
                    },
                    on: {
                      "update-phone": phone =>
                        _vm.onPhoneInputChange(phone, index)
                    }
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "danger-msg show-msg" }, [
                      _vm._v(_vm._s(_vm.questionErrors[index]))
                    ])
                  ])
                ]
              : question.type === "text" &&
                !_vm.isCachedAnswerExists(question.content, index)
              ? [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.answers[index],
                        expression: "answers[index]"
                      }
                    ],
                    staticClass: "input-bar",
                    attrs: {
                      rows: _vm.textAreaRows(question.content),
                      id: `input-${index}`,
                      type: question.type,
                      placeholder: question.content,
                      disabled: _vm.disabled
                    },
                    domProps: { value: _vm.answers[index] },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.answers, index, $event.target.value)
                        },
                        _vm.setTextareaHeight
                      ],
                      blur: function($event) {
                        return _vm.onQuestionInputChange(index)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "danger-msg show-msg" }, [
                      _vm._v(_vm._s(_vm.questionErrors[index]))
                    ])
                  ])
                ]
              : question.type === "dropdown" &&
                !_vm.isCachedAnswerExists(question.content, index)
              ? [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(question.content))
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-label" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.answers[index],
                            expression: "answers[index]"
                          }
                        ],
                        staticClass: "input-bar",
                        class: { selected: !!_vm.answers[index] },
                        attrs: { id: `input-${index}`, disabled: _vm.disabled },
                        on: {
                          blur: function($event) {
                            return _vm.onQuestionInputChange(index)
                          },
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.answers,
                              index,
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          { attrs: { disabled: "", selected: "", value: "" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("shared.form.select_an_option"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(question.options, function(option) {
                          return _c(
                            "option",
                            { domProps: { value: option.value } },
                            [_vm._v(_vm._s(option.value))]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        staticClass: "chevron",
                        staticStyle: { "pointer-events": "none" },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 11.95 11",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M7.05852 9.5L2.94138 5L7.05852 0.5",
                            fill: "none",
                            "stroke-width": "1"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "danger-msg show-msg" }, [
                      _vm._v(_vm._s(_vm.questionErrors[index]))
                    ])
                  ])
                ]
              : !_vm.isCachedAnswerExists(question.content, index)
              ? [
                  question.options && question.options.length
                    ? [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(question.content))
                        ]),
                        _vm._v(" "),
                        _vm._l(question.options, function(option, optionIndex) {
                          return _c("label", { staticClass: "input-bar" }, [
                            question.type === "checkbox"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.answers[index],
                                      expression: "answers[index]"
                                    }
                                  ],
                                  attrs: {
                                    id: `input-${index}-${optionIndex}`,
                                    disabled: _vm.disabled,
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    value: option.value,
                                    checked: Array.isArray(_vm.answers[index])
                                      ? _vm._i(
                                          _vm.answers[index],
                                          option.value
                                        ) > -1
                                      : _vm.answers[index]
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.onQuestionInputChange(index)
                                    },
                                    change: function($event) {
                                      var $$a = _vm.answers[index],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = option.value,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.answers,
                                              index,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.answers,
                                              index,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.answers, index, $$c)
                                      }
                                    }
                                  }
                                })
                              : question.type === "radio"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.answers[index],
                                      expression: "answers[index]"
                                    }
                                  ],
                                  attrs: {
                                    id: `input-${index}-${optionIndex}`,
                                    disabled: _vm.disabled,
                                    type: "radio"
                                  },
                                  domProps: {
                                    value: option.value,
                                    checked: _vm._q(
                                      _vm.answers[index],
                                      option.value
                                    )
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.onQuestionInputChange(index)
                                    },
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.answers,
                                        index,
                                        option.value
                                      )
                                    }
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.answers[index],
                                      expression: "answers[index]"
                                    }
                                  ],
                                  attrs: {
                                    id: `input-${index}-${optionIndex}`,
                                    disabled: _vm.disabled,
                                    type: question.type
                                  },
                                  domProps: {
                                    value: option.value,
                                    value: _vm.answers[index]
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.onQuestionInputChange(index)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.answers,
                                        index,
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(option.value) +
                                "\n\t\t\t\t"
                            )
                          ])
                        })
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", [
                    _c("span", { staticClass: "danger-msg show-msg" }, [
                      _vm._v(_vm._s(_vm.questionErrors[index]))
                    ])
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm.showAddress
        ? _c("div", { staticClass: "form-header" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("shared.form.address_header")))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAddress && !_vm.showManualAddressEdit,
              expression: "showAddress && !showManualAddressEdit"
            }
          ],
          staticClass: "form-field mt-0"
        },
        [
          _c("div", { staticClass: "input-bar google-address-autcomplete" }, [
            _c("input", {
              ref: "autocomplete",
              staticClass: "google-address-autcomplete-input",
              attrs: {
                id: "address-auto-complete",
                type: "text",
                placeholder: _vm.$t("shared.form.placeholder_street_address"),
                disabled: _vm.addressSelected
              },
              on: { blur: e => _vm.validateAddress(e) }
            }),
            _vm._v(" "),
            _vm.addressSelected || _vm.showEditButton
              ? _c("button", { on: { click: _vm.editAddress } }, [
                  _vm._v(_vm._s(_vm.$t("shared.form.edit")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("p", [
            _c("span", { staticClass: "danger-msg show-msg" }, [
              _vm._v(_vm._s(_vm.errors.address))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showAddress && _vm.showManualAddressEdit,
              expression: "showAddress && showManualAddressEdit"
            }
          ],
          staticClass: "address-expanded"
        },
        [
          _c("div", { staticClass: "form-field mt-0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.address.street,
                  expression: "address.street"
                }
              ],
              ref: "autocomplete",
              staticClass: "input-bar",
              attrs: {
                id: "address-auto-complete",
                type: "text",
                placeholder: _vm.$t("shared.form.placeholder_street"),
                disabled: _vm.disabled
              },
              domProps: { value: _vm.address.street },
              on: {
                blur: e => _vm.validateAddress(e),
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.address, "street", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "danger-msg show-msg" }, [
                _vm._v(_vm._s(_vm.errors.address))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-field d-flex gap" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.address.city,
                  expression: "address.city"
                }
              ],
              staticClass: "input-bar",
              attrs: {
                type: "text",
                placeholder: _vm.$t("shared.form.placeholder_city"),
                disabled: _vm.disabled
              },
              domProps: { value: _vm.address.city },
              on: {
                blur: e => _vm.validateAddress(e),
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.address, "city", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                key: _vm.provice_updated,
                staticClass: "form-label tax-manual-state mb-0"
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.address.state,
                        expression: "address.state"
                      }
                    ],
                    staticClass: "input-bar",
                    class: {
                      selected: !!_vm.list_provinces[_vm.address.state]
                    },
                    attrs: { disabled: _vm.disabled },
                    on: {
                      blur: e => _vm.validateAddress(e),
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.address,
                          "state",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "", selected: "", value: "" } },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("shared.form.address_province_header"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.list_provinces, function(option) {
                      return _c(
                        "option",
                        { domProps: { value: option.value } },
                        [_vm._v(_vm._s(option.name))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "chevron",
                    staticStyle: { "pointer-events": "none" },
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 11.95 11",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M7.05852 9.5L2.94138 5L7.05852 0.5",
                        fill: "none",
                        "stroke-width": "1"
                      }
                    })
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-field d-flex gap" }, [
            _c("label", { staticClass: "form-label tax-manual-country mb-0" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address.country,
                      expression: "address.country"
                    }
                  ],
                  staticClass: "input-bar",
                  class: {
                    selected: !!_vm.list_countries[_vm.address.country]
                  },
                  attrs: { disabled: _vm.disabled },
                  on: {
                    blur: e => _vm.validateAddress(e),
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.address,
                        "country",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { disabled: "", selected: "", value: "" } },
                    [_vm._v(_vm._s(_vm.$t("shared.form.country_header")))]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.list_countries, function(option) {
                    return _c("option", { domProps: { value: option.value } }, [
                      _vm._v(_vm._s(option.name))
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "svg",
                {
                  staticClass: "chevron",
                  staticStyle: { "pointer-events": "none" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 11.95 11",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M7.05852 9.5L2.94138 5L7.05852 0.5",
                      fill: "none",
                      "stroke-width": "1"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.address.postal_code,
                  expression: "address.postal_code"
                }
              ],
              staticClass: "input-bar",
              attrs: {
                type: "text",
                placeholder: _vm.$t("shared.form.placeholder_postal_code"),
                disabled: _vm.disabled
              },
              domProps: { value: _vm.address.postal_code },
              on: {
                blur: e => _vm.validateAddress(e),
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.address, "postal_code", $event.target.value)
                }
              }
            })
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }