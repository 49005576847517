var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "d-flex align-items-center",
      attrs: { "aria-label": "breadcrumb transparent" }
    },
    [
      _vm.showBackButton
        ? _c("InlineSvg", {
            staticClass: "mr-2 cursor-pointer back-button",
            attrs: { src: "/images/ic-left-arrow.svg" },
            on: { click: _vm.goBack }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ol",
        {
          staticClass:
            "breadcrumb transparent mb-0 px-0 d-lg-inline-flex d-none"
        },
        _vm._l(_vm.filteredBreadcrumbs, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "breadcrumb-item h2 text-bold",
              class: { active: item.active },
              attrs: { "aria-current": "page" }
            },
            [
              item.to
                ? _c(
                    "a",
                    {
                      staticClass: "p-0 m-0",
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "activeTab",
                            item.to,
                            item.query,
                            item.params
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.title))]
                  )
                : _c("span", [_vm._v(_vm._s(item.title))])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "breadcrumb transparent mb-0 px-0 d-lg-none d-inline-flex"
        },
        [
          _c(
            "div",
            {
              staticClass: "h3 text-bold",
              class: { active: _vm.currentPage.active },
              attrs: { "aria-current": "page" }
            },
            [
              _vm.currentPage.to
                ? _c(
                    "a",
                    {
                      staticClass: "p-0 m-0",
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "activeTab",
                            _vm.currentPage.to,
                            _vm.currentPage.query,
                            _vm.currentPage.params
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.currentPage.title))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.currentPage.title))])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }