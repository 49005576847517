<template>
	<div>
		<AppModal id="start-free-trial-affiliate-only" ref="start-free-trial-affiliate-only" title="Save $100s with Stan as your All-In-One Store">
			<div class="trial-disclaimer mt-0">
				<div class="para-2 text-dark text-bold mb-2">
					You won't be charged for your free trial!
				</div>
				<div class="para-3 text-light">
					We ask for your credit card information for a seamless subscription experience, but you can cancel anytime and we’ll send you a
					heads-up email in advance.
				</div>
			</div>
			<div class="subcription-form">
				<SubscriptionForm
					ref="subscription-form"
					:trial-days="trialDays"
					:price-id="subscriptionPriceId"
					:promo-id="promoId"
					:credit-card-required="creditCardRequired"
					:mustApplyTrial="true"
					@loading="loading = true"
					@loaded="loading = false"
					@success="onCCSuccess"
				/>
				<div class="my-4">
					<AppCheckbox v-model="termsAgree">
						I acknowledge that I have read and understand the
						<a href="https://assets.stanwith.me/legal/terms-of-service.pdf" target="_new">Terms of Service</a> and the
						<a href="https://assets.stanwith.me/legal/privacy-policy.pdf">Privacy Policy</a>.
					</AppCheckbox>
				</div>
			</div>
			<div style="margin:20px auto 20px auto">
				<AppButton name="subscribe-button" size="large" class="w-100" :loading="loading" @click="submit" :disabled="!termsAgree">
					<span class="mx-2">Start Your {{ trialDays }} Day Free Trial</span>
				</AppButton>
			</div>
		</AppModal>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex'
	import SubscriptionForm from './components/SubscriptionForm'

	export default {
		name: 'StartFreeTrial',
		components: {
			SubscriptionForm,
		},
		middleware: ['auth', 'experiment-setup'],
		props: {
			isModal: { type: Boolean, default: false },
			isAffiliateTrial: { type: Boolean, default: false },
		},
		data() {
			return {
				loading: false,
				has_subscription_history: true,
				trialDays: 14,
				subscriptionPriceId: undefined,
				promo: undefined,
				showDiscountCode: false,
				creditCardRequired: true,
				termsAgree: false,
			}
		},
		mounted() {
			this.has_subscription_history = this.user.has_subscription_history
			this.subscriptionPriceId = this.creatorMonthlyPriceId
			this.trialDays = 14
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			async logout() {
				$('body').removeClass('menu-active')
				clearInterval(this.t)
				await this.$store.dispatch('Auth/logout')
				this.$router.push({ name: 'login_page' }).catch(() => {})
			},
			submit() {
				if (this.termsAgree) {
					this.$stanAnalytics('subscription-creation-terms-agreed', {
						meta: { user_id: this.user.user_id, username: this.user.username },
					})
				}
				this.$refs['subscription-form'].checkCreatePaymentMethodAndSubscribe()
			},
			preventBackButton(url) {
				for (let i = 0; i < 10; i++) {
					window.history.pushState({}, i, url)
				}
			},
			onCCSuccess() {
				this.$emit('subscribed')
				$('#start-free-trial-affiliate-only').modal('hide')
				this.preventBackButton({ name: 'onboarding' })
				this.$router.push({ name: 'onboarding' })

				// Clearing the cookies to reset the page.
				this.$cookies.set('page', '')
				this.$cookies.set('page', '', '1d', null, 'stanwith.me')
				this.$cookies.set('page', '', '1d', null, 'stan.store')
			},
			displayDiscountCode() {
				this.showDiscountCode = true
			},
		},
		computed: {
			...mapGetters('Auth', ['user', 'userCountryCode', 'hasActiveSubscription', 'getSubscribeFlags']),
			...mapGetters('PlatformSubscriptions', ['creatorMonthlyPriceId']),
			promoAvailable() {
				return this.getSubscribeFlags.show_promo
			},
			discountCode() {
				return this.getSubscribeFlags.discount_code
			},
			promoId() {
				return this.promo?.id
			},
			coupon() {
				return this.promo?.coupon
			},
			hidePlatformDiscountPercentExperiment() {
				return this.user?.experiments?.hide_platform_discount_percent
			},
		},
		watch: {
			promoId(newVal) {
				if (newVal && this.discountCode) this.displayDiscountCode()
			},
		},
	}
</script>
<style lang="scss" scoped>
	.trial-disclaimer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
		gap: 10px;

		background: #f9f9ff;
		border-radius: 15px;
		margin-top: 20px;
	}
</style>
