<template>
	<div class="accordion-container">
		<div v-if="useColumnHeaders" class="column-headers" :style="{ gridTemplateColumns: `3.5fr repeat(${columnData.length}, 1fr)` }">
			<div class="header para-2 px-0">{{ mainColumnHeader }}</div>

			<div v-for="(column, index) in columnData" :key="index" class="header para-2 px-0">
				<AppTooltip class="d-inline-flex" :click="!!column.helperText" :tip="column.helperText">
					{{ column.name }} <InlineSvg v-if="column.helperText" src="/images/icons/ic-info-filled-gray.svg" />
				</AppTooltip>
			</div>
		</div>
		<div class="accordion-item card" :class="{ 'no-header': !showHeader, 'has-actions': !!actions }" ref="wrapper">
			<div
				class="card-header"
				:id="heading"
				data-toggle="collapse"
				:data-target="dataTarget"
				aria-expanded="true"
				:aria-controls="collapseId"
				:class="{ collapsed: !visible }"
				v-if="showHeader"
				@change="$emit('changed', name)"
			>
				<div class="card-header-content-wrapper">
					<div class="main-row">
						<div class="d-grid flex-grow-1 grid-col-wrapper" :style="{ gridTemplateColumns: `minmax(0, 3.5fr) ${columnData.length}fr` }">
							<div class="title-and-icon">
								<div class="accordion-icon" :class="{ error }" v-if="image">
									<InlineSvg :src="image" alt="" />
								</div>
								<div class="accordion-title-and-subtitle-wrapper" :class="{ 'no-padding': !image }">
									<div class="accordion-title-and-labels">
										<span class="h3 text-truncate text-bold" :class="{ titleClass, error }"> {{ title }} </span>

										<div v-if="titleLabels.length > 0" class="accordion-title-label-wrapper accordion-actions-wrapper">
											<template v-for="action in titleLabels">
												<span
													class="col-auto px-0"
													:key="action.key"
													:class="action.customClass"
													v-if="action.type === 'tag' && action.visible"
													@click.stop="$emit('action-trigger', action)"
												>
													<AppTag
														:label="action.label"
														:backgroundColor="action.backgroundColor"
														:textColor="action.textColor"
														:image="action.image"
														:svgFill="action.svgFill"
														:fontWeight="400"
													/>
												</span>
											</template>
										</div>
									</div>
									<slot name="subtitle"></slot>
								</div>
							</div>

							<div
								v-if="columnData.length > 0"
								class="d-grid accordion-column-data"
								:style="{ gridTemplateColumns: `repeat(${columnData.length}, 1fr)` }"
							>
								<template v-for="(column, index) in columnData">
									<div :key="`accordion-column-${index}`" class="text-bold column d-flex align-items-center">
										<CustomHollowDotsSpinner
											v-if="column.value === null"
											:animation-duration="1000"
											:dot-size="5"
											color="var(--stan-primary-primary-color)"
											class="loader align-items-center justify-content-start flex-1 m-0 min-width-0"
										/>
										<div v-if="column.isHtml" v-html="column.value || ''"></div>
										<span v-else>{{ column.value }}</span>
									</div>
								</template>
							</div>
						</div>

						<div class="accordion-actions-wrapper" style="min-width: 56px;">
							<template v-for="action in actions">
								<span
									class="col-auto px-0"
									:key="action.key"
									:class="action.customClass"
									v-if="action.type === 'tag' && action.visible"
									@click.stop="$emit('action-trigger', action)"
								>
									<AppTag
										:label="action.label"
										:backgroundColor="action.backgroundColor"
										:textColor="action.textColor"
										:image="action.image"
									/>
								</span>
								<span
									:key="action.key"
									class="accordion-icon d-flex align-items-center"
									:class="action.class"
									v-else-if="action.visible"
									@click.stop="$emit('action-trigger', action)"
								>
									<InlineSvg :src="action.icon" />
								</span>
							</template>
							<div class="accordion-icon" v-if="dropdown">
								<AppDropdownMenu class="px-1" :options="dropdown" @selected="dropdownSelected" />
							</div>
						</div>

						<span class="accordion-icon indicator" v-if="showAccordionArrow">
							<img src="/images/icons/ic-chevron-down.svg" />
						</span>
					</div>

					<div v-if="columnData.length > 0" class="accordion-column-data-mobile">
						<div class="hidden-state">
							<template v-for="(column, index) in columnData">
								<div :key="`accordion-column-${index}`" class="column">
									<InlineSvg v-if="column.icon" :src="column.icon" alt="" />
									<CustomHollowDotsSpinner
										v-if="column.value === null"
										:animation-duration="1000"
										:dot-size="5"
										color="var(--stan-primary-primary-color)"
										class="loader align-items-center m-0 justify-content-start min-width-0"
									/>
									<div class="para-2" v-else>
										<div v-if="column.isHtml" v-html="column.value || ''"></div>
										<span v-else>{{ column.value }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div :id="collapseId" class="collapse" :class="{ show: visible }" :aria-labelledby="heading" :data-parent="dataParent">
				<div class="card-body" :class="[bodyClass, { 'no-border px-0 pb-0': isWrapper }]">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CustomHollowDotsSpinner from '~/stan-vue-shared/components/HollowDotsSpinner'

	export default {
		name: 'AppAccordionItem',
		components: { CustomHollowDotsSpinner },
		props: {
			name: { type: String, default: '' },
			showHeader: { type: Boolean, default: () => true },
			visible: { type: Boolean, default: false },
			parent: { type: String, default: '' },
			title: { type: String, default: '' },
			image: { type: String, default: '' },
			titleLabels: { type: Array, default: () => [] },
			columnData: { type: Array, default: () => [] },
			actions: { type: Array, default: () => [] },
			isWrapper: { type: Boolean, default: false },
			showAccordionArrow: { type: Boolean, default: true },
			dropdown: { type: Array, default: () => undefined },
			bodyClass: { type: String, default: '' },
			titleClass: { type: String, default: '' },
			error: { type: Boolean, default: false },
			useColumnHeaders: { type: Boolean, default: false },
			mainColumnHeader: { type: String, default: 'Name' },
		},
		mounted() {
			$(this.dataTarget).on('shown.bs.collapse', this.triggerShown)
			$(this.dataTarget).on('.collapse', this.triggerShown)
			$(this.dataTarget).on('hidden.bs.collapse', this.triggerHidden)

			$(this.dataTarget).on('focusin', this.triggerFocusIn)
			$(this.dataTarget).on('focusout', this.triggerFocusOut)

			$(this.$refs.wrapper).on('mouseup', this.triggerMouseUp)
			$(this.$refs.wrapper).on('mousedown', this.triggerMouseDown)
			$(this.$refs.wrapper).on('click', this.triggerClick)
		},
		destroyed() {
			$(this.dataTarget).off('shown.bs.collapse', this.triggerShown)
			$(this.dataTarget).off('hidden.bs.collapse', this.triggerHidden)
			$(this.dataTarget).off('.collapse', this.triggerShown)

			$(this.dataTarget).off('focusin', this.triggerFocusIn)
			$(this.dataTarget).off('focusout', this.triggerFocusOut)

			$(this.$refs.wrapper).off('mouseup', this.triggerMouseUp)
			$(this.$refs.wrapper).off('mousedown', this.triggerMouseDown)
			$(this.$refs.wrapper).off('click', this.triggerClick)
		},
		computed: {
			collapseId() {
				return `collapse${this.name}`
			},
			heading() {
				return `heading${this.name}`
			},
			dataParent() {
				return this.parent ? `#accordion-${this.parent}` : ''
			},
			dataTarget() {
				return `#${this.collapseId}`
			},
		},
		methods: {
			triggerShown(event) {
				this.expanded = true
				this.$emit('shown', event)
			},
			triggerHidden(event) {
				this.expanded = false
				this.$emit('hidden', event)
			},
			triggerFocusIn(event) {
				this.$emit('focusin', event)
			},
			triggerFocusOut(event) {
				this.$emit('focusout', event)
			},
			triggerMouseUp(event) {
				this.$emit('mouseup', event)
			},
			triggerMouseDown(event) {
				this.$emit('mousedown', event)
			},
			triggerClick(event) {
				if (!this.expanded) {
					this.expanded = !this.expanded
				}
				this.$emit('click', event)
			},
			dropdownSelected(event) {
				this.$emit('selected', event)
			},
		},
		data() {
			return {
				expanded: false,
			}
		},
	}
</script>

<style lang="scss" scoped>
	%flex-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.accordion-item {
		&.card,
		&.card:not(:last-of-type),
		&.card:not(:first-of-type) {
			box-shadow: var(--stan-box-shadow);
			background: var(--stan-white);
			overflow: hidden;
			border-radius: 13px;
			border: none;
			margin-bottom: 25px;
			&.has-actions {
				overflow: visible;
			}

			.card-header {
				padding: 0;
				margin-bottom: -1px;
				border-bottom: none;
				background: transparent;
				user-select: none;

				:hover {
					cursor: pointer;
				}
				.card-header-content-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 0.5rem;
					padding: 1.5625rem 1.25rem;
				}
			}
			.card-body {
				padding: 0 1.25rem 1.5rem;
			}
			&.border-less {
				&,
				.card-header,
				.card-body {
					border: none !important;
				}
			}
		}
		.indicator {
			transition: all linear 0.2s;
			transform: rotate(180deg);
		}
		.collapsed {
			.indicator {
				transform: rotate(360deg);
			}
		}
		&.no-header {
			.card-body {
				padding-top: 1.5rem;
			}
		}
	}
	.accordion-title-and-subtitle-wrapper {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		min-width: 0;
		padding-left: 1rem;
		padding-right: 1rem;

		&.no-padding {
			padding-left: 0;
			padding-right: 0;
		}
		@media (min-width: 675px) {
			flex-direction: column;
			align-items: flex-start;

			.accordion-subtitle {
				margin-top: 0.5rem;
				margin-left: 0;
			}
		}
	}
	.accordion-title-and-labels {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		width: 100%;
		min-width: 0;
		overflow: hidden;

		.error {
			color: var(--stan-danger);
		}

		.h3.text-truncate {
			min-width: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
		}

		.accordion-title-label-wrapper {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			margin-left: 0.5rem;
		}
	}
	.accordion-subtitle {
		font-size: 12px;
		color: var(--stan-text-light-color);
	}
	.accordion-column-data {
		@media (max-width: 674px) {
			display: none !important;
		}
	}

	.accordion-column-data-mobile {
		display: flex;
		flex-basis: 100%;
		flex-grow: 1;
		max-width: 100%;
		min-width: 0;

		.hidden-state {
			display: flex;
			column-gap: 24px;
			row-gap: 12px;
			flex-wrap: wrap;

			.column {
				display: flex;
				align-items: center;
				gap: 4px;

				svg::v-deep {
					width: 16px;
					height: 16px;
					path {
						stroke: #4a568b;
					}
				}

				div {
					color: #4a568b;
				}
			}
		}

		.expanded-state {
			display: flex;
			gap: 24px;

			.column {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				gap: 4px;

				div {
					color: #4a568b;
				}
			}
		}

		@media (min-width: 675px) {
			display: none !important;
		}
	}

	.title-and-icon {
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}

	.accordion-icon {
		display: flex;
		align-items: center;
		@extend %flex-auto;
		img,
		svg::v-deep {
			width: 24px;
			height: 24px;
			path:not(.no-fill),
			rect:not(.no-fill) {
				fill: var(--stan-text-dark-color);
			}
		}
		&.error::v-deep,
		&.ai-danger::v-deep {
			color: var(--stan-danger);
			svg,
			svg path {
				fill: var(--stan-danger);
			}
		}
		& + .accordion-title-and-subtitle-wrapper {
			padding: 0;
		}
	}
	.accordion-title-label-wrapper {
		margin-left: 1rem;
		@media (max-width: 674px) {
			margin-left: 0.5rem;
		}
	}
	.accordion-actions-wrapper {
		display: flex;
		align-items: center;
		justify-content: end;
		gap: 4px;
		@extend %flex-auto;
	}
	.overflow-visible {
		overflow: visible !important;
	}
	.min-width-0 {
		min-width: 0;
	}

	.column-headers {
		display: grid;
		padding-right: 74px;
		padding-left: 1.25rem;
		padding-bottom: 8px;
		@media (max-width: 674px) {
			display: none !important;
		}

		.header {
			color: var(--stan-text-light-color);
			font-family: 'Inter', sans;
			font-weight: 400;
			line-height: 1.125rem;
			padding-inline: 5px;
		}
	}
	.d-grid {
		display: grid;
	}
	.main-row {
		display: flex;
		width: 100%;
	}
	.grid-col-wrapper {
		@media (max-width: 674px) {
			grid-template-columns: minmax(0, 3.5fr) !important;
		}
	}
	.disabled-icon {
		pointer-events: none;
		opacity: 0.5;
	}
</style>
