/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import axios from 'axios'
import constants from '~/global_helper/constants.js'

// state
const DEFAULT_COUNTRY = 'US'
export const state = {
	loadingPromise: null,
	isLoading: false,
	data: null,
	platformCountry: undefined,
}

export const getters = {
	initalized: state => !!state.data,
	isLoading: state => state.isLoading,
	platformCountry: state => state.platformCountry || DEFAULT_COUNTRY,
	data(state, getters) {
		if (state.isLoading || !state.data) {
			return null
		}
		const country = state.data?.[getters.platformCountry] ? getters.platformCountry : DEFAULT_COUNTRY
		return state.data[country]
	},
	platformCurrency(state, getters) {
		return getters.data?.currency
	},
	creatorMonthlyPrice(state, getters) {
		return getters.data?.prices?.creator?.month?.amount
	},
	creatorMonthlyAnnualizedPrice(state, getters) {
		// US has special pricing for the `localized pricing experiment`
		if (state.platformCountry === DEFAULT_COUNTRY) {
			return 360
		}
		return getters.data?.prices?.creator?.month?.amount * 12
	},
	creatorAnnualPrice(state, getters) {
		return getters.data?.prices?.creator?.year?.amount
	},
	creatorProMonthlyPrice(state, getters) {
		return getters.data?.prices?.[constants.TIER.CREATOR_PRO]?.month?.amount
	},
	creatorProMonthlyAnnualizedPrice(state, getters) {
		// US has special pricing for the `localized pricing experiment`
		if (state.platformCountry === DEFAULT_COUNTRY) {
			return 1137.6
		}
		return getters.data?.prices?.[constants.TIER.CREATOR_PRO]?.month?.amount * 12
	},
	creatorProAnnualPrice(state, getters) {
		return getters.data?.prices?.[constants.TIER.CREATOR_PRO]?.year?.amount
	},
	creatorMonthlyPriceId(state, getters) {
		return getters.data?.prices?.creator?.month?.stripe_id
	},
	creatorAnnualPriceId(state, getters) {
		return getters.data?.prices?.creator?.year?.stripe_id
	},
	creatorProMonthlyPriceId(state, getters) {
		return getters.data?.prices?.[constants.TIER.CREATOR_PRO]?.month?.stripe_id
	},
	creatorProAnnualPriceId(state, getters) {
		return getters.data?.prices?.[constants.TIER.CREATOR_PRO]?.year?.stripe_id
	},
}

export const mutations = {
	setData(state, payload) {
		state.data = payload
	},
	setLoadingPromise(state, promise) {
		state.loadingPromise = promise
	},
	setLoading(state, value) {
		state.isLoading = value
	},
	clearLoadingPromise(state) {
		state.loadingPromise = null
	},
	setPlatformCountry(state, country) {
		state.platformCountry = country
	},
}

export const actions = {
	fetchPlatformSubscriptionPrices({ state, commit }) {
		// This data is not likely to change, no need to fetch once loaded in
		if (state.data) {
			return state.data
		}

		// To avoid double fetching
		if (state.loadingPromise) {
			return state.loadingPromise
		}

		commit('setLoading', true)
		const promise = new Promise((resolve, reject) => {
			axios
				.get('v1/platform_subscriptions/prices')
				.then(response => {
					commit('setData', response.data)
					resolve(response)
				})
				.catch(error => {
					console.error(error)
					reject(error)
				})
				.finally(() => {
					commit('setLoading', false)
				})
		})

		commit('setLoadingPromise', promise)
		return promise
	},
	setPlatformCountry({ commit }, country) {
		commit('setPlatformCountry', country)
	},
}
