<template>
	<div class="pagination-control">
		<div class="button-group">
			<button
				class="pagination-arrow"
				:class="{ 'pagination-arrow-disabled': isLeftDisabled }"
				:disabled="isLeftDisabled"
				@click="onLeftClick"
				aria-label="Previous item"
			>
				<InlineSvg src="images/icons/ic-chevron-left.svg" />
			</button>
			<button
				class="pagination-arrow"
				:class="{ 'pagination-arrow-disabled': isRightDisabled }"
				:disabled="isRightDisabled"
				@click="onRightClick"
				aria-label="Next item"
			>
				<InlineSvg src="images/icons/ic-chevron-right.svg" />
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PaginationControl',
		props: {
			label: {
				type: String,
				default: 'Item',
			},
			current: {
				type: Number,
				default: 0,
				validator: value => value >= 0,
			},
			total: {
				type: Number,
				default: 0,
				validator: value => value >= 0,
			},
		},
		computed: {
			isLeftDisabled() {
				return this.current <= 0
			},
			isRightDisabled() {
				return this.current >= this.total - 1
			},
		},
		methods: {
			onLeftClick() {
				if (!this.isLeftDisabled) {
					this.$emit('click', this.current - 1)
				}
			},
			onRightClick() {
				if (!this.isRightDisabled) {
					this.$emit('click', this.current + 1)
				}
			},
		},
	}
</script>

<style scoped>
	.pagination-control {
		display: flex;
		gap: 24px;
		align-items: center;
	}

	.pagination-counter {
		display: inline-block;
		min-width: 5ch;
		text-align: right;
	}

	.pagination-arrow {
		display: flex;
		padding: 7.273px;
		justify-content: center;
		align-items: center;
		border-radius: 36.655px;
		background: var(--stan-gray-soft-color);
		width: 40px;
		height: 40px;
		flex-shrink: 0;
		border: none;
	}

	.pagination-arrow ::v-deep svg path {
		fill: var(--stan-primary-primary-color);
	}

	.pagination-arrow-disabled {
		opacity: 0.5;
	}

	.button-group {
		display: flex;
		gap: 6px;
		flex-shrink: 0;
	}
</style>
