var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      ref: "modal",
      attrs: {
        action: "Confirm",
        "secondary-action": "Cancel",
        title: "Edit flow",
        loading: _vm.loading,
        fullscreenOnMobile: "",
        hideClose: "",
        large: ""
      },
      on: { cta: _vm.submitModalForm, "secondary-cta": _vm.hide }
    },
    [
      _c(
        "div",
        { key: _vm.flowId, staticClass: "edit-flow-content" },
        [
          _c("div", { staticClass: "h3 text-bold" }, [_vm._v("Details")]),
          _vm._v(" "),
          _c("AppInput", {
            attrs: {
              placeholder: "Name Your Flow",
              label: "Flow title",
              disabled: _vm.updatesDisabled
            },
            model: {
              value: _vm.flowName,
              callback: function($$v) {
                _vm.flowName = $$v
              },
              expression: "flowName"
            }
          }),
          _vm._v(" "),
          !_vm.isDateTriggerActive
            ? _c("div", { staticClass: "trigger-purchase-flow" }, [
                _c(
                  "div",
                  {
                    class: {
                      "form-error":
                        _vm.$v.selectedProducts.$dirty &&
                        _vm.$v.selectedProducts.$invalid
                    }
                  },
                  [
                    _c("label", { staticClass: "control-label mr-1" }, [
                      _vm._v("Product")
                    ]),
                    _vm._v(" "),
                    _c("EditProductTags", {
                      key: _vm.editProductTagsKey,
                      ref: "editProductTags",
                      attrs: {
                        showTitle: false,
                        disabled: false,
                        searchable: true,
                        onlyPaidProducts: _vm.isCartAbandonedTriggerActive
                      },
                      on: {
                        "product-tag-list-loaded": _vm.populateProductTags,
                        "tags-updated": _vm.productTagsUpdated
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isCartAbandonedTriggerActive
                  ? _c(
                      "div",
                      { staticClass: "d-flex my-2 align-items-center" },
                      [
                        _c("AppToggle", {
                          staticClass: "mr-2",
                          staticStyle: {
                            transform: "translateY(-1px)",
                            "min-width": "30px"
                          },
                          model: {
                            value: _vm.showRemoveRecipients,
                            callback: function($$v) {
                              _vm.showRemoveRecipients = $$v
                            },
                            expression: "showRemoveRecipients"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticClass: "control-label mr-1 mb-0" },
                          [
                            _vm._v(
                              "Remove customer from flow after product purchase"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.showRemoveRecipients &&
                          !_vm.isCartAbandonedTriggerActive,
                        expression:
                          "showRemoveRecipients && !isCartAbandonedTriggerActive"
                      }
                    ],
                    staticClass: "trigger-purchase-flow mb-3"
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          "form-error":
                            _vm.$v.selectedProducts.$dirty &&
                            _vm.$v.selectedProducts.$invalid
                        }
                      },
                      [
                        _c("EditProductTags", {
                          key: _vm.editExcludeTagsKey,
                          ref: "editExcludeTags",
                          attrs: {
                            showTitle: false,
                            disabled: false,
                            searchable: true,
                            includeAllProductsTag: false,
                            includeNewReferralTag: false
                          },
                          on: {
                            "product-tag-list-loaded": _vm.populateExcludeTags,
                            "tags-updated": _vm.excludeTagsUpdated
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Timezone")
                    ]),
                    _vm._v(" "),
                    _c("AppDropdown", {
                      staticClass: "form-control",
                      attrs: {
                        options: _vm.timeZonesList,
                        placeholder: "Time Zone",
                        reduce: "name",
                        label: "label",
                        searchable: false,
                        dropdownRowComponent: _vm.timezoneRowComponent,
                        icon: "/images/icons/ic-globe-gray.svg",
                        disabled: _vm.updatesDisabled
                      },
                      model: {
                        value: _vm.selectedTimezone,
                        callback: function($$v) {
                          _vm.selectedTimezone = $$v
                        },
                        expression: "selectedTimezone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "date-time-picker" }, [
                  _c(
                    "div",
                    { staticClass: "date" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Date")
                      ]),
                      _vm._v(" "),
                      _c("AppFlatPickr", {
                        ref: "calendar",
                        staticClass: "h-100",
                        attrs: {
                          custom_class: "form-control h-100",
                          placeholder: "Start Date",
                          disableMobile: true,
                          disabled: _vm.updatesDisabled
                        },
                        model: {
                          value: _vm.selectedDate,
                          callback: function($$v) {
                            _vm.selectedDate = $$v
                          },
                          expression: "selectedDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "time" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Time")
                      ]),
                      _vm._v(" "),
                      _c("AppDropdown", {
                        staticClass: "form-control",
                        attrs: {
                          options: _vm.timesArrayList,
                          searchable: false,
                          label: "name",
                          reduce: "value",
                          rightAlign: false,
                          disabled: _vm.updatesDisabled
                        },
                        model: {
                          value: _vm.selectedTime,
                          callback: function($$v) {
                            _vm.selectedTime = $$v
                          },
                          expression: "selectedTime"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "immutable-details" }, [
            _c("div", { staticClass: "detail-row" }, [
              _c(
                "div",
                { staticClass: "key" },
                [
                  _c("InlineSvg", {
                    staticClass: "icon",
                    attrs: { src: "/images/add-icon.svg" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Trigger")])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.triggerText) + "\n\t\t\t\t")
              ])
            ]),
            _vm._v(" "),
            _vm.recipientCount
              ? _c("div", { staticClass: "detail-row" }, [
                  _c(
                    "div",
                    { staticClass: "key" },
                    [
                      _c("InlineSvg", {
                        staticClass: "icon",
                        attrs: { src: "/images/icon-users.svg" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Audience")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.recipientCount) + " Recipients")
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isDateTriggerActive
              ? _c("div", { staticClass: "detail-row" }, [
                  _c(
                    "div",
                    { staticClass: "key" },
                    [
                      _c("InlineSvg", {
                        staticClass: "icon",
                        attrs: { src: "/images/icon-users.svg" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tags")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "value tags" },
                    _vm._l(_vm.audienceTags, function(tag, index) {
                      return _c("AppTag", {
                        key: index,
                        attrs: { value: tag.name, type: "referrer" }
                      })
                    }),
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }